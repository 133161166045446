<template>
  <div class="overlay-selector-wrapper">
    <div class="d-flex flex-column">
      <Background
        class="mt-3"
        v-for="(overlay, key) in design.overlayList"
        :background="overlay"
        :selected="overlay.id === activeOverlayId"
        :key="key"
        @changeBackgroundSelected="changeOverlaySelected"
        @deleteLogoFromList="deleteOverlayFromList"
      />
      <div class="add-overlay-selector mt-3 d-flex align-center justify-center cursor-pointer" @click="toggleOverlayUploadModal">
        <AppIcon size="36" icon="plus" color="var(--v-dark-gray-2-base)" />
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { consoleError } from 'xpermeet-lib';

import Background from '@/components/StudioMenu/StudioDesign/Background';
import { STUDIO, SETTINGS } from '@/constants/modules';
import { SET_SHOW_OVERLAY_UPLOAD_MODAL } from '@/constants/mutation-types';

export default {
  name: 'OverlaySelector',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    Background,
  },
  data() {
    return {
      showOverlayUploadModal: false,
    };
  },
  computed: {
    ...mapState(STUDIO, ['design']),
    ...mapGetters(STUDIO, ['getActiveOverlay']),
    activeOverlayId() {
      return this.getActiveOverlay?.id;
    },
  },
  methods: {
    ...mapMutations(SETTINGS, [SET_SHOW_OVERLAY_UPLOAD_MODAL]),
    ...mapActions(SETTINGS, ['toggleOverlay', 'deleteOverlay']),
    async changeOverlaySelected(id) {
      try {
        await this.toggleOverlay({ id });
      } catch (err) {
        consoleError(err);
      }
    },
    async deleteOverlayFromList(id) {
      try {
        await this.deleteOverlay({ id });
      } catch (err) {
        consoleError(err);
      }
    },
    toggleOverlayUploadModal(state) {
      this[SET_SHOW_OVERLAY_UPLOAD_MODAL](state !== undefined ? state : !this.showOverlayUploadModal);
    },
  },
};
</script>

<style lang="scss" scoped>
.overlay-selector-wrapper {
  .add-overlay-selector {
    width: 108px;
    height: 54px;
    border-radius: 3px;
    background: var(--v-light-gray-2-lighten4);
  }
}
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "studio-bottom-menu d-flex justify-center pt-1" },
    [
      _c("MicrophoneSelectButton", {
        staticClass: "mr-1",
        attrs: {
          type: _vm.studio,
          studio: "",
          "is-active": _vm.microphoneAllowed && !_vm.user.audioMuted,
        },
        on: {
          toggle: function ($event) {
            return _vm.setAudioMute(!_vm.user.audioMuted)
          },
        },
      }),
      _c("span", { staticClass: "mx-2" }),
      _c("CameraSelectButton", {
        staticClass: "mr-1",
        attrs: {
          type: _vm.studio,
          studio: "",
          disabled: "",
          "is-active": _vm.cameraAllowed && !_vm.user.videoMuted,
        },
        on: {
          toggle: function ($event) {
            return _vm.setVideoMute(!_vm.user.videoMuted)
          },
        },
      }),
      _c("span", { staticClass: "mx-2" }),
      _c("SpeakerSelectButton", {
        staticClass: "mr-1",
        attrs: { type: _vm.studio, studio: "", "is-active": !_vm.speakerMuted },
        on: {
          toggle: function ($event) {
            return _vm.setSpeakerMute(!_vm.speakerMuted)
          },
        },
      }),
      _c("span", { staticClass: "mx-2" }),
      _c("AppMenuButton", {
        attrs: {
          icon: "monitor-1",
          loading: _vm.screenShareOnprogress,
          disabled: _vm.disabled,
          color: _vm.user.screenSharing ? "success" : "",
        },
        on: { click: _vm.toggleScreenShare },
      }),
      _c("span", { staticClass: "mx-2" }),
      _c("AppMenuButton", {
        attrs: { disabled: !_vm.isUserLogin, icon: "user-add" },
        on: { click: _vm.showInviteParticipantModal },
      }),
      _c("span", { staticClass: "mx-6" }),
      _c("AppMenuButton", {
        attrs: { "data-test-id": _vm.close, icon: "call", color: "error" },
        on: { click: _vm.leaveStudio },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
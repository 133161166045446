<template>
  <AppModal :title="$t(this.title)" max-width="450" persistent :show-close-button="!loading">
    <AppInput v-model="text" :max-length="200" :label="$t('bannerText')"></AppInput>
    <AppInput v-model="duration" :label="`${$t('duration')} (${$t('second')})`" type="number"></AppInput>
    <AppButton block @click="handleClick" :loading="loading" :disabled="!text">{{ $t('saveChanges') }}</AppButton>
  </AppModal>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { consoleError } from 'xpermeet-lib';

import { SETTINGS, NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'AddEditBannerModal',
  props: {
    folders: {
      type: Array,
      required: true,
    },
    folderId: {
      type: String,
    },
    banner: {
      type: Object,
    },
  },
  data() {
    return {
      text: this.banner?.text || '',
      loading: false,
      duration: this.banner?.duration || 4,
    };
  },

  computed: {
    ...mapState(SETTINGS, ['generalSettings']),
    title() {
      return this.folder ? 'editBanner' : 'addNewBanner';
    },
    folderIndex() {
      return this.folders.findIndex((f) => f.id === this.folderId);
    },
    bannerIndex() {
      return this.folders[this.folderIndex].banners.findIndex((b) => b.id === this.banner?.id);
    },
  },
  mounted() {
    if (!this.banner) {
      this.duration = +this.generalSettings?.banners?.duration || 4;
    }
  },
  methods: {
    ...mapActions(SETTINGS, ['setSettings']),
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    async create() {
      try {
        this.loading = true;

        const { banners } = this.folders[this.folderIndex];
        this.folders[this.folderIndex].banners = [
          {
            id: uuidv4(),
            text: this.text,
            duration: this.duration,
          },
          ...banners,
        ];

        const isSuccess = await this.setSettings({
          shortCode: 'banners',
          state: JSON.stringify(this.folders),
        });

        if (isSuccess) {
          this.$emit('completed');
          this.showToastNotification({ body: this.$t('success.successfullyAdded'), config: { type: TOAST_TYPE.SUCCESS } });
        } else {
          this.showToastNotification({ body: this.$t('errors.couldNotSet', { name: this.$t('folder') }), config: { type: TOAST_TYPE.ERROR } });
        }
      } catch (err) {
        consoleError('Create banner folder error: ', err);
      } finally {
        this.loading = false;
      }
    },
    async edit() {
      try {
        this.loading = true;
        const folders = [...this.folders];

        folders[this.folderIndex].banners[this.bannerIndex].text = this.text;
        folders[this.folderIndex].banners[this.bannerIndex].duration = this.duration;

        const isSuccess = await this.setSettings({
          shortCode: 'banners',
          state: JSON.stringify(folders),
        });

        if (isSuccess) {
          this.$emit('completed');
          this.showToastNotification({ body: this.$t('success.successfullyEdited'), config: { type: TOAST_TYPE.SUCCESS } });
        } else {
          this.showToastNotification({ body: this.$t('errors.couldNotEdit', { name: this.$t('folder') }), config: { type: TOAST_TYPE.ERROR } });
        }
      } catch (err) {
        consoleError('Edit banner folder error: ', err);
      } finally {
        this.loading = false;
      }
    },
    handleClick() {
      if (this.banner) {
        this.edit();
      } else {
        this.create();
      }
    },
    close(type) {
      this.dialog = false;
      this.$modal?.close(type);
    },
  },
};
</script>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-app",
    [
      _vm.loading
        ? _c(
            "div",
            {
              staticClass:
                "d-flex flex-column justify-center align-center loading-screen",
            },
            [_c("AppLogo", { staticClass: "pulse", attrs: { width: "7%" } })],
            1
          )
        : [
            _c("KeydownHandler", { on: { keyup: _vm.onHotkeyPressed } }),
            _c(
              "v-app-bar",
              {
                staticClass: "mb-4 studio--header",
                attrs: {
                  flat: "",
                  color: "bg-gray",
                  app: "",
                  "clipped-right": "",
                },
              },
              [
                _c("AppLogo", { attrs: { width: "7%" } }),
                _c(
                  "v-tooltip",
                  {
                    attrs: { bottom: "", "max-width": "500" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "span",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass:
                                      "studio--header--title ml-2 ellipsis",
                                  },
                                  "span",
                                  attrs,
                                  false
                                ),
                                on
                              ),
                              [_vm._v(_vm._s(_vm.getCurrentBroadcastTitle))]
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.getCurrentBroadcastTitle))])]
                ),
                _c("v-spacer"),
                _vm.hasExpiredToken
                  ? _c("ExpiredTokenWarning", { staticClass: "mr-2" })
                  : _vm._e(),
                _vm.showAddDestinationButton
                  ? _c(
                      "div",
                      [
                        !_vm.destinations.length
                          ? _c("AddDestination", {
                              attrs: {
                                bright: "",
                                "trigger-element-type": "button",
                              },
                            })
                          : _c(
                              "app-button",
                              {
                                staticClass: "py-2",
                                attrs: { color: "night-rider" },
                                on: {
                                  click: _vm.showAddEditCurrentSessionModal,
                                },
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "broadcasts.addDestinationToCurrentSession"
                                      )
                                    ) +
                                    " "
                                ),
                              ]
                            ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.isStreamStarted || _vm.isStreamEnded
                  ? _c(
                      "div",
                      {
                        staticClass:
                          "mr-3 broadcast-status d-flex align-center",
                      },
                      [
                        _c("div", {
                          staticClass: "broadcast-status--icon mr-3",
                          class: {
                            started: _vm.isStreamStarted,
                            "ml-3": _vm.showAddDestinationButton,
                          },
                        }),
                        _vm._v(" " + _vm._s(_vm.statusText) + " "),
                      ]
                    )
                  : _vm._e(),
                _c(
                  "span",
                  {
                    staticClass: "studio--header--mode mr-6",
                    class: { "ml-4": _vm.showAddDestinationButton },
                  },
                  [_vm._v(" " + _vm._s(_vm.modeText) + " ")]
                ),
                _c(
                  "div",
                  { staticClass: "d-flex" },
                  _vm._l(_vm.providers, function (destination, key) {
                    return _c("DestinationMenu", {
                      key: key,
                      attrs: { destination: destination },
                    })
                  }),
                  1
                ),
                _vm.$can("record", "Studio")
                  ? _c(
                      "v-menu",
                      {
                        attrs: {
                          "close-on-content-click": false,
                          "offset-y": "",
                          transition: "scale-transition",
                          disabled: _vm.disableStreamMenu,
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _vm.showGoLiveButton
                                    ? _c(
                                        "v-btn",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass:
                                                "mr-2 start-broadcast-btn",
                                              class: {
                                                "stream-menu-btn":
                                                  !_vm.disableStreamMenu,
                                              },
                                              attrs: {
                                                large: "",
                                                "data-test-id": `studio-${_vm.btnText}`,
                                                color: _vm.goLiveBtnColor,
                                                loading:
                                                  _vm.broadcastInProgress ||
                                                  _vm.recordIsPending,
                                                disabled: _vm.goLiveIsDisabled,
                                              },
                                              on: { click: _vm.handleStart },
                                            },
                                            "v-btn",
                                            attrs,
                                            false
                                          ),
                                          on
                                        ),
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t(_vm.btnText)) +
                                              " "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          2183821904
                        ),
                        model: {
                          value: _vm.streamMenu,
                          callback: function ($$v) {
                            _vm.streamMenu = $$v
                          },
                          expression: "streamMenu",
                        },
                      },
                      [
                        _c(
                          "v-card",
                          { staticClass: "start-stream-card" },
                          [
                            _c(
                              "v-list",
                              { staticClass: "pt-2" },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-content",
                                      [
                                        _c(
                                          "v-list-item-title",
                                          {
                                            staticClass:
                                              "d-flex align-center justify-center",
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "start-stream-card--text",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.$t("readyToGoLive")
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c("v-divider"),
                            _c(
                              "v-list",
                              { staticClass: "pb-4" },
                              [
                                _c(
                                  "v-list-item",
                                  [
                                    _c(
                                      "v-list-item-action",
                                      [
                                        _c("v-switch", {
                                          attrs: { color: "secondary" },
                                          model: {
                                            value: _vm.recordStream,
                                            callback: function ($$v) {
                                              _vm.recordStream = $$v
                                            },
                                            expression: "recordStream",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                    _c("v-list-item-title", [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "start-stream-card--text",
                                        },
                                        [_vm._v(_vm._s(_vm.$t("recordStream")))]
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-card-actions",
                              {
                                staticClass: "d-flex justify-space-around px-4",
                              },
                              [
                                _c(
                                  "AppButton",
                                  {
                                    attrs: {
                                      "data-test-id": _vm.cancelBtn,
                                      text: "",
                                    },
                                    on: {
                                      click: function ($event) {
                                        _vm.streamMenu = false
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("cancel")))]
                                ),
                                _c(
                                  "AppButton",
                                  {
                                    attrs: {
                                      "data-test-id": `studio-${_vm.btnText}`,
                                      color: _vm.goLiveBtnColor,
                                    },
                                    on: { click: _vm.handleGoLiveClick },
                                  },
                                  [_vm._v(_vm._s(_vm.$t(_vm.btnText)))]
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                !_vm.isUserLogin
                  ? _c(
                      "div",
                      [
                        _c(
                          "AppButton",
                          {
                            attrs: { color: "theme-btn-color" },
                            on: { click: _vm.login },
                          },
                          [_vm._v(_vm._s(_vm.$t("login")))]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
            _c("StudioMenu", { attrs: { "is-owner": _vm.isOwner } }),
            _c(
              "v-main",
              { staticClass: "px-0 py-0" },
              [
                _c(
                  "div",
                  {
                    ref: "studio",
                    staticClass: "studio",
                    attrs: { id: "studio" },
                  },
                  [
                    _c("LayoutMenu", { staticClass: "pr-4 pl-4 mb-1" }),
                    _c(
                      "div",
                      {
                        staticClass: "studio--broadcast-wrapper",
                        attrs: { id: "broadcast-wrapper" },
                      },
                      [_c("Broadcast")],
                      1
                    ),
                    _c("AvatarCropperModal", {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.showAvatarCropper,
                          expression: "showAvatarCropper",
                        },
                      ],
                      ref: "AvatarCropperModal",
                      on: { handleAvatarModal: _vm.handleAvatarModal },
                    }),
                    _c(
                      "div",
                      { staticClass: "studio--bottom-menu my-2" },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "studio--bottom-menu-backstage pb-5 mx-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "studio--bottom-menu-backstage--title",
                              },
                              [
                                _vm._v(
                                  _vm._s(_vm.$t("studio.backstage")) +
                                    " (" +
                                    _vm._s(_vm.userCount) +
                                    ")"
                                ),
                              ]
                            ),
                            _c(
                              "div",
                              { staticClass: "d-flex" },
                              _vm._l(_vm.getUsers, function (user) {
                                return _c("BackstageVideoCard", {
                                  key: user.id,
                                  staticClass:
                                    "studio--bottom-menu-backstage--video mr-4",
                                  attrs: { user: user },
                                  on: {
                                    handleAvatarModal: _vm.handleAvatarModal,
                                    clearAvatar: _vm.clearAvatar,
                                  },
                                })
                              }),
                              1
                            ),
                          ]
                        ),
                        _c("StudioBottomMenu"),
                      ],
                      1
                    ),
                    _c("UserAudioContainer"),
                  ],
                  1
                ),
                _vm.waitingForTheHost ? _c("IAmTheHostModal") : _vm._e(),
                _c("LogoUploadModal"),
                _c("BackgroundUploadModal"),
                _c("OverlayUploadModal"),
              ],
              1
            ),
          ],
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: false,
              expression: "false",
            },
          ],
        },
        [
          _c("MediaDevicesInitiator", {
            ref: "mediaDeviceInitiator",
            on: { initiated: _vm.mediaDeviceInitiated },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
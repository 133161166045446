<template>
  <div>
    <v-switch color="var(--v-secondary-lighten1)" label="permissions" hide-details v-model="switchPermissions1" />
    <v-switch color="var(--v-secondary-lighten1)" label="permissions1" hide-details v-model="switchPermissions2" />
    <v-switch color="var(--v-secondary-lighten1)" label="permissions2" hide-details v-model="switchPermissions3" />
  </div>
</template>
<script>
export default {
  name: 'PermissionsSettings',
  data() {
    return {
      switchPermissions1: false,
      switchPermissions2: false,
      switchPermissions3: false,
    };
  },
  computed: {
    payload() {
      const payload = {
        switchPermissions1: this.switchNotifications1,
        switchPermissions2: this.switchPermissions2,
        switchPermissions3: this.switchPermissions3,
      };
      return payload;
    },
  },
  methods: {
    save() {
      // eslint-disable-next-line no-console
      console.log(this.payload);
    },
  },
};
</script>

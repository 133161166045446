var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.showBackgroundUploadModal
    ? _c(
        "AppModal",
        {
          attrs: { title: _vm.$t("design.uploadBackground") },
          on: { close: _vm.close },
        },
        [
          !_vm.selectedImage
            ? _c("div", { staticClass: "logo-upload" }, [
                _c(
                  "div",
                  {
                    staticClass: "area",
                    on: {
                      click: _vm.selectFile,
                      dragleave: _vm.preventDefault,
                      dragover: _vm.preventDefault,
                      dragenter: _vm.preventDefault,
                      drop: _vm.handleFileDrop,
                    },
                  },
                  [_c("span", [_vm._v(_vm._s(_vm.$t("design.dragDropDesc")))])]
                ),
              ])
            : _vm._e(),
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.selectedImage,
                  expression: "selectedImage",
                },
              ],
              staticClass: "text-center",
            },
            [
              _c(
                "div",
                { staticClass: "preview-wrapper my-4" },
                [
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.imageLoaded,
                        expression: "imageLoaded",
                      },
                    ],
                    ref: "preview",
                    staticClass: "preview",
                    attrs: { src: "#", alt: "preview" },
                    on: {
                      load: function ($event) {
                        return _vm.setImageIsLoaded(true)
                      },
                    },
                  }),
                  !_vm.imageLoaded
                    ? _c("v-skeleton-loader", {
                        staticClass: "mx-auto",
                        attrs: {
                          "max-width": "300",
                          type: "image",
                          loading: "",
                        },
                      })
                    : _vm._e(),
                ],
                1
              ),
              _c(
                "div",
                [
                  _c(
                    "AppButton",
                    {
                      staticClass: "mr-2",
                      attrs: { text: "", disabled: _vm.loading },
                      on: { click: _vm.clearImage },
                    },
                    [_vm._v("Clear")]
                  ),
                  _c(
                    "AppButton",
                    {
                      attrs: { color: "secondary", loading: _vm.loading },
                      on: { click: _vm.upload },
                    },
                    [_vm._v("Upload")]
                  ),
                ],
                1
              ),
            ]
          ),
          _vm.errorMessage
            ? _c(
                "v-alert",
                {
                  staticClass: "mt-2",
                  attrs: { outlined: "", dense: "", type: "error" },
                },
                [_vm._v(_vm._s(_vm.errorMessage))]
              )
            : _vm._e(),
          _c("input", {
            ref: "fileInput",
            staticClass: "hidden",
            attrs: { type: "file", name: "logo" },
            on: { change: _vm.handleFileChange },
          }),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
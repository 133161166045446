import { render, staticRenderFns } from "./ChatStreamSelector.vue?vue&type=template&id=74717f4e&scoped=true&"
import script from "./ChatStreamSelector.vue?vue&type=script&lang=js&"
export * from "./ChatStreamSelector.vue?vue&type=script&lang=js&"
import style0 from "./ChatStreamSelector.vue?vue&type=style&index=0&id=74717f4e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "74717f4e",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/xpermeet-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('74717f4e')) {
      api.createRecord('74717f4e', component.options)
    } else {
      api.reload('74717f4e', component.options)
    }
    module.hot.accept("./ChatStreamSelector.vue?vue&type=template&id=74717f4e&scoped=true&", function () {
      api.rerender('74717f4e', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StudioMenu/StudioChat/ChatStreamSelector.vue"
export default component.exports
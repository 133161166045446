var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-4 corner-notch-selector" }, [
    _c("div", { staticClass: "corner-notch-selector--title" }, [
      _vm._v(" " + _vm._s(_vm.$t("design.chooseSingleOrMultipleToAdd")) + " "),
    ]),
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-wrap align-center corner-notch-selector--button-wrapper mt-2",
      },
      _vm._l(_vm.CORNER_NOTCH_TYPES, function (cornerNotch, key) {
        return _c(
          "AppButton",
          {
            key: key,
            staticClass: "corner-notch-selector--button",
            attrs: {
              disabled: _vm.hasSingleElement(cornerNotch.type),
              small: "",
              color: "var(--v-chat-input-color)",
            },
            on: {
              click: function ($event) {
                return _vm.addNotch(cornerNotch)
              },
            },
          },
          [
            _c("span", {}, [
              _vm._v(
                _vm._s(_vm.$t(`design.cornerNotchTypes.${cornerNotch.type}`))
              ),
            ]),
          ]
        )
      }),
      1
    ),
    _c(
      "div",
      { staticClass: "mt-4" },
      _vm._l(_vm.selectedNotchs, function (value, key) {
        return _c(
          "div",
          { key: key, staticClass: "d-flex" },
          [
            _vm.selectedNotchs[key].type !== "time"
              ? _c("v-text-field", {
                  staticClass: "corner-notch-selector--inputs",
                  attrs: {
                    solo: "",
                    outlined: "",
                    dense: "",
                    "hide-details": "",
                    height: "30",
                    error: _vm.fieldHasError(_vm.selectedNotchs[key]),
                    hint: _vm.$t("design.maxCharacter", {
                      value: _vm.selectedNotchs[key].maxChar,
                    }),
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "label",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "d-flex align-center" }, [
                              _c("span", { staticClass: "label-info" }, [
                                _vm._v(
                                  "(" +
                                    _vm._s(
                                      _vm.$t(
                                        `design.cornerNotchTypes.${_vm.selectedNotchs[key].type}`
                                      )
                                    ) +
                                    ")"
                                ),
                              ]),
                              _c("span", { staticClass: "label-max-char" }, [
                                _vm._v(
                                  "*" +
                                    _vm._s(
                                      _vm.$t("design.maxCharacter", {
                                        value: _vm.selectedNotchs[key].maxChar,
                                      })
                                    )
                                ),
                              ]),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "append",
                        fn: function () {
                          return [
                            _c("AppIcon", {
                              attrs: {
                                icon: _vm.selectedNotchs[key].icon,
                                color: "var(--v-white-base)",
                                width: "14",
                              },
                            }),
                          ]
                        },
                        proxy: true,
                      },
                    ],
                    null,
                    true
                  ),
                  model: {
                    value: _vm.selectedNotchs[key].value,
                    callback: function ($$v) {
                      _vm.$set(_vm.selectedNotchs[key], "value", $$v)
                    },
                    expression: "selectedNotchs[key].value",
                  },
                })
              : _c(
                  "div",
                  {
                    staticClass:
                      "time-notch d-flex justify-space-between align-center mb-2",
                  },
                  [
                    _c("CurrentTime"),
                    _c("AppIcon", {
                      attrs: {
                        icon: _vm.selectedNotchs[key].icon,
                        color: "var(--v-white-base)",
                        width: "14",
                      },
                    }),
                  ],
                  1
                ),
            _c(
              "div",
              {
                staticClass: "d-flex justify-center ml-4 mt-1 cursor-pointer",
                on: {
                  click: function ($event) {
                    return _vm.removeNotch(key)
                  },
                },
              },
              [_c("AppIcon", { attrs: { icon: "close", width: "14" } })],
              1
            ),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="studio-menu-wrapper">
    <div class="studio-menu-selectors">
      <div
        v-for="(item, key) in menuItems"
        v-show="!item.hideSelector"
        :key="key"
        class="px-0 mt-1 studio-menu-selector-items cursor-pointer"
        :class="{ 'mt-4': key === 0, active: tab === item.value }"
        @click="tab = item.value"
      >
        <v-badge
          v-tooltip="item.value === RIGHT_MENU_ITEMS.MESSAGES ? tooltipText : false"
          left
          color="secondary"
          overlap
          class="w-full"
          :content="item.value === RIGHT_MENU_ITEMS.MESSAGES ? totalUnreadedMessageCount : false"
          :value="item.value === RIGHT_MENU_ITEMS.MESSAGES ? totalUnreadedMessageCount : false"
        >
          <div class="d-flex align-center justify-space-around w-full h-full flex-column pt-2 pb-1 mr-1">
            <AppIcon :icon="item.icon" size="20" />
            <span class="studio-menu-text px-2 mt-2">{{ item.text }}</span>
          </div>
        </v-badge>
      </div>
    </div>
    <v-navigation-drawer permanent app right bottom width="440" clipped class="studio-menu-items">
      <v-tabs v-model="tab" vertical>
        <v-tab-item v-for="(item, key) in menuItems" :key="key" :value="item.value">
          <v-card color="var(--v-bg-gray-base)" flat>
            <v-card-text class="pb-0 px-0 pt-0">
              <component :is="item.component" :selected-tab="tab" />
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { RIGHT_MENU_ITEMS } from '@/constants/enums';
import { STUDIO } from '@/constants/modules';
import StudioMessages from '@/components/StudioMenu/StudioChat/StudioMessages';
import StudioSettings from '@/components/StudioMenu/StudioSettings';
import StudioBanners from '@/components/StudioMenu/StudioBanners';
// import StudioSounds from '@/components/StudioMenu/StudioSounds';
import StudioDesign from '@/components/StudioMenu/StudioDesign/index';
import { SET_DESIGN } from '@/constants/mutation-types';

export default {
  name: 'StudioMenu',
  props: {
    isOwner: Boolean,
  },
  data() {
    return {
      RIGHT_MENU_ITEMS,
      tab: RIGHT_MENU_ITEMS.MESSAGES,
    };
  },
  computed: {
    ...mapState(STUDIO, ['roomConfig']),
    ...mapGetters(STUDIO, ['getUnreadMessageCount', 'getUnreadStreamMessageCount']),
    menuItems() {
      const items = [
        {
          text: this.$t('rightMenu.messages'),
          value: RIGHT_MENU_ITEMS.MESSAGES,
          icon: 'message-1',
          component: StudioMessages,
          show: true,
          hideSelector: !this.isOwner,
        },
        {
          text: this.$t('rightMenu.banners'),
          value: RIGHT_MENU_ITEMS.BANNERS,
          icon: 'banner-1',
          component: StudioBanners,
          show: this.isOwner,
        },
        // {
        //   text: this.$t('rightMenu.sounds'),
        //   value: RIGHT_MENU_ITEMS.SOUNDS,
        //   icon: 'music-1',
        //   component: StudioSounds,
        // },
        {
          text: this.$t('rightMenu.design'),
          value: RIGHT_MENU_ITEMS.DESIGN,
          icon: 'eye-closed-1',
          component: StudioDesign,
          show: this.isOwner,
        },
        {
          text: this.$t('rightMenu.settings.text'),
          value: RIGHT_MENU_ITEMS.SETTINGS,
          icon: 'settings-1',
          component: StudioSettings,
          show: this.isOwner,
        },
      ];

      return items.filter((i) => i.show);
    },
    tooltipText() {
      if (this.getUnreadMessageCount && this.getUnreadStreamMessageCount) {
        return this.getBothUnreadMessageText;
      } else if (this.getUnreadMessageCount) {
        return this.getUnreadMessageText;
      } else if (this.getUnreadStreamMessageCount) {
        return this.getUnreadStreamMessageText;
      } else {
        return '';
      }
    },
    getBothUnreadMessageText() {
      return this.$t('messageCounts.both', { inRoomMessageCount: this.getUnreadMessageCount, streamMessageCount: this.getUnreadStreamMessageCount });
    },
    getUnreadMessageText() {
      return this.$t('messageCounts.inRoom', { inRoomMessageCount: this.getUnreadMessageCount });
    },
    getUnreadStreamMessageText() {
      return this.$t('messageCounts.stream', { getUnreadStreamMessageCount: this.getUnreadStreamMessageCount });
    },
    totalUnreadedMessageCount() {
      return this.getUnreadMessageCount + this.getUnreadStreamMessageCount;
    },
  },
  created() {
    const staticStreamMessage = {
      sender: 'XperStream',
      message: this.isOwner ? this.$t('staticStreamMessage.moderator') : this.$t('staticStreamMessage.user'),
      senderAvatar: '/images/chat-logo.svg',
    };
    this.addStreamMessage(staticStreamMessage);
  },
  methods: {
    ...mapMutations(STUDIO, [SET_DESIGN]),
    ...mapActions(STUDIO, ['addStreamMessage']),
  },
  watch: {
    'roomConfig.design'() {
      if (this.roomConfig?.design) {
        Object.keys(this.roomConfig.design).forEach((type) => {
          this[SET_DESIGN]({ type, value: this.roomConfig.design[type] });
          if (this.roomConfig.design[type]) {
            const target = type === 'theme' ? 'selectedTheme' : type;
            this[target] = this.roomConfig.design[type];
          }
        });
      }
    },
  },
};
</script>
<style lang="scss">
.studio-menu-wrapper {
  .studio-menu-selectors {
    position: fixed;
    background: transparent;
    right: 440px;
    z-index: 1;
    top: 64px;
    margin-right: 3px;

    .studio-menu-selector-items {
      background: var(--v-theme-gray-base);
      border-radius: 2px;
      position: relative;
      &.active {
        &:after {
          content: '';
          width: 5px;
          height: 100%;
          position: absolute;
          top: 0;
          right: 0;
          background: var(--v-theme-primary-base);
          border-radius: 2px;
        }
      }
      .studio-menu-text {
        font-size: 13px;
        line-height: 16px;
      }
    }
  }
  .studio-menu-items {
    float: right;
    z-index: 1;
    box-shadow: none !important;
  }
  .v-navigation-drawer__content {
    overflow: hidden;
  }
  .v-tabs-slider-wrapper {
    color: var(--v-studio-menu-slider-wrapper-color);
    width: 5px;
  }
  .w-full {
    width: 100%;
  }
  .h-full {
    height: 100%;
  }
  &.v-card {
    // background: transparent;
    .v-item-group {
      // background: transparent;
    }
  }
  .v-window {
    .v-card {
      height: 100vh;
    }
    border-left: 0.5px solid #4d4b51;
  }
}
</style>


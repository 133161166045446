var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    { attrs: { title: _vm.$t("changeDisplayName"), "max-width": "450" } },
    [
      _c("AppInput", {
        attrs: { label: _vm.$t("displayName"), "max-length": 30 },
        model: {
          value: _vm.userName,
          callback: function ($$v) {
            _vm.userName = $$v
          },
          expression: "userName",
        },
      }),
      _c(
        "AppButton",
        {
          attrs: { block: "", loading: _vm.loading },
          on: { click: _vm.create },
        },
        [_vm._v(_vm._s(_vm.$t("saveChanges")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
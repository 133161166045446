var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-selector camera-device-selector" },
    [
      _vm.hasCamera
        ? _vm._l(_vm.getCameras, function (item) {
            return _c(
              "div",
              {
                key: item.deviceId,
                staticClass:
                  "camera-device-selector--item pr-2 pt-2 d-flex align-center",
                class: { selected: _vm.selectedCameraId === item.deviceId },
                attrs: { role: "button" },
                on: {
                  click: function ($event) {
                    _vm.selectedCameraId = item.deviceId
                  },
                },
              },
              [
                _c("span", { staticClass: "camera-text ml-2" }, [
                  _vm._v(_vm._s(item.label)),
                ]),
                _c("v-spacer"),
                _vm.showPreview
                  ? _c("div", [
                      _c("video", {
                        attrs: { id: `video-preview-${item.deviceId}` },
                      }),
                    ])
                  : _vm._e(),
              ],
              1
            )
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
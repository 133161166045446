var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-switch", {
        attrs: {
          color: "var(--v-secondary-lighten1)",
          label: "permissions",
          "hide-details": "",
        },
        model: {
          value: _vm.switchPermissions1,
          callback: function ($$v) {
            _vm.switchPermissions1 = $$v
          },
          expression: "switchPermissions1",
        },
      }),
      _c("v-switch", {
        attrs: {
          color: "var(--v-secondary-lighten1)",
          label: "permissions1",
          "hide-details": "",
        },
        model: {
          value: _vm.switchPermissions2,
          callback: function ($$v) {
            _vm.switchPermissions2 = $$v
          },
          expression: "switchPermissions2",
        },
      }),
      _c("v-switch", {
        attrs: {
          color: "var(--v-secondary-lighten1)",
          label: "permissions2",
          "hide-details": "",
        },
        model: {
          value: _vm.switchPermissions3,
          callback: function ($$v) {
            _vm.switchPermissions3 = $$v
          },
          expression: "switchPermissions3",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
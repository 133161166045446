var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-text-field", {
    staticClass: "send-message mx-1",
    attrs: {
      disabled: _vm.disableChat,
      "background-color": _vm.chatBackground,
      height: "67",
      "hide-details": "",
      solo: "",
      flat: "",
    },
    on: {
      keyup: function ($event) {
        if (
          !$event.type.indexOf("key") &&
          _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
        )
          return null
        return _vm.send.apply(null, arguments)
      },
      focus: function ($event) {
        return _vm.$emit("onFocus")
      },
    },
    scopedSlots: _vm._u([
      {
        key: "label",
        fn: function () {
          return [
            _c("div", { staticClass: "d-flex justify-center align-start" }, [
              _c("div", [_vm._v(_vm._s(_vm.$t("rightMenu.sendAMessage")))]),
              _vm.disableChat
                ? _c(
                    "div",
                    { staticClass: "send-message-input-warning ml-4" },
                    [_vm._v(_vm._s(_vm.errorText))]
                  )
                : _vm._e(),
            ]),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.message,
      callback: function ($$v) {
        _vm.message = $$v
      },
      expression: "message",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        title: _vm.$t("inactive.waitingForTheHost"),
        "max-width": "450",
        persistent: "",
        "show-close-button": false,
      },
    },
    [
      _c("div", { staticClass: "d-flex align-center justify-center" }, [
        _c(
          "div",
          { staticClass: "d-flex flex-column align-center text-center" },
          [
            _c("AppLogo", {
              staticClass: "my-4",
              attrs: { width: 200, redirectable: false },
            }),
            _c("div", [_vm._v(_vm._s(_vm.$t("inactive.streamOpening")))]),
            _c("div", { staticClass: "d-flex mt-8" }, [
              _c(
                "div",
                [
                  _c(
                    "AppButton",
                    { attrs: { block: "" }, on: { click: _vm.login } },
                    [_vm._v(_vm._s(_vm.$t("inactive.IamTheHost")))]
                  ),
                ],
                1
              ),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
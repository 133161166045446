<template>
  <div class="studio-messages-wrapper">
    <div class="d-flex studio-messages-wrapper--tabs">
      <v-col>
        <v-badge
          v-tooltip="getUnreadStreamMessageText"
          class="w-full"
          offset-x="40"
          left
          color="secondary"
          overlap
          :content="getUnreadStreamMessageCount"
          :value="getUnreadStreamMessageCount"
        >
          <app-button @click="tab = 0" block large :color="streamMsgBtnColor" :ripple="false" depressed>{{ $t('rightMenu.messageTypes.stream') }}</app-button>
        </v-badge>
      </v-col>
      <v-col>
        <v-badge
          v-tooltip="getUnreadMessageText"
          class="w-full"
          offset-x="40"
          left
          color="secondary"
          overlap
          :content="getUnreadMessageCount"
          :value="getUnreadMessageCount"
        >
          <app-button @click="tab = 1" block large :color="studioMsgBtnColor" :ripple="false" depressed>
            {{ $t('rightMenu.messageTypes.inRoom') }}
          </app-button>
        </v-badge>
      </v-col>
    </div>
    <v-tabs-items v-model="tab" class="message-containers">
      <v-tab-item><StudioChat type="stream" /></v-tab-item>
      <v-tab-item><StudioChat type="inRoom" /></v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { STUDIO } from '@/constants/modules';
import StudioChat from '@/components/StudioMenu/StudioChat';

export default {
  name: 'StudioMessages',
  components: { StudioChat },
  data() {
    return {
      tab: 0,
    };
  },
  computed: {
    ...mapGetters(STUDIO, ['getUnreadMessageCount', 'getUnreadStreamMessageCount']),
    streamMsgBtnColor() {
      return this.tab === 0 ? 'theme-btn-color' : 'transparent';
    },
    studioMsgBtnColor() {
      return this.tab === 1 ? 'theme-btn-color' : 'transparent';
    },
    getUnreadMessageText() {
      return this.getUnreadMessageCount ? this.$t('messageCounts.inRoom', { inRoomMessageCount: this.getUnreadMessageCount }) : false;
    },
    getUnreadStreamMessageText() {
      return this.getUnreadStreamMessageCount ? this.$t('messageCounts.stream', { getUnreadStreamMessageCount: this.getUnreadStreamMessageCount }) : false;
    },
  },
};
</script>

<style lang="scss">
.studio-messages-wrapper {
  &--tabs {
    .v-btn__content {
      font-size: 20px !important;
    }
  }
  .message-containers {
    background-color: var(--v-bg-gray-base);
  }
  .studio-messages-tabs {
    &.v-tabs {
      #studio-messages-tabs-border {
        width: 100%;
        height: 1px;
        background: var(--v-tab-border-color);
        position: absolute;
        bottom: 0px;
      }
      .v-tab {
        color: var(--v-white-base);
        font-weight: 300;
        font-size: 20px;
        line-height: 23px;
        text-transform: none;

        &.active {
          font-weight: bold;
        }
      }
    }
  }
}
</style>

export function componentToHex(c) {
  let hex = Number(c).toString(16);
  return hex.length === 1 ? '0' + hex : hex;
}

export function componentToOpacity(a) {
  const opacity = Math.round(Number(a) * 255).toString(16);
  return opacity.length === 1 ? '0' + opacity : opacity;
}

export function rgbToHex(r, g, b, a = null) {
  let hex = '#' + componentToHex(r) + componentToHex(g) + componentToHex(b);
  if (a) {
    hex += componentToOpacity(a);
  }
  return hex;
}

export function rgbaToArray(string) {
  const str = string.startsWith('rgba') ? string.substring(5, string.length - 1) : string.substring(4, string.length - 1);
  return str.replace(/ /g, '').split(',');
}

export function convertToHex(string) {
  const rgbaArray = rgbaToArray(string);
  return rgbToHex(...rgbaArray);
}


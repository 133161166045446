<template>
  <div class="studio-chat-wrapper d-flex justify-space-between flex-column">
    <div
      class="messages px-1 pb-4"
      :class="{
        stream: type === 'stream',
        'not-moderator': !getLocalUser.isModerator,
        'any-destination': getLocalUser.isModerator && !currentSession.items.length,
      }"
      ref="messageBodyElement"
    >
      <Message
        v-for="(message, key) in filteredMessages"
        :message="message"
        :key="key"
        stream-status="pending"
        :is-pending="messageStatus[message.uuid] && messageStatus[message.uuid].pending.length"
        @sendAgain="sendAgain"
      />
      <IntersectionObserver @intersect="onIntersect" />
    </div>
    <div>
      <div v-if="type === 'stream' && currentSession && currentSession.items.length && getLocalUser.isModerator" class="d-flex ml-2 justify-space-between">
        <ChatStreamSelector
          :youtube-providers="youtubeProviders"
          :twitch-providers="twitchProviders"
          :short-codes="shortCodes"
          :selected-short-code="selectedShortCode"
          @setShortCode="setShortCode"
          @setAnySelectedStream="setAnySelectedStream"
          @setSelectedProviderPayload="setSelectedProviderPayload"
          @setTargetEmpty="setTargetEmpty"
        />
        <div class="short-codes mr-2" v-if="shortCodes.length > 1">
          <v-tooltip top max-width="400">
            <template v-slot:activator="{ on, attrs }">
              <AppIcon v-bind="attrs" v-on="on" icon="info" />
            </template>
            <div>{{ $t('forUsingShortCode') }}</div>
            <v-divider></v-divider>
            <div>{{ $t('shortCodeList', { shortCodes: shortCodesText }) }}</div>
          </v-tooltip>
        </div>
      </div>

      <div v-if="type !== 'stream' || (currentSession && currentSession.items.length && getLocalUser.isModerator)">
        <ChatInput
          @setAnySelectedStream="setAnySelectedStream"
          :short-codes="shortCodes"
          :type="type"
          :disable-chat="disableChat"
          @onFocus="onFocus"
          @send="send"
        />
      </div>
      <div class="viewers-information d-flex align-center mt-2">
        <AppIcon icon="viewer-eyes" size="15" class="ml-4" />
        <span class="ml-1">{{ viewerCountText }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import Message from '@/components/Common/Message.vue';
import ChatInput from '@/components/StudioMenu/StudioChat/ChatInput.vue';
import ChatStreamSelector from '@/components/StudioMenu/StudioChat/ChatStreamSelector.vue';
import IntersectionObserver from '@/components/Common/IntersectionObserver.vue';
import { STUDIO, DESTINATIONS } from '@/constants/modules';
import streamMessageQueue from '@/utils/streamMessageQueue';
import { SHORT_CODES } from '@/constants/short-codes';
import { DESTINATION_TYPES } from '@/constants/enums';

const { ALL, TWITCH, YOUTUBE } = SHORT_CODES;
import {
  SET_MY_STREAM_MESSAGES,
  SET_MY_STREAM_MESSAGE_FROM_CLIENT,
  UPDATE_STREAM_MESSAGE,
  DELETE_STREAM_MESSAGE,
  READ_ROOM_MESSAGES,
  READ_STREAM_MESSAGES,
} from '@/constants/mutation-types';

let messageQueue = null;
let tempMessageStatus;

export default {
  name: 'StudioChat',
  components: { Message, IntersectionObserver, ChatInput, ChatStreamSelector },
  props: {
    type: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      message: '',
      messageStatus: tempMessageStatus || {},
      anySelectedStream: true,
      uuid: null,
      targetProviders: [],
      selectedShortCode: null,
    };
  },
  computed: {
    ...mapState(STUDIO, ['messages', 'streamMessages', 'roomConfig', 'currentSession']),
    ...mapState(DESTINATIONS, ['destinations']),
    ...mapGetters(STUDIO, [
      'getLocalUser',
      'isWebinarStarted',
      'getViewerCount',
      'getStreamMessageByUuid',
      'getStreamErrorMessageByUuid',
      'getUnreadMessageCount',
      'getUnreadStreamMessageCount',
    ]),
    ...mapGetters(DESTINATIONS, ['getDestinationByUserProviderId']),

    filteredMessages() {
      return this.type === 'stream' ? this.streamMessages : this.messages;
    },
    actionType() {
      return this.type === 'stream' ? 'sendStreamMessage' : 'sendMessage';
    },
    disableChat() {
      return this.type === 'stream' && (!this.anySelectedStream || !this.isWebinarStarted);
    },
    selectedSessionPayload() {
      return this.targetProviders.map((item) => item.providerName);
    },
    selectedSessionUserProviderIds() {
      return this.targetProviders.map((item) => item.userProviderId);
    },
    viewerCountText() {
      return this.isWebinarStarted ? this.getViewerCount : this.$t('broadcastDidntStartedYet');
    },
    youtubeProviders() {
      return this.currentSession?.items.filter((item) => item.providerShortCode === DESTINATION_TYPES.YOUTUBE);
    },
    twitchProviders() {
      return this.currentSession?.items.filter((item) => item.providerShortCode === DESTINATION_TYPES.TWITCH);
    },
    youtubeShortCodes() {
      let codes = [];
      if (this.youtubeProviders.length) {
        codes = [`/${YOUTUBE}`];
        if (this.youtubeProviders.length > 1) {
          codes = [...codes, `/${YOUTUBE}${ALL}`, ...this.youtubeProviders.map((item, index) => `/${YOUTUBE}${index + 1}`)];
        }
      }

      return codes;
    },
    twitchShortCodes() {
      let codes = [];
      if (this.twitchProviders.length) {
        codes = [`/${TWITCH}`];
        if (this.twitchProviders.length > 1) {
          codes = [...codes, `/${TWITCH}${ALL}`, ...this.twitchProviders.map((item, index) => `/${TWITCH}${index + 1}`)];
        }
      }

      return codes;
    },
    shortCodes() {
      let codes = [];
      if (this.youtubeShortCodes.length + this.twitchShortCodes.length > 1) {
        codes = [`/${ALL}`];
      }
      return [...codes, ...this.youtubeShortCodes, ...this.twitchShortCodes];
    },
    shortCodesText() {
      return this.shortCodes.join(', ');
    },
  },
  created() {
    messageQueue = new streamMessageQueue(({ id, status, message, userSessionProviderId, reSendMessage }) => {
      if (!this.messageStatus[id]) {
        this.setMessageStatus(id, message);
      }
      const idleIndex = this.messageStatus[id].idle.findIndex((stat) => stat === userSessionProviderId);

      if (idleIndex > -1) {
        this.messageStatus[id].idle.splice(idleIndex, 1);
      }

      const pendingIndex = this.messageStatus[id].pending.findIndex((stat) => stat === userSessionProviderId);

      if (status === 'success' || status === 'error') {
        this.messageStatus[id].pending.splice(pendingIndex, 1);
        this.messageStatus[id][status].push(userSessionProviderId);

        if (status === 'success') {
          this.setMyStreamMessages(message); //bak
        }

        if (!this.messageStatus[id].idle.length) {
          this.checkMessageStatus(id, reSendMessage);
        }
      } else {
        this.messageStatus[id].pending.push(userSessionProviderId);
      }
    });
    this.targetProviders = this.currentSession.items.map((provider) => {
      const { providerShortCode, userSessionProviderId, userProviderId } = provider;
      return {
        providerName: providerShortCode,
        userSessionProviderId: userSessionProviderId,
        senderAvatar: this.getDestinationByUserProviderId(userProviderId)?.avatar,
        userProviderId: userProviderId,
      };
    });
  },
  methods: {
    ...mapActions(STUDIO, ['sendMessage', 'addStreamMessage']),
    ...mapMutations(STUDIO, [
      SET_MY_STREAM_MESSAGES,
      SET_MY_STREAM_MESSAGE_FROM_CLIENT,
      UPDATE_STREAM_MESSAGE,
      DELETE_STREAM_MESSAGE,
      READ_ROOM_MESSAGES,
      READ_STREAM_MESSAGES,
    ]),
    send(message) {
      const payload = {};
      if (this.type === 'stream') {
        payload.messageText = message;
        payload.sessionId = this.currentSession.sessionId;
        payload.targetProviders = this.targetProviders;
      } else {
        payload.message = message;
      }
      if (this.type === 'stream' && this.shortCodes.includes(message)) {
        this.setShortCode(message.split('/')[1]);
      } else {
        this[this.actionType](payload);
      }
    },
    sendStreamMessage(payload) {
      this.uuid = uuidv4();
      let message = { ...payload, uuid: this.uuid };
      const avatar = message.targetProviders.find((provider) => provider.senderAvatar)?.senderAvatar;
      const streamMessagePayload = {
        message: message.messageText,
        uuid: message.uuid,
        userId: this.getLocalUser.id,
        senderAvatar: avatar || null,
        selectedSessionPayload: this.selectedSessionPayload,
        targetProviders: message.targetProviders,
      };
      if (!this.messageStatus[this.uuid]) {
        this.setMessageStatus(this.uuid, message);
      }
      this.addStreamMessage(streamMessagePayload);

      message.targetProviders.forEach((provider) => {
        const { sessionId, messageText, uuid } = message;
        messageQueue.enqueue({ sessionId, messageText, uuid, targetProviders: [provider] });
      });
    },
    sendAgain(message) {
      const messageStatus = {
        success: [],
        error: [],
        pending: [],
        idle: message.targetProviders.map((provider) => provider.userSessionProviderId),
        targetProviders: message.targetProviders,
      };

      if (!this.messageStatus[message.uuid]) {
        this.$set(this.messageStatus, message.uuid, messageStatus);
      }

      messageQueue.enqueue(message);
    },
    setMyStreamMessages(message) {
      this[SET_MY_STREAM_MESSAGE_FROM_CLIENT]({ message, selectedSessionUserProviderIds: this.selectedSessionUserProviderIds });
    },
    checkMessageStatus(id, reSendMessage) {
      const errorMessages = this.messageStatus[id].error.map((error) =>
        this.messageStatus[id].targetProviders.find((provider) => provider.userSessionProviderId === error),
      );

      const successMessages = this.messageStatus[id].success.map((success) =>
        this.messageStatus[id].targetProviders.find((provider) => provider.userSessionProviderId === success),
      );

      const savedMessage = this.getStreamMessageByUuid(id);

      if (!reSendMessage) {
        if (errorMessages.length) {
          if (successMessages.length) {
            const successTargetProviders = savedMessage.targetProviders.filter(
              (targetProvider) => !errorMessages.find((message) => message.userSessionProviderId === targetProvider.userSessionProviderId),
            );
            const successSelectedSessionPayload = savedMessage.selectedSessionPayload.filter((sessionPayload) => {
              if (successTargetProviders.find((provider) => provider.providerName === sessionPayload)) {
                return true;
              } else {
                return !errorMessages.some((message) => message.providerName === sessionPayload);
              }
            });
            const errorSelectedSessionPayload = savedMessage.selectedSessionPayload.filter(
              (sessionPayload) => !successMessages.some((message) => message.providerName === sessionPayload),
            );
            const errorTargetProviders = savedMessage.targetProviders.filter(
              (targetProvider) => !successMessages.find((message) => message.userSessionProviderId === targetProvider.userSessionProviderId),
            );
            const payload = {
              ...savedMessage,
              selectedSessionPayload: successSelectedSessionPayload,
              targetProviders: successTargetProviders,
            };

            this.UPDATE_STREAM_MESSAGE(payload);

            const errorMessagePayload = {
              message: savedMessage.body,
              uuid: savedMessage.uuid,
              userId: savedMessage.userId,
              senderAvatar: savedMessage.senderAvatar || null,
              selectedSessionPayload: errorSelectedSessionPayload,
              targetProviders: errorTargetProviders,
              statusType: 'error',
            };

            this.addStreamMessage(errorMessagePayload);
          } else {
            const payload = {
              ...savedMessage,
              statusType: 'error',
            };

            this.UPDATE_STREAM_MESSAGE(payload);
          }
        }
      } else {
        const errorMessage = this.getStreamErrorMessageByUuid(id);

        if (successMessages.length) {
          if (!errorMessages.length) {
            if (!savedMessage) {
              const payload = {
                ...errorMessage,
              };
              delete payload.statusType;

              this.UPDATE_STREAM_MESSAGE(payload);
            } else {
              const payload = {
                ...savedMessage,
                selectedSessionPayload: [...new Set([...savedMessage.selectedSessionPayload, ...errorMessage.selectedSessionPayload])],
                targetProviders: [...savedMessage.targetProviders, ...errorMessage.targetProviders],
              };

              this.UPDATE_STREAM_MESSAGE(payload);
              this.DELETE_STREAM_MESSAGE(errorMessage.id);
            }
          } else {
            const successTargetProviders = this.messageStatus[id].success.map((successMessage) =>
              this.messageStatus[id].targetProviders.find((provider) => provider.userSessionProviderId === successMessage),
            );
            const successSelectedSessionPayloads = successTargetProviders.map((provider) => provider.providerName);
            const errorTargetProviders = this.messageStatus[id].success.map((errorsuccessMessage) =>
              this.messageStatus[id].targetProviders.find((provider) => provider.userSessionProviderId === errorsuccessMessage),
            );
            const errorSelectedSessionPayloads = errorTargetProviders.map((provider) => provider.providerName);
            const successMessagePayload = {
              ...savedMessage,
              selectedSessionPayload: [...new Set([...savedMessage.selectedSessionPayload, ...successSelectedSessionPayloads])],
              targetProviders: [...savedMessage.targetProviders, ...successTargetProviders],
            };
            const errorMessagePayload = { ...errorMessage, selectedSessionPayload: errorSelectedSessionPayloads, targetProviders: errorTargetProviders };

            this.UPDATE_STREAM_MESSAGE(successMessagePayload);
            this.UPDATE_STREAM_MESSAGE(errorMessagePayload);
          }
        }
      }
      this.$delete(this.messageStatus, id);
    },
    onFocus() {
      const elem = this.$refs.messageBodyElement;
      const hasScroll = elem.scrollHeight > elem.clientHeight;
      if (hasScroll) {
        if (elem.scrollTop + elem.clientHeight + 1 >= elem.scrollHeight) {
          this.readMessages();
        }
      } else {
        this.readMessages();
      }
    },
    onIntersect() {
      this.readMessages();
    },
    readMessages() {
      if (this.type === 'stream') {
        this.READ_STREAM_MESSAGES();
      } else {
        this.READ_ROOM_MESSAGES();
      }
    },
    setAnySelectedStream(value) {
      this.anySelectedStream = value;
    },
    setSelectedProviderPayload(value) {
      this.targetProviders = [...value];
    },
    setTargetEmpty() {
      this.targetProviders = [];
    },
    setShortCode(value) {
      this.selectedShortCode = value;
    },
    setMessageStatus(id, message) {
      const messageStatus = {
        success: [],
        error: [],
        pending: [],
        idle: message.targetProviders.map((provider) => provider.userSessionProviderId),
        targetProviders: message.targetProviders,
      };
      this.$set(this.messageStatus, id, messageStatus);
    },
  },
  watch: {
    session: {
      immediate: true,
      deep: true,
      handler() {
        if (this.currentSession?.items) {
          this[SET_MY_STREAM_MESSAGES](this.currentSession?.items.reduce((acc, curr) => ({ ...acc, [curr.userProviderId]: {} }), {}));
        }
      },
    },
    messageStatus: {
      immediate: true,
      deep: true,
      handler() {
        tempMessageStatus = this.messageStatus;
      },
    },
    filteredMessages: {
      handler: function () {
        this.$nextTick(() => {
          const element = this.$refs['messageBodyElement'];
          element.scrollTop = element.scrollHeight;
        });
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.studio-chat-wrapper {
  .messages {
    height: calc(100vh - 230px);
    &.stream {
      height: calc(100vh - 260px);
      &.not-moderator {
        height: calc(100vh - 160px);
      }
      &.any-destination {
        height: calc(100vh - 205px);
      }
    }
    overflow: auto;
  }

  .viewers-information {
    color: var(--v-white-darken1);
    font-size: 13px;
    line-height: 15px;
  }
}
</style>

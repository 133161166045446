<template>
  <div class="animated-elements-wrapper">
    <div class="d-flex align-center">
      <div class="info-text">{{ $t('design.animatedElements') }}</div>
      <v-tooltip top max-width="250">
        <template v-slot:activator="{ on, attrs }">
          <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
        </template>
        <v-divider></v-divider>
        <div>{{ $t('design.animatedElementsInfo') }}</div>
      </v-tooltip>
    </div>
    <div v-for="(element, key) in ANIMATED_ELEMENTS" :key="key">
      <div class="mt-2 d-flex">
        <div>
          <div
            class="animated-element d-flex align-center pa-1"
            :class="{
              active: element.type === selectedAnimatedElement,
              applied: appliedDesigns.includes(element.type),
              'hidden-element': animatedElements[element.type].hidden,
            }"
          >
            <div
              class="animated-element--title d-flex justify-center align-center cursor-pointer"
              :class="{ bordered: element.type === ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE }"
              @click="selectedAnimatedElement = element.type"
            >
              {{ element.translatedTitle ? this.$t(`design.${element.title}`) : element.title }}
            </div>
            <div class="animated-element--subtitle ml-5">{{ $t(`design.${animatedElements[element.type].hidden ? 'hiddenOnStage' : element.type}`) }}</div>
          </div>
          <div v-if="showFields(element, 'text')">
            <v-text-field
              v-model="animatedElements[element.type].text"
              background-color="var(--v-chat-input-color)"
              height="67"
              hide-details
              solo
              flat
              class="animated-design-text mt-3"
            >
              <template v-slot:label>
                <div class="d-flex justify-start align-center">
                  <div class="text-field-slot">{{ $t('design.textHere') }}</div>
                </div>
              </template>
            </v-text-field>
          </div>
          <div v-if="showFields(element, 'textSpeed')" class="d-flex text-speed-wrapper mt-2">
            <div>
              {{ $t('design.textSpeed') }}
            </div>
            <div
              v-for="(item, key) in 3"
              :key="key"
              class="ml-1 cursor-pointer text-speed"
              :class="{ active: item === animatedElements[element.type].textSpeed }"
              @click="setField(element.type, 'textSpeed', item)"
            >
              {{ item }}x
            </div>
          </div>
          <div v-if="showFields(element, 'cornerNotch')" class="mt-4">
            <div class="d-flex align-center">
              <v-switch
                v-model="animatedElements[element.type].cornerNotch"
                class="corner-notch-switch mt-0"
                color="var(--v-secondary-lighten1)"
                :label="$t('design.cornerNotch')"
                hide-details
                @change="changeNotch"
              />
              <v-tooltip top max-width="250">
                <template v-slot:activator="{ on, attrs }">
                  <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
                </template>
                <v-divider></v-divider>
                <div>{{ $t('design.cornerNotchInfo') }}</div>
              </v-tooltip>
            </div>

            <div v-if="animatedElements[element.type].cornerNotch"><corner-notch-selector ref="cornerNotchSelector" @setNotchError="setNotchError" /></div>
          </div>
        </div>
        <div v-if="selectedAnimatedElement === element.type || appliedDesigns.includes(element.type)" class="ml-2">
          <div class="remove-icon-wrapper d-flex justify-center align-center cursor-pointer" @click="removeSelected(element)">
            <AppIcon icon="trash-2" width="14" height="14" color="var(--v-white-base)" />
          </div>
          <div
            class="remove-icon-wrapper d-flex justify-center align-center cursor-pointer mt-2"
            :class="{ 'hidden-icon': animatedElements[element.type].hidden }"
            @click="setField(element.type, 'hidden', !animatedElements[element.type].hidden)"
          >
            <AppIcon
              :icon="animatedElements[element.type].hidden ? 'eye-on' : 'eye-off'"
              width="14"
              height="14"
              :color="animatedElements[element.type].hidden ? 'var(--v-border-orange)' : 'var(--v-white-base)'"
            />
          </div>
        </div>
      </div>
      <div v-if="selectedAnimatedElement === element.type" class="mt-3 d-flex justify-end">
        <AppButton @click="applyDesign(element.type)" :disabled="disableApplyButton" color="var(--v-secondary-lighten1)" small>{{ $t('apply') }}</AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import CornerNotchSelector from '@/components/StudioMenu/StudioDesign/AnimatedElements/CornerNotchSelector.vue';
import { SET_DESIGN } from '@/constants/mutation-types';
import { STUDIO } from '@/constants/modules';
import { ANIMATED_ELEMENT_TYPES, ANIMATED_ELEMENTS } from '@/constants/animated-elements';

export default {
  name: 'AnimatedElements',
  components: { CornerNotchSelector },
  data() {
    return {
      ANIMATED_ELEMENT_TYPES,
      ANIMATED_ELEMENTS,
      selectedAnimatedElement: null,
      animatedElements: {
        [ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE]: {},
        // [ANIMATED_ELEMENT_TYPES.COUNTDOWN]: {},
        // [ANIMATED_ELEMENT_TYPES.LIVE_TAG]: {},
        // [ANIMATED_ELEMENT_TYPES.SOCIAL_CONTACTS]: {},
      },
      appliedDesigns: [],
      hasCornerNotchError: false,
      selectedNotchs: [],
    };
  },
  computed: {
    ...mapState(STUDIO, ['roomConfig', 'design']),
    showFields() {
      return (element, type) => {
        return element.extraProperties.findIndex((property) => property.type === type) !== -1 && element.type === this.selectedAnimatedElement;
      };
    },
    animatedElementPayload() {
      return this.appliedDesigns.reduce((a, v) => ({ ...a, [v]: { ...this.animatedElements[v] } }), {});
    },
    disableApplyButton() {
      return this.animatedElements[this.selectedAnimatedElement]?.text === '' || this.hasCornerNotchError;
    },
  },
  created() {
    this.$nextTick(() => {
      for (let i in ANIMATED_ELEMENTS) {
        if (ANIMATED_ELEMENTS[i].extraProperties.length) {
          this.setToDefault(ANIMATED_ELEMENTS[i].type);
        }
      }
    });
  },
  methods: {
    ...mapMutations(STUDIO, [SET_DESIGN]),
    removeSelected(element) {
      this.setToDefault(element.type);
      const index = this.appliedDesigns.findIndex((design) => design === element.type);
      if (index > -1) {
        this.appliedDesigns.splice(index, 1);
      }
      this.selectedAnimatedElement = null;
      this.setDesign();
    },
    applyDesign(elementType) {
      if (!this.appliedDesigns.includes(elementType)) {
        this.appliedDesigns.push(elementType);
      }
      this.selectedAnimatedElement = null;
      this.setDesign();
    },
    setToDefault(type) {
      if (this.roomConfig.design?.animatedElements?.[type]) {
        this.animatedElements[type] = { ...this.roomConfig.design.animatedElements[type] };
        if (!this.appliedDesigns.includes(type)) {
          this.appliedDesigns.push(type);
        }
      } else {
        const animatedElement = ANIMATED_ELEMENTS.find((element) => element.type === type);
        this.animatedElements[type] = { ...animatedElement.extraProperties.reduce((a, v) => ({ ...a, [v.type]: v.defaultValue }), {}) };
      }
    },
    setDesign() {
      const value = { ...this.animatedElementPayload };
      if (value?.[ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE]) {
        value[ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE].cornerNotchProperties = value?.[ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE].cornerNotch
          ? this.$refs?.cornerNotchSelector?.[0]?.notchsPayload()
          : [];
      }
      this[SET_DESIGN]({ type: 'animatedElements', value });
    },
    setField(elementType, fieldType, value) {
      const { animatedElements } = this.design;
      this.animatedElements[elementType][fieldType] = value;
      if (animatedElements[elementType]) {
        animatedElements[elementType][fieldType] = value;
        this[SET_DESIGN]({ type: 'animatedElements', value: { ...animatedElements } });
      }
    },
    changeNotch(value) {
      if (!value) {
        this.$refs?.cornerNotchSelector?.[0]?.removeNotchs();
      }
    },
    setNotchError(value) {
      this.hasCornerNotchError = value;
    },
    updateNotchs(notchs) {
      this.selectedNotchs = [...notchs];
    },
  },
};
</script>

<style lang="scss" scoped>
.animated-elements-wrapper {
  .info-text {
    font-size: 15px;
    font-weight: 300;
    line-height: 17.5px;
    color: #ffffff;
  }
  .animated-element {
    width: 300px;
    &.active {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23FF9119FF' stroke-width='2' stroke-dasharray='13%2c 10' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
    }
    &.applied:not(.active) {
      border: 1px solid var(--v-border-orange);
      border-radius: 4px;
      &.hidden-element {
        border: 1px solid var(--v-gray-base);
      }
    }
    &--title {
      background: var(--v-gray-91-base);
      color: var(--v-primary-base);
      font-size: 27px;
      line-height: 31.64px;
      font-weight: 500;
      width: 108px;
      height: 56px;
      position: relative;
      border-radius: 3px;

      &.bordered {
        &::after {
          content: '';
          background: var(--v-primary-base);
          width: 53px;
          height: 6px;
          position: absolute;
          bottom: 10%;
        }
      }
    }
    &--subtitle {
      color: var(--v-white-base);
      font-size: 15px;
      font-weight: 400;
    }
    &.hidden-element {
      .animated-element--subtitle {
        color: var(--v-gray-base);
      }
    }
  }
  .remove-icon-wrapper {
    border: 1px solid var(--v-white-base);
    width: 20px;
    height: 20px;
    border-radius: 4px;
    &.hidden-icon {
      border: 1px solid var(--v-border-orange);
    }
  }
  .animated-design-text {
    ::v-deep .v-input__slot {
      min-height: auto !important;
      .v-text-field__slot {
        height: 100%;
        .v-label {
          color: white;
          font-size: 14px;
          margin: auto;
          top: 5px;
        }
      }
    }
  }
  .text-speed-wrapper {
    color: var(--v-white-base);
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    .text-speed:not(.active) {
      color: var(--v-chat-input-text-color);
    }
  }
  .corner-notch-switch {
    ::v-deep .v-input__control {
      flex-grow: 0;
      width: auto;
      .v-label {
        color: var(--v-white-base);
      }
    }
    label {
      flex: none;
    }
  }
}
</style>


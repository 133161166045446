var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "hotkeys-wrapper" },
    [
      _c("v-checkbox", {
        attrs: {
          "hide-details": "",
          color: "var(--v-secondary-lighten1)",
          label: _vm.$t("rightMenu.settings.useDefaultKeys"),
        },
        on: { change: _vm.changeHotkeysToDefaults },
        model: {
          value: _vm.useDefaults,
          callback: function ($$v) {
            _vm.useDefaults = $$v
          },
          expression: "useDefaults",
        },
      }),
      !_vm.hideInputs
        ? _c(
            "div",
            _vm._l(_vm.hotkeysData, function (hotkey, key) {
              return _c("AppHotkeySelectInput", {
                key: key,
                attrs: {
                  "hide-details": "",
                  color: "var(--v-secondary-lighten1)",
                  disabled: _vm.useDefaults,
                  label: _vm.$t(`rightMenu.settings.${hotkey.type}`),
                  defaultKey: _vm
                    .findDefaultHotkeyByType({ type: hotkey.type })
                    .value.key.toUpperCase(),
                  type: hotkey.type,
                  value: hotkey.value.key,
                  "hotkeys-data": _vm.hotkeysData,
                },
                on: {
                  changeHotkey: function ($event) {
                    return _vm.changeHotkey($event, hotkey.type)
                  },
                },
              })
            }),
            1
          )
        : _vm._e(),
      _vm.hasNull
        ? _c(
            "v-alert",
            {
              staticClass: "mt-4",
              attrs: { type: "error" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "close",
                    fn: function () {
                      return [
                        _c("AppIcon", {
                          staticClass: "cursor-pointer",
                          attrs: { icon: "close-2" },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ],
                null,
                false,
                107350086
              ),
            },
            [
              _vm._v(
                " " +
                  _vm._s(_vm.$t("rightMenu.settings.hotkeySameKeyError")) +
                  " "
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="hotkeys-wrapper">
    <v-checkbox
      hide-details
      color="var(--v-secondary-lighten1)"
      :label="$t('rightMenu.settings.useDefaultKeys')"
      v-model="useDefaults"
      @change="changeHotkeysToDefaults"
    />
    <div v-if="!hideInputs">
      <AppHotkeySelectInput
        v-for="(hotkey, key) in hotkeysData"
        hide-details
        color="var(--v-secondary-lighten1)"
        :key="key"
        :disabled="useDefaults"
        :label="$t(`rightMenu.settings.${hotkey.type}`)"
        :defaultKey="findDefaultHotkeyByType({ type: hotkey.type }).value.key.toUpperCase()"
        :type="hotkey.type"
        :value="hotkey.value.key"
        :hotkeys-data="hotkeysData"
        @changeHotkey="changeHotkey($event, hotkey.type)"
      />
    </div>
    <v-alert v-if="hasNull" type="error" class="mt-4">
      <template v-slot:close>
        <AppIcon class="cursor-pointer" icon="close-2" />
      </template>
      {{ $t('rightMenu.settings.hotkeySameKeyError') }}
    </v-alert>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex';
import { SETTINGS, NOTIFICATION } from '@/constants/modules';
import { SET_USE_DEFAULT_HOTKEYS, SET_HOTKEYS } from '@/constants/mutation-types';
import { HOTKEYS } from '@/constants/settings';
import { TOAST_TYPE } from '@/constants/toast';

const { USE_DEFAULT_HOTKEYS, CAMERA_ON_OFF, SPEAKER_ON_OFF, MICROPHONE_ON_OFF } = HOTKEYS;

export default {
  name: 'HotkeysSettings',
  props: {
    selected: Boolean,
    selectedTab: Boolean,
  },
  data() {
    return {
      USE_DEFAULT_HOTKEYS,
      CAMERA_ON_OFF,
      SPEAKER_ON_OFF,
      MICROPHONE_ON_OFF,
      isFocused: false,
      hotkeysData: [],
      useDefaults: false,
      hasNull: false,
      hideInputs: false,
    };
  },
  computed: {
    ...mapState(SETTINGS, ['defaultHotkeys', 'hotkeys', USE_DEFAULT_HOTKEYS]),
    ...mapGetters(SETTINGS, ['findDefaultHotkeyByType']),
    settingsPayload() {
      return {
        shortCode: 'hotkeys',
        state: JSON.stringify({
          [USE_DEFAULT_HOTKEYS]: this.useDefaults,
          hotkeys: this.hotkeysData,
        }),
      };
    },
  },
  created() {
    this.$nextTick(() => {
      this.setHotkeyData();
    });
    this.useDefaults = this[USE_DEFAULT_HOTKEYS];
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    ...mapActions(SETTINGS, ['setSettings']),
    ...mapMutations(SETTINGS, [SET_USE_DEFAULT_HOTKEYS, SET_HOTKEYS]),
    save() {
      if (this.hotkeysData.every((hotkey) => hotkey.value.code && hotkey.value.keyCode)) {
        this[SET_USE_DEFAULT_HOTKEYS](this.useDefaults);
        this[SET_HOTKEYS](this.hotkeysData);
        this.setSettings(this.settingsPayload);
      } else {
        this.showToastNotification({ body: 'error.duplicatedHotkeys', config: { type: TOAST_TYPE.WARNING } });
      }
    },
    changeHotkeysToDefaults(e) {
      if (e) {
        this.$set(this, 'hotkeysData', [...this.defaultHotkeys]);
      }
    },
    changeHotkey(e, type) {
      const index = this.findHotkeyIndexByType(type);
      const value = {
        key: e.key ? e.key.trim() : e.code,
        keyCode: e.keyCode,
        code: e.code,
      };
      this.hotkeysData[index] = {
        type,
        value: { ...value },
      };
      if (!e.code || !e.keyCode) {
        this.hasNull = true;
      } else if (this.hotkeysData.every((hotkey) => hotkey.value.code && hotkey.value.keyCode)) {
        this.hasNull = false;
      }
    },
    findHotkeyIndexByType(type) {
      return this.hotkeysData.findIndex((hotkey) => hotkey.type === type);
    },
    setHotkeyData() {
      this.hideInputs = true;
      this.hasNull = false;
      if (this[USE_DEFAULT_HOTKEYS]) {
        this.$set(this, 'hotkeysData', [...this.defaultHotkeys]);
      } else {
        this.$set(this, 'hotkeysData', [...this.hotkeys]);
      }
      setTimeout(() => {
        this.hideInputs = false;
      }, 10);
    },
  },
  watch: {
    selected: {
      immediate: true,
      handler() {
        if (this.selected && this.selectedTab) {
          this.setHotkeyData();
        }
      },
    },
    selectedTab: {
      immediate: true,
      handler() {
        if (this.selected && this.selectedTab) {
          this.setHotkeyData();
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.hotkeys-wrapper {
  height: 100%;
}
</style>

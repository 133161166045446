<template>
  <div class="background-selector-wrapper pl-1">
    <div class="d-flex flex-column">
      <div class="d-flex align-center">
        <ColorPicker type="background" />
      </div>
      <div class="d-flex flex-column">
        <Background
          class="mt-3"
          v-for="(background, key) in design.backgroundList"
          :background="background"
          :selected="background.id === activeBackgroundId"
          :key="key"
          @changeBackgroundSelected="changeBackgroundSelected"
          @deleteLogoFromList="deleteBackgroundFromList"
        />
        <div class="add-logo-selector mt-3 d-flex align-center justify-center cursor-pointer" @click="toggleBackgroundUploadModal">
          <AppIcon size="36" icon="plus" color="var(--v-dark-gray-2-base)" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { consoleError } from 'xpermeet-lib';

import ColorPicker from '@/components/StudioMenu/StudioDesign/ColorPicker';
import Background from '@/components/StudioMenu/StudioDesign/Background';
import { STUDIO, SETTINGS } from '@/constants/modules';
import { SET_SHOW_BACKGROUND_UPLOAD_MODAL } from '@/constants/mutation-types';

export default {
  name: 'StudioBackgroundSelector',
  components: { ColorPicker, Background },
  data() {
    return {
      showBackgroundUploadModal: false,
    };
  },
  computed: {
    ...mapState(STUDIO, ['design']),
    activeBackgroundId() {
      return this.design?.backgroundList.find((background) => background.active)?.id;
    },
  },
  methods: {
    ...mapMutations(SETTINGS, [SET_SHOW_BACKGROUND_UPLOAD_MODAL]),
    ...mapActions(SETTINGS, ['toggleBackground', 'deleteBackground']),
    async changeBackgroundSelected(id) {
      try {
        await this.toggleBackground({ id });
      } catch (err) {
        consoleError(err);
      }
    },
    async deleteBackgroundFromList(id) {
      try {
        await this.deleteBackground({ id });
      } catch (err) {
        consoleError(err);
      }
    },
    toggleBackgroundUploadModal(state) {
      this[SET_SHOW_BACKGROUND_UPLOAD_MODAL](state !== undefined ? state : !this.showBackgroundUploadModal);
    },
  },
};
</script>

<style lang="scss" scoped>
.background-selector-wrapper {
  .add-logo-selector {
    width: 108px;
    height: 54px;
    border-radius: 3px;
    background: var(--v-light-gray-2-lighten4);
  }
}
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "studio-design-wrapper mt-4" }, [
    _c(
      "div",
      {
        staticClass:
          "d-flex flex-column justify-space-between studio-design-wrapper--content",
      },
      [
        _c(
          "div",
          {
            staticClass: "ml-4 studio-design-wrapper--body",
            class: { "info-alert": _vm.infoAlert },
          },
          [
            _c(
              "div",
              { staticClass: "d-flex align-center" },
              [
                _c("div", { staticClass: "info-text" }, [
                  _vm._v(_vm._s(_vm.$t("design.backThemeColor"))),
                ]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "AppIcon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      icon: "info",
                                      size: "14",
                                      color: "var(--v-secondary-lighten1)",
                                    },
                                  },
                                  "AppIcon",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("v-divider"),
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("design.backgroundSelectorInfo"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex align-center mt-4" },
              [_c("ColorPicker", { attrs: { type: "banner" } })],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [
                _c("div", { staticClass: "info-text" }, [
                  _vm._v(_vm._s(_vm.$t("design.displayName"))),
                ]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "AppIcon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      icon: "info",
                                      size: "14",
                                      color: "var(--v-secondary-lighten1)",
                                    },
                                  },
                                  "AppIcon",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("v-divider"),
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("design.hideUserNameSelectorInfo"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-4" },
              [
                _c("v-switch", {
                  attrs: {
                    color: "var(--v-secondary-lighten1)",
                    label: _vm.$t("design.hideUserName"),
                    "hide-details": "",
                  },
                  on: { change: _vm.onSwitchChange },
                  model: {
                    value: _vm.hideUserName,
                    callback: function ($$v) {
                      _vm.hideUserName = $$v
                    },
                    expression: "hideUserName",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [
                _c("div", { staticClass: "info-text" }, [
                  _vm._v(_vm._s(_vm.$t("design.logo"))),
                ]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "AppIcon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      icon: "info",
                                      size: "14",
                                      color: "var(--v-secondary-lighten1)",
                                    },
                                  },
                                  "AppIcon",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("v-divider"),
                    _c("div", [
                      _vm._v(_vm._s(_vm.$t("design.logoDescription"))),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "mt-4" },
              [_c("LogoPicker", { on: { change: _vm.onLogoChange } })],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [_c("animated-elements")],
              1
            ),
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [
                _c("div", { staticClass: "info-text" }, [
                  _vm._v(_vm._s(_vm.$t("design.overlay"))),
                ]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "AppIcon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      icon: "info",
                                      size: "14",
                                      color: "var(--v-secondary-lighten1)",
                                    },
                                  },
                                  "AppIcon",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("v-divider"),
                    _c("div", [_vm._v(_vm._s(_vm.$t("design.overlayInfo")))]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "mt-4" }, [_c("OverlaySelector")], 1),
            _c(
              "div",
              { staticClass: "d-flex align-center mt-5" },
              [
                _c("div", { staticClass: "info-text" }, [
                  _vm._v(_vm._s(_vm.$t("design.background"))),
                ]),
                _c(
                  "v-tooltip",
                  {
                    attrs: { top: "", "max-width": "250" },
                    scopedSlots: _vm._u([
                      {
                        key: "activator",
                        fn: function ({ on, attrs }) {
                          return [
                            _c(
                              "AppIcon",
                              _vm._g(
                                _vm._b(
                                  {
                                    staticClass: "ml-2",
                                    attrs: {
                                      icon: "info",
                                      size: "14",
                                      color: "var(--v-secondary-lighten1)",
                                    },
                                  },
                                  "AppIcon",
                                  attrs,
                                  false
                                ),
                                on
                              )
                            ),
                          ]
                        },
                      },
                    ]),
                  },
                  [
                    _c("v-divider"),
                    _c("div", [
                      _vm._v(
                        _vm._s(_vm.$t("design.studioBackgroundSelectorInfo"))
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c("div", { staticClass: "mt-4" }, [_c("BackgroundSelector")], 1),
          ]
        ),
        _c(
          "div",
          { staticClass: "px-4" },
          [
            _c(
              "v-alert",
              {
                attrs: { type: "info" },
                scopedSlots: _vm._u([
                  {
                    key: "close",
                    fn: function () {
                      return [
                        _c("AppIcon", {
                          staticClass: "cursor-pointer",
                          attrs: { icon: "close-2" },
                          on: {
                            click: function ($event) {
                              _vm.infoAlert = false
                            },
                          },
                        }),
                      ]
                    },
                    proxy: true,
                  },
                ]),
                model: {
                  value: _vm.infoAlert,
                  callback: function ($$v) {
                    _vm.infoAlert = $$v
                  },
                  expression: "infoAlert",
                },
              },
              [_vm._v(" " + _vm._s(_vm.$t("design.clickSave")) + " ")]
            ),
            _c(
              "AppButton",
              {
                staticClass: "save-button mt-3",
                attrs: { block: "", color: "var(--v-info-darken3)" },
                on: { click: _vm.save },
              },
              [_vm._v(_vm._s(_vm.$t("rightMenu.settings.applyToStream")))]
            ),
          ],
          1
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
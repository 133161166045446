<template>
  <AppModal v-if="showBackgroundUploadModal" :title="$t('design.uploadBackground')" @close="close">
    <div v-if="!selectedImage" class="logo-upload">
      <div class="area" @click="selectFile" @dragleave="preventDefault" @dragover="preventDefault" @dragenter="preventDefault" @drop="handleFileDrop">
        <span>{{ $t('design.dragDropDesc') }}</span>
      </div>
    </div>
    <div v-show="selectedImage" class="text-center">
      <div class="preview-wrapper my-4">
        <img v-show="imageLoaded" @load="setImageIsLoaded(true)" ref="preview" class="preview" src="#" alt="preview" />
        <v-skeleton-loader v-if="!imageLoaded" class="mx-auto" max-width="300" type="image" loading></v-skeleton-loader>
      </div>
      <div>
        <AppButton class="mr-2" text :disabled="loading" @click="clearImage">Clear</AppButton>
        <AppButton color="secondary" :loading="loading" @click="upload">Upload</AppButton>
      </div>
    </div>
    <v-alert v-if="errorMessage" class="mt-2" outlined dense type="error">{{ errorMessage }}</v-alert>
    <input ref="fileInput" type="file" @change="handleFileChange" name="logo" class="hidden" />
  </AppModal>
</template>

<script>
import { mapActions, mapState, mapMutations } from 'vuex';
import XperdriveLib from 'xperdrive-lib';
import { consoleError } from 'xpermeet-lib';

import { NOTIFICATION, SETTINGS } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';
import { IMAGE_MIME_TYPES } from '@/constants/file';
import { BACKGROUND_MAX_SIZE } from '@/constants/design';
import { SET_SHOW_BACKGROUND_UPLOAD_MODAL } from '@/constants/mutation-types';

export default {
  name: 'uploadBackground',
  data: () => ({
    selectedImage: null,
    errorMessage: null,
    loading: false,
    imageLoaded: false,
  }),
  computed: {
    ...mapState(SETTINGS, ['showBackgroundUploadModal']),
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    ...mapActions(SETTINGS, ['addBackground', 'setSettings', 'fetchSettings']),
    ...mapMutations(SETTINGS, [SET_SHOW_BACKGROUND_UPLOAD_MODAL]),
    setImageIsLoaded(status) {
      this.imageLoaded = status;
    },
    clearImage() {
      this.selectedImage = null;
    },
    setImagePreview(file) {
      this.selectedImage = null;
      this.setImageIsLoaded(false);
      this.errorMessage = this.validation(file);

      if (this.errorMessage) {
        return;
      }

      const fileReader = new FileReader();
      this.selectedImage = file;
      fileReader.readAsDataURL(file);
      fileReader.onload = (e) => {
        this.$refs.preview.src = e.target.result;
      };
    },
    preventDefault(e) {
      e.preventDefault();
      return false;
    },
    handleFileChange() {
      const [file] = this.$refs.fileInput.files;
      this.setImagePreview(file);
    },
    selectFile() {
      this.$refs.fileInput.click();
    },
    handleFileDrop(e) {
      e.preventDefault();
      const dt = e.dataTransfer;
      const [file] = structuredClone(dt.files);
      this.setImagePreview(file);
    },
    validation(file) {
      if (!IMAGE_MIME_TYPES.includes(file.type)) {
        return this.$t('error.invalidFileType', { allowedTypes: 'png, jpg' });
      }

      if (file.size > BACKGROUND_MAX_SIZE) {
        return this.$t('error.maxFileSize', { size: '10mb' });
      }

      return null;
    },
    close() {
      this.SET_SHOW_BACKGROUND_UPLOAD_MODAL(false);
      this.selectedImage = null;
    },
    async upload() {
      this.loading = true;
      try {
        const formData = new FormData();
        formData.append('formFile', this.selectedImage);
        const { promise } = XperdriveLib.ImageUploadAnonymous({ width: 1920, height: 1080 }, formData);
        const res = await promise;
        const url = res?.data?.result;
        if (url) {
          await this.addBackground({ url });
          this.close();
        } else {
          this.showToastNotification({ body: 'error.backgroundUploadError', config: { type: TOAST_TYPE.ERROR } });
        }
      } catch (err) {
        consoleError(err);
        this.showToastNotification({ body: 'error.backgroundUploadError', config: { type: TOAST_TYPE.ERROR } });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss">
.logo-upload {
  .area {
    cursor: pointer;
    border-radius: 3px;
    border: 1px dotted var(--v-light-gray-base);
    width: 100%;
    height: 100px;
    background-color: var(--v-light-gray-2-base);
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.preview-wrapper {
  img {
    max-width: 250px;
    max-height: 250px;
  }
}
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "settings-wrapper px-4 py-4" }, [
    _c(
      "div",
      [
        _c("AppSelect", {
          staticClass: "mt-1",
          attrs: { "hide-details": "", items: _vm.items },
          model: {
            value: _vm.selectedSettings,
            callback: function ($$v) {
              _vm.selectedSettings = $$v
            },
            expression: "selectedSettings",
          },
        }),
        _c(
          "div",
          {
            staticClass: "settings-contents",
            class: { "has-alert": _vm.infoAlert },
          },
          [
            _c("General", {
              key: "0",
              ref: "General",
              staticClass: "content d-flex flex-column",
              class: { active: _vm.selectedSettings === _vm.GENERAL },
            }),
            _c("Camera", {
              key: "1",
              ref: "Camera",
              staticClass: "content d-flex flex-column",
              class: { active: _vm.selectedSettings === _vm.CAMERA },
              attrs: { infoAlert: _vm.infoAlert },
            }),
            _c("Audio", {
              key: "2",
              ref: "Audio",
              staticClass: "content d-flex flex-column",
              class: { active: _vm.selectedSettings === _vm.AUDIO },
              attrs: { infoAlert: _vm.infoAlert },
            }),
            _c("Hotkeys", {
              key: "3",
              ref: "Hotkeys",
              staticClass: "content d-flex flex-column",
              class: { active: _vm.selectedSettings === _vm.HOTKEYS },
              attrs: {
                selected: _vm.selectedSettings === _vm.HOTKEYS,
                "selected-tab":
                  _vm.selectedTab === _vm.RIGHT_MENU_ITEMS.SETTINGS,
              },
            }),
            _c("Notifications", {
              key: "4",
              ref: "Notifications",
              staticClass: "content d-flex flex-column",
              class: { active: _vm.selectedSettings === _vm.NOTIFICATIONS },
            }),
            _c("Permissions", {
              key: "5",
              ref: "Permissions",
              staticClass: "content d-flex flex-column",
              class: { active: _vm.selectedSettings === _vm.PERMISSIONS },
            }),
          ],
          1
        ),
        _c(
          "v-alert",
          {
            attrs: { type: "info" },
            scopedSlots: _vm._u([
              {
                key: "close",
                fn: function () {
                  return [
                    _c("AppIcon", {
                      staticClass: "cursor-pointer",
                      attrs: { icon: "close-2" },
                      on: {
                        click: function ($event) {
                          _vm.infoAlert = false
                        },
                      },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.infoAlert,
              callback: function ($$v) {
                _vm.infoAlert = $$v
              },
              expression: "infoAlert",
            },
          },
          [
            _vm._v(
              " " + _vm._s(_vm.$t("rightMenu.settings.mustPressSave")) + " "
            ),
          ]
        ),
        _c(
          "AppButton",
          {
            staticClass: "save-button mt-3",
            attrs: { block: "", color: "var(--v-info-darken3)" },
            on: { click: _vm.save },
          },
          [_vm._v(_vm._s(_vm.$t("rightMenu.settings.save")))]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
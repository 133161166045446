var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "overlay-selector-wrapper" }, [
    _c(
      "div",
      { staticClass: "d-flex flex-column" },
      [
        _vm._l(_vm.design.overlayList, function (overlay, key) {
          return _c("Background", {
            key: key,
            staticClass: "mt-3",
            attrs: {
              background: overlay,
              selected: overlay.id === _vm.activeOverlayId,
            },
            on: {
              changeBackgroundSelected: _vm.changeOverlaySelected,
              deleteLogoFromList: _vm.deleteOverlayFromList,
            },
          })
        }),
        _c(
          "div",
          {
            staticClass:
              "add-overlay-selector mt-3 d-flex align-center justify-center cursor-pointer",
            on: { click: _vm.toggleOverlayUploadModal },
          },
          [
            _c("AppIcon", {
              attrs: {
                size: "36",
                icon: "plus",
                color: "var(--v-dark-gray-2-base)",
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
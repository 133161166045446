var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        title: _vm.$t(this.title),
        "max-width": "450",
        persistent: "",
        "show-close-button": !_vm.loading,
      },
    },
    [
      _c("AppInput", {
        attrs: { "max-length": 200, label: _vm.$t("bannerText") },
        model: {
          value: _vm.text,
          callback: function ($$v) {
            _vm.text = $$v
          },
          expression: "text",
        },
      }),
      _c("AppInput", {
        attrs: {
          label: `${_vm.$t("duration")} (${_vm.$t("second")})`,
          type: "number",
        },
        model: {
          value: _vm.duration,
          callback: function ($$v) {
            _vm.duration = $$v
          },
          expression: "duration",
        },
      }),
      _c(
        "AppButton",
        {
          attrs: { block: "", loading: _vm.loading, disabled: !_vm.text },
          on: { click: _vm.handleClick },
        },
        [_vm._v(_vm._s(_vm.$t("saveChanges")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="d-flex align-center color-selector-wrapper">
    <div>
      <v-menu v-model="menu" :close-on-content-click="false" offset-y>
        <template v-slot:activator="{ on, attrs }">
          <div
            v-bind="attrs"
            v-on="on"
            class="background-color-selector"
            :class="type"
            :id="`background-color-selector-${type}`"
            :style="{ background: backgroundColor }"
          ></div>
        </template>

        <v-card max-width="450">
          <v-list max-width="450">
            <v-list-item>
              <v-color-picker :value="defaultColor.code" dot-size="23" mode="hexa" swatches-max-height="200" @input="onColorChange"></v-color-picker>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </div>
    <div class="ml-5">
      <v-text-field v-if="type !== 'background'" outlined dense hide-details @input="changePickerText" :value="pickerText" @blur="onBlur" @focus="onFocus" />
      <div class="information-text" v-else>{{ $t('design.color') }}</div>
    </div>

    <div class="set-default-text cursor-pointer ml-3" @click="setDefault">{{ $t('design.setDefault') }}</div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { consoleError } from 'xpermeet-lib';
import debounce from 'lodash.debounce';

import { convertToHex } from '@/helpers/convert';
import { STUDIO } from '@/constants/modules';
import { DEFAULT_BANNER_COLOR, DEFAULT_BACKGROUND_COLOR } from '@/constants/colors';
import { SET_DESIGN } from '@/constants/mutation-types';
import { SETTINGS } from '@/constants/modules';

export default {
  name: 'ColorPicker',
  props: {
    type: {
      default: 'banner',
      type: String,
    },
  },
  data() {
    return {
      fav: true,
      menu: true,
      message: false,
      hints: true,
      backgroundColor: '',
      picker: null,
      pickerText: '',
      inputSelected: false,
    };
  },
  computed: {
    ...mapState(STUDIO, ['roomConfig']),
    roomConfigProperty() {
      return this.type === 'banner' ? this.roomConfig.design?.background : this.roomConfig.design?.studioBackgroundColor;
    },
    designType() {
      return this.type === 'banner' ? 'background' : 'studioBackgroundColor';
    },
    defaultColor() {
      const color = this.type === 'banner' ? DEFAULT_BANNER_COLOR.COLOR : DEFAULT_BACKGROUND_COLOR.COLOR;
      const code = this.type === 'banner' ? DEFAULT_BANNER_COLOR.CODE : DEFAULT_BACKGROUND_COLOR.CODE;
      return {
        color,
        code,
      };
    },
  },
  created() {
    this.backgroundColor = this.defaultColor.color;
  },
  mounted() {
    const elem = document.querySelector(`#background-color-selector-${this.type}`);
    let style = window.getComputedStyle ? window.getComputedStyle(elem) : elem.currentStyle;
    const background = this.roomConfigProperty ? this.roomConfigProperty : convertToHex(style.backgroundColor);
    this.picker = background;
    this.pickerText = background.replace('#', '');
    this.backgroundColor = background;
    this.menu = false;
  },
  methods: {
    ...mapMutations(STUDIO, [SET_DESIGN]),
    ...mapActions(SETTINGS, ['setBackgroundsPassive']),
    changePickerText: debounce(function (e) {
      this.onChangePickerText(e);
    }, 100),
    onColorChange: debounce(function (e) {
      if (typeof e === 'string') {
        if (!this.inputSelected) {
          this.pickerText = e.replace('#', '');
          this.backgroundColor = e;
          this.picker = e;
        }
        this[SET_DESIGN]({ type: this.designType, value: e });
      }
    }, 100),
    onChangePickerText(value) {
      this.backgroundColor = `#${value}`;
      this.pickerText = value;
      this.picker = value;
      this[SET_DESIGN]({ type: this.designType, value: `#${value}` });
    },
    onFocus() {
      this.inputSelected = true;
    },
    onBlur() {
      if (this.picker.toLowerCase() !== this.backgroundColor.toLowerCase()) {
        this.picker = this.backgroundColor;
        this.pickerText = this.backgroundColor.replace('#', '');
      }
      this.inputSelected = false;
    },
    setDefault() {
      this.onChangePickerText(this.defaultColor.code);
      if (this.type === 'background') {
        this.setAllBackgroundsPassive();
      }
    },
    async setAllBackgroundsPassive() {
      try {
        await this.setBackgroundsPassive();
      } catch (err) {
        consoleError(err);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.color-selector-wrapper {
  .background-color-selector {
    width: 24px;
    height: 24px;
    border-radius: 3px;
    border: 1px solid #ffffff;
    &.background {
      width: 108px;
      height: 56px;
    }
  }
  .information-text {
    color: var(--v-white-base);
    font-size: 15px;
    font-weight: 400;
  }
  .set-default-text {
    font-size: 13px;
    line-height: 15.23px;
    font-weight: 300;
    border-bottom: 1px solid #ffffff;
  }
}
</style>


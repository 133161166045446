var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "d-flex align-center color-selector-wrapper" },
    [
      _c(
        "div",
        [
          _c(
            "v-menu",
            {
              attrs: { "close-on-content-click": false, "offset-y": "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "div",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "background-color-selector",
                              class: _vm.type,
                              style: { background: _vm.backgroundColor },
                              attrs: {
                                id: `background-color-selector-${_vm.type}`,
                              },
                            },
                            "div",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
              model: {
                value: _vm.menu,
                callback: function ($$v) {
                  _vm.menu = $$v
                },
                expression: "menu",
              },
            },
            [
              _c(
                "v-card",
                { attrs: { "max-width": "450" } },
                [
                  _c(
                    "v-list",
                    { attrs: { "max-width": "450" } },
                    [
                      _c(
                        "v-list-item",
                        [
                          _c("v-color-picker", {
                            attrs: {
                              value: _vm.defaultColor.code,
                              "dot-size": "23",
                              mode: "hexa",
                              "swatches-max-height": "200",
                            },
                            on: { input: _vm.onColorChange },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "ml-5" },
        [
          _vm.type !== "background"
            ? _c("v-text-field", {
                attrs: {
                  outlined: "",
                  dense: "",
                  "hide-details": "",
                  value: _vm.pickerText,
                },
                on: {
                  input: _vm.changePickerText,
                  blur: _vm.onBlur,
                  focus: _vm.onFocus,
                },
              })
            : _c("div", { staticClass: "information-text" }, [
                _vm._v(_vm._s(_vm.$t("design.color"))),
              ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "set-default-text cursor-pointer ml-3",
          on: { click: _vm.setDefault },
        },
        [_vm._v(_vm._s(_vm.$t("design.setDefault")))]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
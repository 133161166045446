<template>
  <AppModal :title="$t('inactive.waitingForTheHost')" max-width="450" persistent :show-close-button="false">
    <div class="d-flex align-center justify-center">
      <div class="d-flex flex-column align-center text-center">
        <AppLogo class="my-4" :width="200" :redirectable="false" />
        <div>{{ $t('inactive.streamOpening') }}</div>
        <div class="d-flex mt-8">
          <div>
            <AppButton block @click="login">{{ $t('inactive.IamTheHost') }}</AppButton>
          </div>
        </div>
      </div>
    </div>
  </AppModal>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'IAmTheHostModal',
  methods: {
    ...mapActions('User', ['login']),
  },
};
</script>

<template>
  <div class="studio-design-wrapper mt-4">
    <div class="d-flex flex-column justify-space-between studio-design-wrapper--content">
      <div class="ml-4 studio-design-wrapper--body" :class="{ 'info-alert': infoAlert }">
        <div class="d-flex align-center">
          <div class="info-text">{{ $t('design.backThemeColor') }}</div>
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
            </template>
            <v-divider></v-divider>
            <div>{{ $t('design.backgroundSelectorInfo') }}</div>
          </v-tooltip>
        </div>
        <div class="d-flex align-center mt-4">
          <ColorPicker type="banner" />
        </div>
        <!-- <div class="d-flex align-center mt-5">
          <div class="info-text">{{ $t('design.theme') }}</div>
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
            </template>
            <v-divider></v-divider>
            <div>{{ $t('design.themeSelectorInfo') }}</div>
          </v-tooltip>
        </div>
         <v-row class="mt-4">
          <v-col cols="3" v-for="(theme, key) in themes" :key="key">
            <ThemeSelector :type="theme" :selected="selectedTheme === theme" @selectTheme="selectTheme" />
          </v-col>
        </v-row> -->
        <div class="d-flex align-center mt-5">
          <div class="info-text">{{ $t('design.displayName') }}</div>
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
            </template>
            <v-divider></v-divider>
            <div>{{ $t('design.hideUserNameSelectorInfo') }}</div>
          </v-tooltip>
        </div>
        <div class="mt-4">
          <v-switch color="var(--v-secondary-lighten1)" :label="$t('design.hideUserName')" hide-details v-model="hideUserName" @change="onSwitchChange" />
        </div>
        <div class="d-flex align-center mt-5">
          <div class="info-text">{{ $t('design.logo') }}</div>
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
            </template>
            <v-divider></v-divider>
            <div>{{ $t('design.logoDescription') }}</div>
          </v-tooltip>
        </div>
        <div class="mt-4">
          <LogoPicker @change="onLogoChange" />
        </div>
        <div class="d-flex align-center mt-5"><animated-elements /></div>
        <div class="d-flex align-center mt-5">
          <div class="info-text">{{ $t('design.overlay') }}</div>
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
            </template>
            <v-divider></v-divider>
            <div>{{ $t('design.overlayInfo') }}</div>
          </v-tooltip>
        </div>
        <div class="mt-4">
          <OverlaySelector />
        </div>
        <div class="d-flex align-center mt-5">
          <div class="info-text">{{ $t('design.background') }}</div>
          <v-tooltip top max-width="250">
            <template v-slot:activator="{ on, attrs }">
              <AppIcon v-bind="attrs" v-on="on" icon="info" size="14" color="var(--v-secondary-lighten1)" class="ml-2" />
            </template>
            <v-divider></v-divider>
            <div>{{ $t('design.studioBackgroundSelectorInfo') }}</div>
          </v-tooltip>
        </div>
        <div class="mt-4">
          <BackgroundSelector />
        </div>
      </div>

      <div class="px-4">
        <v-alert v-model="infoAlert" type="info">
          <template v-slot:close>
            <AppIcon class="cursor-pointer" icon="close-2" @click="infoAlert = false" />
          </template>
          {{ $t('design.clickSave') }}
        </v-alert>
        <AppButton class="save-button mt-3" block color="var(--v-info-darken3)" @click="save">{{ $t('rightMenu.settings.applyToStream') }}</AppButton>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from 'vuex';

import ColorPicker from '@/components/StudioMenu/StudioDesign/ColorPicker';
import AnimatedElements from '@/components/StudioMenu/StudioDesign/AnimatedElements/index.vue';
import LogoPicker from '@/components/StudioMenu/StudioDesign/LogoPicker';
import BackgroundSelector from '@/components/StudioMenu/StudioDesign/BackgroundSelector/index.vue';
import OverlaySelector from '@/components/StudioMenu/StudioDesign/OverlaySelector/index.vue';
import { SET_DESIGN } from '@/constants/mutation-types';
import { THEME_TYPES } from '@/constants/theme-types';
import { STUDIO } from '@/constants/modules';
// import ThemeSelector from '@/components/StudioMenu/StudioDesign/ThemeSelector';

const { FLAT, ROUNDED, BERRET } = THEME_TYPES;

export default {
  name: 'StudioDesign',
  components: { ColorPicker, LogoPicker /* ThemeSelector */, AnimatedElements, BackgroundSelector, OverlaySelector },
  data() {
    return {
      infoAlert: true,
      themes: [FLAT, ROUNDED, BERRET],
      selectedTheme: FLAT,
      hideUserName: false,
    };
  },
  computed: {
    ...mapState(STUDIO, ['design']),
  },
  methods: {
    ...mapMutations(STUDIO, [SET_DESIGN]),
    ...mapActions(STUDIO, ['changeRoomDesign']),
    save() {
      this.changeRoomDesign(this.design);
    },
    selectTheme(value) {
      this.selectedTheme = value;
    },
    onSwitchChange(e) {
      this[SET_DESIGN]({ type: 'hideUserName', value: e });
    },
    onLogoChange(value) {
      this[SET_DESIGN]({ type: 'logoList', value });
    },
  },
};
</script>

<style lang="scss" scoped>
.studio-design-wrapper {
  height: 100vh;
  &--content {
    height: 85%;
  }
  &--body {
    height: 90%;
    overflow-y: auto;
    &.info-alert {
      height: 75%;
    }
  }
  .info-text {
    font-size: 15px;
    font-weight: 300;
    line-height: 17.5px;
    color: #ffffff;
  }
}
</style>


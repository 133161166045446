var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "AppModal",
    {
      attrs: {
        title: _vm.$t(this.title),
        "max-width": "450",
        persistent: "",
        "show-close-button": !_vm.loading,
      },
    },
    [
      _c("AppTextField", {
        attrs: { label: _vm.$t("folderName") },
        model: {
          value: _vm.name,
          callback: function ($$v) {
            _vm.name = $$v
          },
          expression: "name",
        },
      }),
      _c(
        "AppButton",
        {
          attrs: { block: "", loading: _vm.loading },
          on: { click: _vm.handleClick },
        },
        [_vm._v(_vm._s(_vm.$t("saveChanges")))]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
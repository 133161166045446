var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "banners" },
    [
      !_vm.selectedFolder
        ? [
            _c(
              "div",
              {
                staticClass:
                  "banners--header d-flex justify-space-between align-center",
              },
              [
                _c("span", [_vm._v(_vm._s(_vm.$t("folders")))]),
                _c("AppIcon", {
                  staticClass: "cursor-pointer",
                  attrs: { icon: "folder-plus-outline" },
                  on: {
                    click: function ($event) {
                      return _vm.showAddFolderModal()
                    },
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "banners--folders" },
              _vm._l(_vm.banners, function (item) {
                return _c(
                  "div",
                  {
                    key: item.id,
                    staticClass:
                      "banners--folders--folder d-flex justify-space-between align-center cursor-pointer",
                    on: {
                      click: function ($event) {
                        _vm.selectedFolder = item
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("AppIcon", {
                          staticClass: "mx-5",
                          attrs: { icon: "folder" },
                        }),
                        _c("div", [
                          _c("div", [_vm._v(_vm._s(item.name))]),
                          _c("div", [
                            _vm._v(
                              _vm._s(item.banners.length) +
                                " " +
                                _vm._s(
                                  _vm.$t(
                                    item.banners.length > 1
                                      ? "banners"
                                      : "banner"
                                  )
                                )
                            ),
                          ]),
                        ]),
                      ],
                      1
                    ),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on, attrs }) {
                                return [
                                  _c(
                                    "v-btn",
                                    _vm._g(
                                      _vm._b(
                                        {
                                          staticClass: "more-actions",
                                          attrs: { icon: "" },
                                        },
                                        "v-btn",
                                        attrs,
                                        false
                                      ),
                                      on
                                    ),
                                    [
                                      _c("AppIcon", {
                                        staticClass: "ml-1",
                                        attrs: {
                                          size: "16",
                                          icon: "more-vertical",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      },
                      _vm._l(_vm.folderActions, function (action, index) {
                        return _c(
                          "v-list",
                          { key: index, staticClass: "app-action-list py-0" },
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return action.onClick(item)
                                  },
                                },
                              },
                              [
                                _c("v-list-item-content", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-item-icon mr-2" },
                                        [
                                          _c("AppIcon", {
                                            attrs: {
                                              icon: action.icon,
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-item-text",
                                          style: {
                                            color: action.color
                                              ? action.color
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t(action.name)) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                )
              }),
              0
            ),
          ]
        : [
            _c(
              "div",
              {
                staticClass:
                  "banners--header d-flex justify-space-between align-center",
              },
              [
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("AppIcon", {
                      staticClass: "cursor-pointer mr-2",
                      attrs: { icon: "chevron-left" },
                      on: {
                        click: function ($event) {
                          _vm.selectedFolder = null
                        },
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(_vm.selectedFolder.name))]),
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "d-flex align-center" },
                  [
                    _c("AppIcon", {
                      staticClass: "cursor-pointer",
                      attrs: { icon: "plus" },
                      on: {
                        click: function ($event) {
                          return _vm.showAddBannerModal()
                        },
                      },
                    }),
                    _c(
                      "v-menu",
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "more-actions",
                                        attrs: { icon: "" },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c("AppIcon", {
                                      staticClass: "ml-1",
                                      attrs: {
                                        size: "16",
                                        icon: "more-vertical",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      _vm._l(_vm.folderActions, function (action, index) {
                        return _c(
                          "v-list",
                          { key: index, staticClass: "app-action-list py-0" },
                          [
                            _c(
                              "v-list-item",
                              {
                                on: {
                                  click: function ($event) {
                                    return action.onClick(_vm.selectedFolder)
                                  },
                                },
                              },
                              [
                                _c("v-list-item-content", [
                                  _c(
                                    "div",
                                    { staticClass: "d-flex align-center" },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "list-item-icon mr-2" },
                                        [
                                          _c("AppIcon", {
                                            attrs: {
                                              icon: action.icon,
                                              width: "20",
                                              height: "20",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "span",
                                        {
                                          staticClass: "list-item-text",
                                          style: {
                                            color: action.color
                                              ? action.color
                                              : "#ffffff",
                                          },
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(_vm.$t(action.name)) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ]
            ),
            _c(
              "div",
              { staticClass: "banners--list" },
              _vm._l(_vm.selectedFolder.banners, function (banner) {
                return _c(
                  "div",
                  { key: banner.id, staticClass: "banners--list--banner-item" },
                  [
                    _c("div", {
                      staticClass: "banners--list--banner-item--border",
                      class: {
                        "on-queue": _vm.isOnQueue(banner.id),
                        current: _vm.currentBannerId === banner.id,
                      },
                    }),
                    _c("span", [_vm._v(_vm._s(banner.text))]),
                    _vm.isOnQueue(banner.id) ||
                    (_vm.currentBannerId === banner.id &&
                      _vm.getBannerSettings.parmanent)
                      ? _c(
                          "div",
                          {
                            staticClass:
                              "banners--list--banner-item--menu d-flex justify-center align-center banners--list--banner-item--menu--current",
                          },
                          [
                            _c(
                              "AppButton",
                              {
                                attrs: {
                                  text: "",
                                  large: "",
                                  plain: "",
                                  ripple: false,
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.toggleBanner(banner)
                                  },
                                },
                              },
                              [
                                _c("AppIcon", {
                                  staticClass: "mr-2 cursor-pointer",
                                  attrs: {
                                    icon: "minus-circle",
                                    width: "20",
                                    height: "20",
                                  },
                                }),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.getBannerSettings.parmanent
                                        ? _vm.$t("hide")
                                        : _vm.$t("removeFromQueue")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        )
                      : _c(
                          "div",
                          {
                            staticClass:
                              "banners--list--banner-item--menu d-flex justify-center align-center",
                            class: {
                              "cursor-pointer":
                                _vm.currentBannerId !== banner.id,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.toggleBanner(banner)
                              },
                            },
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "d-flex align-center",
                                attrs: { text: "", large: "", plain: "" },
                              },
                              [
                                _vm.currentBannerId !== banner.id
                                  ? _c("AppIcon", {
                                      staticClass: "mr-2 cursor-pointer",
                                      attrs: {
                                        icon: "plus",
                                        width: "20",
                                        height: "20",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.currentBannerId === banner.id
                                        ? _vm.$t("current")
                                        : _vm.$t("show")
                                    ) +
                                    " "
                                ),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "d-flex banners--list--banner-item--menu--items",
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                  },
                                },
                              },
                              [
                                _c("AppIcon", {
                                  staticClass: "mr-2 cursor-pointer",
                                  attrs: {
                                    icon: "pen",
                                    width: "20",
                                    height: "20",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.showAddBannerModal(banner)
                                    },
                                  },
                                }),
                                _c("AppIcon", {
                                  staticClass: "cursor-pointer",
                                  attrs: {
                                    icon: "trash",
                                    width: "20",
                                    height: "20",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.deleteBanner(banner)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ]
                        ),
                  ]
                )
              }),
              0
            ),
          ],
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-menu close-on-content-click offset-y transition="scale-transition">
    <template v-slot:activator="{ on, attrs }">
      <div class="pl-1 py-1 mr-3 d-flex align-center destination-menu-selector-wrapper cursor-pointer" v-on="on">
        <DestinationAvatar size="28" class="message-avatar" :avatar="destination.avatarUrl" :destination-types="channel" icon-position="right" />
        <AppIcon
          :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'"
          color="var(--v-text-gray-base)"
          width="24"
          class="mr-2 destination-menu-icon"
        />
      </div>
    </template>
    <v-list>
      <v-list-item-title>
        <div class="d-flex pa-4">
          <DestinationAvatar
            size="50"
            class="message-avatar mr-3"
            :avatar="destination.avatarUrl"
            :destination-types="channel"
            icon-position="right"
            icon-height="25"
          />
          <div class="d-flex flex-column justify-center item-head-wrapper">
            <v-tooltip bottom max-width="300">
              <template v-slot:activator="{ on, attrs }">
                <span class="item-head-title ellipsis" v-bind="attrs" v-on="on">{{ destination.title }}</span>
              </template>
              <span>{{ destination.title }}</span>
            </v-tooltip>
            <span class="item-head-user">{{ destination.userProviderDetail.displayName }}</span>
          </div>
        </div>
        <div class="d-flex align-center ready-to-go mx-4 pa-2" :class="{ started: webinarSessionId }">
          <AppIcon size="20" icon="check-1" :color="webinarSessionId ? 'success' : ''" />
          <span class="ready-text ml-3">{{ $t(youAreLiveText, { destination: channel }) }}</span>
        </div>
      </v-list-item-title>
      <v-list-item v-if="isModerator" class="my-2" dense @click="showAddEditModal" :ripple="false">
        <div class="d-flex align-center px-2 py-2">
          <AppIcon size="20" icon="pen" color="var(--v-text-gray-base)" />
          <span class="destination-list-text ml-3">{{ $t('edit') }}</span>
        </div>
      </v-list-item>

      <v-list-item v-if="showRemoveListItem" class="my-2" dense @click="showDeleteBroadcastModal">
        <div class="d-flex align-center px-2 py-2">
          <AppIcon size="20" icon="trash" color="var(--v-text-gray-base)" />
          <span class="destination-list-text ml-3">{{ $t('remove') }}</span>
        </div>
      </v-list-item>
      <v-divider :class="{ 'mt-2': !isModerator }"></v-divider>
      <v-list-item class="mt-2" dense>
        <a :href="destination.viewUrl" target="_blank" class="d-flex align-center px-2 py-2 view-on">
          <ProviderIcon class="stream-image" :provider="channel" style="height: 28px" />
          <span class="destination-list-text ml-3">{{ $t('viewOn', { channel: channel }) }}</span>
        </a>
      </v-list-item>
    </v-list>
  </v-menu>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';

import { STUDIO } from '@/constants/modules';
import DestinationAvatar from '@/components/DestinationAvatar';
import AddEditBroadcastModal from '@/components/Modals/AddEditBroadcastModal';
import DeleteBroadcastModal from '@/components/Modals/DeleteBroadcastModal';

export default {
  name: 'DestinationMenu',
  components: { DestinationAvatar },
  props: {
    destination: Object,
  },
  computed: {
    ...mapState(STUDIO, ['currentSession', 'webinarSessionId']),
    ...mapGetters(STUDIO, ['getLocalUser']),
    channel() {
      return this.destination.userProviderDetail.providerShortCode;
    },
    isModerator() {
      return this.getLocalUser?.isModerator;
    },
    showRemoveListItem() {
      return this.isModerator && this.currentSession.items.length > 1 && this.currentSession.status !== 'live';
    },
    youAreLiveText() {
      return this.webinarSessionId ? 'youAreLiveOnDestination' : 'readyGoToDestination';
    },
  },
  methods: {
    ...mapActions(STUDIO, ['updateCurrentSession']),
    showAddEditModal() {
      const modal = this.$showModal(
        AddEditBroadcastModal,
        {
          broadcast: this.currentSession,
          selectedDestinationOauthId: this.destination.userProviderDetail.oauthId,
          editOn: 'studio',
        },
        {
          completed: () => {
            modal.close();
            this.updateCurrentSession();
          },
        },
      );
    },
    showDeleteBroadcastModal() {
      const modal = this.$showModal(
        DeleteBroadcastModal,
        { broadcast: this.currentSession, selectedBroadcast: this.destination },
        {
          close: () => {
            modal.close();
          },
          deleted: () => {
            modal.close();
            this.updateCurrentSession();
          },
        },
      );
    },
  },
};
</script>
<style lang="scss" scoped>
.item-head-wrapper {
  color: white;
  .item-head-title {
    font-size: 18px;
    font-weight: 500;
    line-height: 21px;
    max-width: 200px;
  }
  .item-head-user {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }
}
.ready-to-go {
  border: 1px solid var(--v-matterhorn-base);
  color: white;
  border-radius: 5px;
  .ready-text {
    font-size: 13px;
    font-weight: 400;
    line-height: 15px;
  }
  &.started {
    border: 1px solid var(--v-success-base);
    .ready-text {
      color: var(--v-success-base);
    }
  }
}
.destination-list-text {
  font-size: 13px;
  font-weight: 400;
  line-height: 15px;
}
.destination-menu-selector-wrapper {
  background: var(--v-theme-btn-color-base);
  border-radius: 4px;
  height: 44px;
}

.view-on {
  color: var(--v-white-base);
  text-decoration: auto;
}
</style>

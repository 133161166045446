var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "logo-picker d-flex" },
      [
        _vm._l(_vm.logoList, function (logo) {
          return _c(
            "DesignBox",
            {
              key: logo.url,
              staticClass: "mr-2 logo-box",
              attrs: {
                active: logo.active,
                selected: logo.id === _vm.selectedLogoId,
              },
              nativeOn: {
                click: function ($event) {
                  _vm.selectedLogoId = logo.id
                },
              },
            },
            [
              _c("img", {
                staticClass: "logo",
                attrs: { src: logo.url, alt: "logo" },
                on: {
                  error: function ($event) {
                    return _vm.deleteLogoFromList(logo.id)
                  },
                },
              }),
              _c(
                "div",
                { staticClass: "logo-menu" },
                [
                  _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "AppIconButton",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "mx-1",
                                        attrs: {
                                          width: 30,
                                          "icon-size": "16px",
                                          icon: logo.active
                                            ? "eye-off"
                                            : "eye-on",
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.stopPropagation()
                                            return _vm.changeLogoVisibility(
                                              logo.id
                                            )
                                          },
                                        },
                                      },
                                      "AppIconButton",
                                      attrs,
                                      false
                                    ),
                                    on
                                  )
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        true
                      ),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t(logo.active ? "hide" : "show"))),
                      ]),
                    ]
                  ),
                  !logo.parmanent
                    ? _c(
                        "v-tooltip",
                        {
                          attrs: { bottom: "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "AppIconButton",
                                      _vm._g(
                                        _vm._b(
                                          {
                                            staticClass: "mx-1",
                                            attrs: {
                                              width: 30,
                                              "icon-size": "16px",
                                              icon: "minus-circle",
                                            },
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                return _vm.deleteLogoFromList(
                                                  logo.id
                                                )
                                              },
                                            },
                                          },
                                          "AppIconButton",
                                          attrs,
                                          false
                                        ),
                                        on
                                      )
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            true
                          ),
                        },
                        [_c("span", [_vm._v(_vm._s(_vm.$t("delete")))])]
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          )
        }),
        _c(
          "DesignBox",
          {
            staticClass: "mr-2",
            nativeOn: {
              click: function ($event) {
                return _vm.toggleLogoUploadModal.apply(null, arguments)
              },
            },
          },
          [
            _c("AppIcon", {
              attrs: {
                size: "36",
                icon: "plus",
                color: "var(--v-dark-gray-2-base)",
              },
            }),
          ],
          1
        ),
      ],
      2
    ),
    _vm.showLogoOptions
      ? _c(
          "div",
          { staticClass: "d-flex align-center opacity-picker" },
          [
            _c("span", { staticClass: "picker-title" }, [
              _vm._v(_vm._s(_vm.$t("opacity"))),
            ]),
            _c("v-slider", {
              attrs: {
                color: "white",
                "track-color": "white",
                "hide-details": "",
                hint: "opacity",
                max: "100",
                min: "0",
              },
              model: {
                value: _vm.opacity,
                callback: function ($$v) {
                  _vm.opacity = $$v
                },
                expression: "opacity",
              },
            }),
            _c("span", { staticClass: "picker-title" }, [
              _vm._v(_vm._s(_vm.opacity) + "%"),
            ]),
          ],
          1
        )
      : _vm._e(),
    _vm.showLogoOptions
      ? _c(
          "div",
          { staticClass: "d-flex align-center opacity-picker" },
          [
            _c("span", { staticClass: "picker-title" }, [
              _vm._v(_vm._s(_vm.$t("size"))),
            ]),
            _c("v-slider", {
              attrs: {
                color: "white",
                "track-color": "white",
                "hide-details": "",
                hint: "opacity",
                max: "20",
                min: "3",
              },
              model: {
                value: _vm.size,
                callback: function ($$v) {
                  _vm.size = $$v
                },
                expression: "size",
              },
            }),
            _c("span", { staticClass: "picker-title" }, [
              _vm._v(_vm._s(_vm.$t("max"))),
            ]),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div class="camera-device-settings py-1" :class="{ 'has-alert': infoAlert }">
    <DeviceSelector :infoAlert="infoAlert" ref="deviceSelector" hide-microphone-settings hide-speaker-settings @cameraChange="handleCameraChange" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeviceSelector from '@/components/DeviceSelectors/DeviceSelector';
import { DEVICES } from '@/constants/modules';

export default {
  name: 'CameraSettings',
  components: { DeviceSelector },
  props: {
    infoAlert: Boolean,
  },
  data() {
    return {
      selectedCameraId: null,
    };
  },
  computed: {
    ...mapGetters(DEVICES, ['hasCamera', 'cameraAllowed', 'getSelectedCameraId']),
    defaultCameraDeviceName() {
      return this.getCameras.find((item) => item.deviceId === this.selectedCameraId)?.label;
    },
  },
  methods: {
    ...mapActions(DEVICES, ['changeDevice', 'changeCameraById']),
    handleCameraChange(deviceId) {
      this.selectedCameraId = deviceId;
    },
    save() {
      if (this.selectedCameraId && this.selectedCameraId !== this.getSelectedCameraId) {
        this.changeCameraById(this.selectedCameraId);
      }
    },
  },
  watch: {
    getSelectedCameraId: {
      handler: function () {
        this.selectedCameraId = this.getSelectedCameraId || null;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep .quality-settings {
  .v-slider__tick-label {
    padding-top: 8px;
    white-space: initial;
    font-size: 13px;
  }

  .advanced-settings-title {
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 27px;
    letter-spacing: 0px;
    opacity: 0.9;
    color: var(--v-white-base);
  }
  .v-slider__tick {
    background: var(--v-primary-base);
    border-radius: 50%;

    &.v-slider__tick--filled {
      background: var(--v-primary-base);
      border-radius: 50%;
    }
    .v-slider__tick-label {
      font-weight: 400;
      font-size: 12px;
      line-height: 18px;
      opacity: 0.9;
    }
  }
}
.camera-device-settings {
  max-height: calc(100vh - 190px);
  &.has-alert {
    max-height: calc(100vh - 250px);
  }
  overflow: auto;
}
</style>

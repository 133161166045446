var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "studio-chat-wrapper d-flex justify-space-between flex-column",
    },
    [
      _c(
        "div",
        {
          ref: "messageBodyElement",
          staticClass: "messages px-1 pb-4",
          class: {
            stream: _vm.type === "stream",
            "not-moderator": !_vm.getLocalUser.isModerator,
            "any-destination":
              _vm.getLocalUser.isModerator && !_vm.currentSession.items.length,
          },
        },
        [
          _vm._l(_vm.filteredMessages, function (message, key) {
            return _c("Message", {
              key: key,
              attrs: {
                message: message,
                "stream-status": "pending",
                "is-pending":
                  _vm.messageStatus[message.uuid] &&
                  _vm.messageStatus[message.uuid].pending.length,
              },
              on: { sendAgain: _vm.sendAgain },
            })
          }),
          _c("IntersectionObserver", { on: { intersect: _vm.onIntersect } }),
        ],
        2
      ),
      _c("div", [
        _vm.type === "stream" &&
        _vm.currentSession &&
        _vm.currentSession.items.length &&
        _vm.getLocalUser.isModerator
          ? _c(
              "div",
              { staticClass: "d-flex ml-2 justify-space-between" },
              [
                _c("ChatStreamSelector", {
                  attrs: {
                    "youtube-providers": _vm.youtubeProviders,
                    "twitch-providers": _vm.twitchProviders,
                    "short-codes": _vm.shortCodes,
                    "selected-short-code": _vm.selectedShortCode,
                  },
                  on: {
                    setShortCode: _vm.setShortCode,
                    setAnySelectedStream: _vm.setAnySelectedStream,
                    setSelectedProviderPayload: _vm.setSelectedProviderPayload,
                    setTargetEmpty: _vm.setTargetEmpty,
                  },
                }),
                _vm.shortCodes.length > 1
                  ? _c(
                      "div",
                      { staticClass: "short-codes mr-2" },
                      [
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", "max-width": "400" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "AppIcon",
                                        _vm._g(
                                          _vm._b(
                                            { attrs: { icon: "info" } },
                                            "AppIcon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1047403614
                            ),
                          },
                          [
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("forUsingShortCode"))),
                            ]),
                            _c("v-divider"),
                            _c("div", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("shortCodeList", {
                                    shortCodes: _vm.shortCodesText,
                                  })
                                )
                              ),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            )
          : _vm._e(),
        _vm.type !== "stream" ||
        (_vm.currentSession &&
          _vm.currentSession.items.length &&
          _vm.getLocalUser.isModerator)
          ? _c(
              "div",
              [
                _c("ChatInput", {
                  attrs: {
                    "short-codes": _vm.shortCodes,
                    type: _vm.type,
                    "disable-chat": _vm.disableChat,
                  },
                  on: {
                    setAnySelectedStream: _vm.setAnySelectedStream,
                    onFocus: _vm.onFocus,
                    send: _vm.send,
                  },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "div",
          { staticClass: "viewers-information d-flex align-center mt-2" },
          [
            _c("AppIcon", {
              staticClass: "ml-4",
              attrs: { icon: "viewer-eyes", size: "15" },
            }),
            _c("span", { staticClass: "ml-1" }, [
              _vm._v(_vm._s(_vm.viewerCountText)),
            ]),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <div class="logo-picker d-flex">
      <DesignBox
        v-for="logo in logoList"
        :key="logo.url"
        :active="logo.active"
        :selected="logo.id === selectedLogoId"
        class="mr-2 logo-box"
        @click.native="selectedLogoId = logo.id"
      >
        <img class="logo" :src="logo.url" alt="logo" @error="deleteLogoFromList(logo.id)" />
        <div class="logo-menu">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <AppIconButton
                class="mx-1"
                :width="30"
                icon-size="16px"
                :icon="logo.active ? 'eye-off' : 'eye-on'"
                v-bind="attrs"
                v-on="on"
                @click.stop="changeLogoVisibility(logo.id)"
              />
            </template>
            <span>{{ $t(logo.active ? 'hide' : 'show') }}</span>
          </v-tooltip>
          <v-tooltip bottom v-if="!logo.parmanent">
            <template v-slot:activator="{ on, attrs }">
              <AppIconButton
                class="mx-1"
                :width="30"
                icon-size="16px"
                :icon="'minus-circle'"
                v-bind="attrs"
                v-on="on"
                @click.stop="deleteLogoFromList(logo.id)"
              />
            </template>
            <span>{{ $t('delete') }}</span>
          </v-tooltip>
        </div>
      </DesignBox>
      <DesignBox class="mr-2" @click.native="toggleLogoUploadModal">
        <AppIcon size="36" icon="plus" color="var(--v-dark-gray-2-base)" />
      </DesignBox>
    </div>

    <div class="d-flex align-center opacity-picker" v-if="showLogoOptions">
      <span class="picker-title">{{ $t('opacity') }}</span>
      <v-slider v-model="opacity" color="white" track-color="white" hide-details hint="opacity" max="100" min="0"></v-slider>
      <span class="picker-title">{{ opacity }}%</span>
    </div>
    <div class="d-flex align-center opacity-picker" v-if="showLogoOptions">
      <span class="picker-title">{{ $t('size') }}</span>
      <v-slider v-model="size" color="white" track-color="white" hide-details hint="opacity" max="20" min="3"></v-slider>
      <span class="picker-title">{{ $t('max') }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex';
import debounce from 'lodash.debounce';
import { consoleError } from 'xpermeet-lib';

import DesignBox from '@/components/Common/DesignBox';
import { SETTINGS } from '@/constants/modules';
import { SET_SHOW_LOGO_UPLOAD_MODAL } from '@/constants/mutation-types';

export default {
  name: 'LogoPicker',
  components: {
    DesignBox,
  },
  data() {
    return {
      showLogoUploadModal: false,
      opacity: 50,
      size: 10,
      selectedLogoId: null,
    };
  },
  computed: {
    ...mapState(SETTINGS, ['logoList']),
    showLogoOptions() {
      return this.selectedLogoId !== null && !this.getSelectedLogo?.parmanent;
    },
    getSelectedLogo() {
      return this.logoList.find((l) => l.id === this.selectedLogoId);
    },
  },
  methods: {
    ...mapMutations(SETTINGS, [SET_SHOW_LOGO_UPLOAD_MODAL]),
    ...mapActions(SETTINGS, ['deleteLogo', 'toggleLogo', 'updateLogo']),
    async changeLogoVisibility(id) {
      try {
        const newLogoList = await this.toggleLogo({ id });
        this.$emit('change', newLogoList);
      } catch (err) {
        consoleError(err);
      }
    },
    toggleLogoUploadModal(state) {
      this[SET_SHOW_LOGO_UPLOAD_MODAL](state !== undefined ? state : !this.showLogoUploadModal);
    },
    async deleteLogoFromList(id) {
      try {
        await this.deleteLogo({ id });
      } catch (err) {
        consoleError(err);
      }
    },
    mergeAndGetUpdatePayload(style) {
      if (!this.getSelectedLogo) {
        return {};
      }

      return {
        id: this.selectedLogoId,
        partialData: {
          style: {
            ...this.getSelectedLogo.style,
            ...style,
          },
        },
      };
    },
    async saveLogoStyle(style) {
      if (this.getSelectedLogo) {
        const newLogoList = await this.updateLogo(this.mergeAndGetUpdatePayload(style));
        this.$emit('change', newLogoList);
      }
    },
    handleOpacityChange: debounce(function () {
      this.saveLogoStyle({ opacity: `${this.opacity}%` });
    }, 500),
    handleSizeChange: debounce(function () {
      this.saveLogoStyle({ width: `${this.size}%` });
    }, 500),
  },
  watch: {
    opacity: 'handleOpacityChange',
    size: 'handleSizeChange',
    selectedLogoId() {
      if (this.showLogoOptions) {
        this.opacity = +(this.getSelectedLogo?.style?.opacity || '50').replace(/%/gi, '');
        this.size = (this.getSelectedLogo?.size || '10').replace(/%/gi, '');
      }
    },
  },
};
</script>

<style lang="scss">
.logo-picker {
  overflow-x: auto;
  width: 100%;
  .logo {
    max-width: 80px;
    max-height: 80px;
  }
  .logo-box {
    position: relative;

    &:hover {
      .logo-menu {
        opacity: 1;
      }
    }

    .logo-menu {
      transition: all 500ms;
      background-color: var(--v-black-40);
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0;
    }
  }
}

.opacity-picker {
  max-width: 300px;

  .picker-title {
    width: 50px;
  }
}
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("v-switch", {
        attrs: {
          color: "var(--v-secondary-lighten1)",
          label: _vm.$t(`rightMenu.settings.notificationSoundsOnOff`),
          "hide-details": "",
        },
        model: {
          value: _vm.soundSettings[_vm.SOUNDS_ON_OFF],
          callback: function ($$v) {
            _vm.$set(_vm.soundSettings, _vm.SOUNDS_ON_OFF, $$v)
          },
          expression: "soundSettings[SOUNDS_ON_OFF]",
        },
      }),
      _c("v-switch", {
        attrs: {
          color: "var(--v-secondary-lighten1)",
          label: _vm.$t("rightMenu.settings.userJoinedSound"),
          disabled: !_vm.soundSettings[_vm.SOUNDS_ON_OFF],
          "hide-details": true,
        },
        model: {
          value: _vm.soundSettings[_vm.USER_JOINED],
          callback: function ($$v) {
            _vm.$set(_vm.soundSettings, _vm.USER_JOINED, $$v)
          },
          expression: "soundSettings[USER_JOINED]",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
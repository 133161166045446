<template>
  <div class="device-selector microphone-device-selector">
    <template v-if="hasMicrophone">
      <div
        class="microphone-device-selector--item pr-2 py-3 d-flex align-center"
        :class="{ selected: selectedMicId === item.deviceId }"
        v-for="item in getMicrophones"
        :key="item.deviceId"
        @click="selectedMicId = item.deviceId"
        role="button"
      >
        <span class="microphone-text ellipsis ml-2" style="max-width: 220px">{{ item.label }}</span>
        <v-spacer />
        <MicrophoneMeter class="ml-4" :level="audioLevels[item.deviceId]" />
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import MicrophoneMeter from '@/components/DeviceSelectors/SettingsMicrophoneMeter';
import { DEVICES } from '@/constants/modules';
import { getInstance } from '@/xpermeet';

const xperMeetLib = getInstance();

export default {
  name: 'MicrophoneDeviceSelector',
  components: { MicrophoneMeter },
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
    },
  },
  data() {
    return {
      audioLevels: {},
      audioTracks: [],
      selectedMicId: this.value || null,
    };
  },
  computed: {
    ...mapGetters(DEVICES, ['getMicrophones', 'hasMicrophone']),
  },
  mounted() {
    this.initAudioLevel();
  },
  beforeDestroy() {
    this.clearTracks();
  },
  methods: {
    initAudioLevel() {
      this.clearTracks();

      this.getMicrophones.forEach((microphoneDevice) => {
        xperMeetLib.lib.createLocalTracks({ devices: ['audio'], micDeviceId: microphoneDevice.deviceId }).then((tracks) => {
          this.audioTracks.push(tracks[0]);
          tracks[0].on(xperMeetLib.lib.events.track.TRACK_AUDIO_LEVEL_CHANGED, (level) => {
            this.$set(this.audioLevels, microphoneDevice.deviceId, level);
          });
        });
      });
    },
    clearTracks() {
      this.audioTracks.forEach((track) => {
        track.removeAllListeners(xperMeetLib.lib.events.track.TRACK_AUDIO_LEVEL_CHANGED);
        track.dispose();
      });
      this.audioTracks = [];
    },
    onChange(item) {
      this.$emit('change', item);
    },
    onAudioLevelChange() {
      this.$emit('onAudioLevelChange', this.audioLevels);
    },
  },
  watch: {
    selectedMicId: 'onChange',
    audioLevels: 'onAudioLevelChange',
  },
};
</script>

<style lang="scss">
.microphone-device-selector {
  max-height: calc(100vh - 190px);
  overflow: auto;
  border-radius: 15px;
  border: 1px solid var(--v-primary-lighten2);
  background-color: var(--v-right-menu-main-base);
  .microphone-text {
    font-size: 12px !important;
    font-weight: 400;
    line-height: 1.25rem;
    letter-spacing: 0.0178571429em !important;
  }
  &.has-alert {
    max-height: calc(100vh - 250px);
  }
  &--item:first-child {
    border-radius: 15px 15px 0 0;
  }

  &--item:last-child {
    border-radius: 0 0 15px 15px;
  }

  &--item {
    &.selected {
      background-color: var(--v-right-menu-main-lighten1);

      &::before {
        content: '';
        width: 5px;
        height: 5px;
        background-color: var(--v-error-base);
        border-radius: 50%;
        margin-left: 1rem;
      }
    }
  }
}
</style>


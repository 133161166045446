export const ANIMATED_ELEMENT_TYPES = {
  ANIMATED_SUBTITLE: 'animatedSubtitle',
  // COUNTDOWN: 'countdown',
  // LIVE_TAG: 'liveTag',
  // SOCIAL_CONTACTS: 'socialContacts',
};

export const ANIMATED_ELEMENTS = [
  {
    type: ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE,
    translatedTitle: false,
    title: 'ABC',
    extraProperties: [
      { type: 'text', defaultValue: '' },
      { type: 'textSpeed', defaultValue: 2 },
      { type: 'cornerNotch', defaultValue: false },
      { type: 'hidden', defaultValue: false },
    ],
  },
  // {
  //   type: ANIMATED_ELEMENT_TYPES.COUNTDOWN,
  //   translatedTitle: false,
  //   title: '00:00',
  //   extraProperties: [],
  // },
  // {
  //   type: ANIMATED_ELEMENT_TYPES.LIVE_TAG,
  //   translatedTitle: true,
  //   title: 'live',
  //   extraProperties: [],
  // },
  // {
  //   type: ANIMATED_ELEMENT_TYPES.SOCIAL_CONTACTS,
  //   translatedTitle: true,
  //   title: 'sc',
  //   extraProperties: [],
  // },
];

export const CORNER_NOTCH_TYPES = [
  {
    isMultiple: true,
    type: 'textOnly',
    maxChar: 30,
    icon: 'only-text',
  },
  {
    isMultiple: true,
    type: 'twitter',
    maxChar: 15,
    icon: 'twitter',
  },
  {
    isMultiple: true,
    type: 'youtube',
    maxChar: 30,
    icon: 'youtube',
  },
  {
    isMultiple: true,
    type: 'instagram',
    maxChar: 30,
    icon: 'instagram',
  },
  {
    isMultiple: true,
    type: 'telegram',
    maxChar: 30,
    icon: 'telegram',
  },
  {
    isMultiple: true,
    type: 'linkedin',
    maxChar: 30,
    icon: 'linkedin',
  },
  {
    isMultiple: true,
    type: 'twitch',
    maxChar: 30,
    icon: 'twitch',
  },
  {
    isMultiple: false,
    type: 'time',
    icon: 'clock',
  },
];


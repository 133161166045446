<template>
  <div class="text-center">
    <div class="design-box d-flex justify-center align-center cursor-pointer" :class="{ active: active }">
      <slot></slot>
    </div>
    <AppIcon v-if="selected" icon="chevron-up" color="success" />
  </div>
</template>

<script>
export default {
  name: 'ThemeSelector',
  props: {
    selected: Boolean,
    active: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.design-box {
  min-width: 80px;
  min-height: 80px;
  max-width: 80px;
  max-height: 80px;
  border-radius: 3px;
  background: var(--v-light-gray-2-lighten4);

  &.active {
    border: 3px solid var(--v-border-orange);
    .selector-type {
      background: var(--v-bg-gray-50);
    }
  }

  img {
    max-width: 100%;
  }
}
</style>


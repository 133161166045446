import { render, staticRenderFns } from "./StudioSettings.vue?vue&type=template&id=7dd38bb3&scoped=true&"
import script from "./StudioSettings.vue?vue&type=script&lang=js&"
export * from "./StudioSettings.vue?vue&type=script&lang=js&"
import style0 from "./StudioSettings.vue?vue&type=style&index=0&id=7dd38bb3&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7dd38bb3",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/xpermeet-web/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7dd38bb3')) {
      api.createRecord('7dd38bb3', component.options)
    } else {
      api.reload('7dd38bb3', component.options)
    }
    module.hot.accept("./StudioSettings.vue?vue&type=template&id=7dd38bb3&scoped=true&", function () {
      api.rerender('7dd38bb3', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/StudioMenu/StudioSettings.vue"
export default component.exports
<template>
  <div class="studio-bottom-menu d-flex justify-center pt-1">
    <MicrophoneSelectButton :type="studio" class="mr-1" studio @toggle="setAudioMute(!user.audioMuted)" :is-active="microphoneAllowed && !user.audioMuted" />
    <span class="mx-2" />
    <CameraSelectButton :type="studio" class="mr-1" studio @toggle="setVideoMute(!user.videoMuted)" disabled :is-active="cameraAllowed && !user.videoMuted" />
    <span class="mx-2" />
    <SpeakerSelectButton :type="studio" class="mr-1" studio @toggle="setSpeakerMute(!speakerMuted)" :is-active="!speakerMuted" />
    <span class="mx-2" />
    <AppMenuButton
      icon="monitor-1"
      :loading="screenShareOnprogress"
      :disabled="disabled"
      :color="user.screenSharing ? 'success' : ''"
      @click="toggleScreenShare"
    />
    <span class="mx-2" />
    <AppMenuButton :disabled="!isUserLogin" icon="user-add" @click="showInviteParticipantModal" />
    <span class="mx-6" />
    <AppMenuButton :data-test-id="close" icon="call" color="error" @click="leaveStudio" />
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

import MicrophoneSelectButton from './MicrophoneSelectButton';
import CameraSelectButton from './CameraSelectButton';
import SpeakerSelectButton from './SpeakerSelectButton';
import InviteParticipantModal from '@/components/Modals/InviteParticipantModal';
import { STUDIO, DEVICES } from '@/constants/modules';
import { isAuthenticated } from '@/helpers/keycloak';

export default {
  name: 'StudioBottomMenu',
  components: { MicrophoneSelectButton, CameraSelectButton, SpeakerSelectButton },
  data: () => ({
    screenShareOnprogress: false,
  }),
  computed: {
    ...mapState(STUDIO, ['speakerMuted', 'currentSession']),
    ...mapGetters(STUDIO, ['getLocalUser', 'remoteRecordStartedBy', 'getCurrentBroadcastTitle']),
    ...mapGetters(DEVICES, ['cameraAllowed', 'microphoneAllowed']),
    user() {
      return this.getLocalUser || {};
    },
    disableScreenShare() {
      return !!(this.remoteRecordStartedBy === this.user.id && this.user?.role === 'moderator');
    },
    isUserLogin() {
      return isAuthenticated();
    },
    studio() {
      return 'studio';
    },
    close() {
      return 'studio-close';
    },
  },
  methods: {
    ...mapActions(STUDIO, ['setVideoMute', 'setAudioMute', 'setSpeakerMute', 'leaveCall', 'startScreenShare', 'stopScreenShare']),
    leaveStudio() {
      const confirmModal = this.$showConfirmModal(
        {
          title: this.$t('studio.leaveStudio'),
          text: this.$t('studio.doYouWantToLeaveTheStudio'),
        },
        {
          confirm: () => {
            confirmModal.close();
            this.$router.push('/');
          },
          cancel: () => {
            confirmModal.close();
          },
        },
      );
    },
    showInviteParticipantModal() {
      const modal = this.$showModal(
        InviteParticipantModal,
        { sessionId: this.currentSession.sessionId, sessionName: this.getCurrentBroadcastTitle },
        {
          close: () => {
            modal.close();
          },
        },
      );
    },
    toggleScreenShare() {
      if (!this.disableScreenShare) {
        if (this.user.screenSharing) {
          this.stopScreenShare();
        } else {
          this.screenShareOnprogress = true;
          this.startScreenShare().finally(() => {
            this.screenShareOnprogress = false;
          });
        }
      }
    },
  },
};
</script>


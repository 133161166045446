<template>
  <div>
    <v-menu v-model="showMenu" top offset-y :close-on-content-click="false" :disabled="providerItems.length < 2" @input="onMenuHandled">
      <template v-slot:activator="{ on, attrs }">
        <span :class="{ 'cursor-default': providerItems.length < 2 }" v-bind="attrs" v-on="on">
          {{ $t('to') }} :
          <span class="selector-span">{{ selectedProviders }}</span>
        </span>
      </template>
      <v-list color="var(--v-chat-input-color)" class="py-0">
        <div v-for="(item, index) in filteredProviderItems" :key="index" class="cursor-pointer" @click="selectItem(item)">
          <div v-if="!selectedCode" class="d-flex justify-start align-center py-2 chat-stream-selector-list w-full" :class="{ active: item.selected }">
            <div class="chat-stream-selector-list--icon">
              <AppIcon v-show="item.selected" size="14" icon="check" class="ml-2" />
            </div>
            <span class="ml-1 mr-4">{{ item.orderedTitle || item.title }}</span>
          </div>
          <div v-else class="d-flex align-center py-2 w-full chat-stream-selector-list-with-short-code" :class="{ active: item.selected }">
            <span class="ml-2 stream-small-font">{{ index + 1 }}</span>
            <ProviderIcon class="ml-2 stream-image" :provider="selectedCode" />
            <span class="ml-2 mr-4 stream-title stream-small-font">{{ item.title }}</span>
          </div>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';
import { DESTINATION_TYPES } from '@/constants/enums';
import { DESTINATIONS } from '@/constants/modules';
import { SHORT_CODES } from '@/constants/short-codes';

const { ALL, YOUTUBE, TWITCH } = SHORT_CODES;

export default {
  name: 'ChatStreamSelector',
  props: {
    youtubeProviders: {
      type: Array,
    },
    twitchProviders: {
      type: Array,
    },
    selectedShortCode: {
      type: String,
    },
  },
  data() {
    return {
      providerItems: null,
      showMenu: false,
      selectedCode: null,
    };
  },
  computed: {
    ...mapGetters(DESTINATIONS, ['getDestinationByUserProviderId']),
    anyProviderSelected() {
      return this.providerItems.some((provider) => provider.selected);
    },
    selectedProviders() {
      const selectedProviders = this.selectedProviderItems;
      if (!selectedProviders.length) {
        return this.$t('noProviderWasChosen');
      } else if (selectedProviders.length === 1) {
        const selectedProvider = selectedProviders[0];
        if (selectedProvider.providerShortCode === DESTINATION_TYPES.ALL) {
          return this.$t('all');
        } else {
          return this.$t('selectedProviderOnly', { provider: selectedProvider.orderedTitle });
        }
      } else {
        const poppedProvider = selectedProviders.pop();
        return this.$t('selectedMultipleProvider', {
          startText: selectedProviders.map((provider) => provider.orderedTitle).join(','),
          endText: poppedProvider.orderedTitle,
        });
      }
    },
    selectedProviderItems() {
      return this.providerItems.filter((provider) => provider.selected);
    },
    selectedProviderPayload() {
      const selectedProviders = this.selectedProviderItems;
      if (selectedProviders.some((provider) => provider.providerShortCode === DESTINATION_TYPES.ALL)) {
        return this.providerItems
          .filter((provider) => provider.providerShortCode !== DESTINATION_TYPES.ALL)
          .map((provider) => {
            return {
              providerName: provider.providerShortCode,
              userSessionProviderId: provider.userSessionProviderId,
              senderAvatar: this.getDestinationByUserProviderId(provider.userProviderId)?.avatar,
              userProviderId: provider.userProviderId,
            };
          });
      } else {
        return selectedProviders.map((provider) => {
          return {
            providerName: provider.providerShortCode,
            userSessionProviderId: provider.userSessionProviderId,
            senderAvatar: this.getDestinationByUserProviderId(provider.userProviderId)?.avatar,
            userProviderId: provider.userProviderId,
          };
        });
      }
    },
    mappedYoutubeProviders() {
      return [
        ...this.youtubeProviders.map((item, index) => {
          const orderedTitle = this.youtubeProviders.length > 1 ? `${item.providerShortCode} ${index + 1}` : item.providerShortCode;
          const shortKey = this.youtubeProviders.length > 1 ? `${item.providerShortCode.toLowerCase()}${index + 1}` : item.providerShortCode.toLowerCase();
          return {
            ...item,
            orderedTitle,
            shortKey,
            selected: false,
          };
        }),
      ];
    },
    mappedTwitchProviders() {
      return [
        ...this.twitchProviders.map((item, index) => {
          const orderedTitle = this.twitchProviders.length > 1 ? `${item.providerShortCode} ${index + 1}` : item.providerShortCode;
          const shortKey = this.twitchProviders.length > 1 ? `${item.providerShortCode.toLowerCase()}${index + 1}` : item.providerShortCode.toLowerCase();
          return {
            ...item,
            orderedTitle,
            shortKey,
            selected: false,
          };
        }),
      ];
    },
    filteredProvidersLength() {
      return this.mappedYoutubeProviders.length + this.mappedTwitchProviders.length;
    },
    filteredProviderItems() {
      if (!this.selectedCode) {
        return this.providerItems;
      } else {
        if (this.selectedCode === DESTINATION_TYPES.YOUTUBE) {
          return this.mappedYoutubeProviders;
        } else {
          return this.mappedTwitchProviders;
        }
      }
    },
  },
  created() {
    let items = [];
    if (this.filteredProvidersLength > 1) {
      items = [{ title: this.$t('all'), selected: true, providerShortCode: DESTINATION_TYPES.ALL }];
    }
    this.providerItems = [...items, ...this.mappedYoutubeProviders, ...this.mappedTwitchProviders];
    if (this.providerItems.length === 1) {
      this.providerItems[0].selected = true;
    }
  },
  methods: {
    selectItem(item, selectedValue = null) {
      const selected = selectedValue || !item.selected;
      if (item.providerShortCode === DESTINATION_TYPES.ALL) {
        this.providerItems.filter((provider) => provider.providerShortCode !== DESTINATION_TYPES.ALL).map((provider) => (provider.selected = false));
        this.providerItems.find((provider) => provider.providerShortCode === DESTINATION_TYPES.ALL).selected = selected;
      } else {
        this.providerItems.find((provider) => provider.providerShortCode === DESTINATION_TYPES.ALL).selected = false;
        this.setSelectedItem(item.userSessionProviderId, item.selected);
      }
      if (!this.anyProviderSelected) {
        this.$emit('setAnySelectedStream', false);
      } else {
        this.$emit('setAnySelectedStream', true);
        this.$emit('setSelectedProviderPayload', this.selectedProviderPayload);
      }
    },
    setSelectedItem(userSessionProviderId, isSelected) {
      let item = this.providerItems.find((item) => item.userSessionProviderId === userSessionProviderId);
      item.selected = !isSelected;
    },
    setUnSelected(type) {
      this.providerItems.filter((provider) => provider.providerShortCode !== type).map((provider) => (provider.selected = false));
    },
    showMenuWithShortCode(type) {
      this.selectedCode = type;
      this.showMenu = true;
    },
    findByShortCodeAndSetActive(shortKey) {
      this.providerItems.find((provider) => provider.shortKey === shortKey).selected = true;
    },
    useShortCode() {
      switch (this.selectedShortCode) {
        case ALL:
          this.selectItem({ title: this.$t('all'), selected: true, providerShortCode: DESTINATION_TYPES.ALL }, true);
          break;
        case `${YOUTUBE}${ALL}`:
          this.setUnSelected(DESTINATION_TYPES.YOUTUBE);
          this.providerItems.filter((provider) => provider.providerShortCode === DESTINATION_TYPES.YOUTUBE).map((provider) => (provider.selected = true));
          break;
        case `${TWITCH}${ALL}`:
          this.setUnSelected(DESTINATION_TYPES.TWITCH);
          this.providerItems.filter((provider) => provider.providerShortCode === DESTINATION_TYPES.TWITCH).map((provider) => (provider.selected = true));
          break;
        case `${YOUTUBE}`:
          this.setUnSelected(null);
          if (this.youtubeProviders.length > 1) {
            this.showMenuWithShortCode(DESTINATION_TYPES.YOUTUBE);
            this.$emit('setAnySelectedStream', false);
          } else {
            this.youtubeProviders.forEach((provider) => this.setSelectedItem(provider.userSessionProviderId, false));
          }
          break;
        case `${TWITCH}`:
          this.setUnSelected(null);
          if (this.twitchProviders.length > 1) {
            this.showMenuWithShortCode(DESTINATION_TYPES.TWITCH);
            this.$emit('setAnySelectedStream', false);
          } else {
            this.twitchProviders.forEach((provider) => this.setSelectedItem(provider.userSessionProviderId, false));
          }
          break;
        default:
          this.setUnSelected(null);
          this.findByShortCodeAndSetActive(this.selectedShortCode);
          break;
      }
      this.$emit('setShortCode', null);
      this.$emit('setSelectedProviderPayload', this.selectedProviderPayload);
    },
    onMenuHandled(val) {
      if (!val) {
        this.selectedCode = null;
      }
    },
    updateProviderItems() {
      const selectedItems = this.providerItems.filter((item) => item.selected);
      let items = [];
      this.$emit('setTargetEmpty');
      if (this.filteredProvidersLength > 1) {
        items = [{ title: this.$t('all'), providerShortCode: DESTINATION_TYPES.ALL }];
      }
      this.providerItems = [...items, ...this.mappedYoutubeProviders, ...this.mappedTwitchProviders].map((item) => {
        const itemIndex = selectedItems.findIndex((selectedItem) => {
          if (selectedItem?.userProviderDetail?.oauthId) {
            return selectedItem.userProviderDetail?.oauthId === item?.userProviderDetail?.oauthId;
          } else {
            return selectedItem.providerShortCode === DESTINATION_TYPES.ALL && item.providerShortCode === DESTINATION_TYPES.ALL;
          }
        });
        if (itemIndex > -1) {
          item.selected = true;
        }
        return item;
      });

      if (this.providerItems.length === 1) {
        this.providerItems[0].selected = true;
      }
      this.$emit('setAnySelectedStream', true);

      this.$emit('setSelectedProviderPayload', this.selectedProviderPayload);
    },
  },
  watch: {
    selectedShortCode() {
      if (this.selectedShortCode) {
        this.useShortCode();
      }
    },
    mappedYoutubeProviders: {
      deep: true,
      handler() {
        this.updateProviderItems();
      },
    },
    mappedTwitchProviders: {
      deep: true,
      handler() {
        this.updateProviderItems();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.cursor-default {
  cursor: default;
}
.selector-span {
  color: var(--v-white-base);
}
.chat-stream-selector-list {
  color: var(--v-disabled-text);
  &--icon {
    min-width: 24px;
  }

  &.active {
    color: var(--v-white-base);
  }
}
.chat-stream-selector-list-with-short-code {
  &.active {
    background: var(--v-gray-base);
  }
  .stream-image {
    width: 15px;
  }
  .stream-title {
    max-width: 12em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>


<template>
  <div class="settings-wrapper px-4 py-4">
    <div>
      <AppSelect v-model="selectedSettings" hide-details class="mt-1" :items="items" />
      <div class="settings-contents" :class="{ 'has-alert': infoAlert }">
        <General class="content d-flex flex-column" :class="{ active: selectedSettings === GENERAL }" ref="General" key="0" />
        <Camera class="content d-flex flex-column" :infoAlert="infoAlert" :class="{ active: selectedSettings === CAMERA }" ref="Camera" key="1" />
        <Audio class="content d-flex flex-column" :infoAlert="infoAlert" :class="{ active: selectedSettings === AUDIO }" ref="Audio" key="2" />
        <Hotkeys
          class="content d-flex flex-column"
          :class="{ active: selectedSettings === HOTKEYS }"
          ref="Hotkeys"
          key="3"
          :selected="selectedSettings === HOTKEYS"
          :selected-tab="selectedTab === RIGHT_MENU_ITEMS.SETTINGS"
        />
        <Notifications class="content d-flex flex-column" :class="{ active: selectedSettings === NOTIFICATIONS }" ref="Notifications" key="4" />
        <Permissions class="content d-flex flex-column" :class="{ active: selectedSettings === PERMISSIONS }" ref="Permissions" key="5" />
      </div>
      <v-alert v-model="infoAlert" type="info">
        <template v-slot:close>
          <AppIcon class="cursor-pointer" icon="close-2" @click="infoAlert = false" />
        </template>
        {{ $t('rightMenu.settings.mustPressSave') }}
      </v-alert>
      <AppButton class="save-button mt-3" block color="var(--v-info-darken3)" @click="save">{{ $t('rightMenu.settings.save') }}</AppButton>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import General from '@/components/Settings/General';
import Camera from '@/components/Settings/Camera';
import Audio from '@/components/Settings/Audio';
import Hotkeys from '@/components/Settings/Hotkeys';
import Notifications from '@/components/Settings/Notifications';
import Permissions from '@/components/Settings/Permissions';
import { STUDIO_SETTING_TYPES } from '@/constants/settings';
import { TOAST_TYPE } from '@/constants/toast';
import { NOTIFICATION, SETTINGS } from '@/constants/modules';
import { RIGHT_MENU_ITEMS } from '@/constants/enums';

const { GENERAL, CAMERA, AUDIO, HOTKEYS, PERMISSIONS, NOTIFICATIONS } = STUDIO_SETTING_TYPES;

export default {
  name: 'StudioSettings',
  components: { General, Camera, Audio, Hotkeys, Notifications, Permissions },
  props: {
    selectedTab: String,
  },
  data() {
    return {
      RIGHT_MENU_ITEMS,
      GENERAL,
      CAMERA,
      AUDIO,
      HOTKEYS,
      PERMISSIONS,
      NOTIFICATIONS,
      selectedSettings: 0,
      items: [
        {
          text: this.$t('rightMenu.settings.types.general'),
          value: GENERAL,
        },
        {
          text: this.$t('rightMenu.settings.types.camera'),
          value: CAMERA,
        },
        {
          text: this.$t('rightMenu.settings.types.audio'),
          value: AUDIO,
        },
        {
          text: this.$t('rightMenu.settings.types.hotkeys'),
          value: HOTKEYS,
        },
        // {
        //   text: this.$t('rightMenu.settings.types.permissions'),
        //   value: PERMISSIONS,
        // },
        {
          text: this.$t('rightMenu.settings.types.notifications'),
          value: NOTIFICATIONS,
        },
      ],
      infoAlert: true,
    };
  },
  methods: {
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    ...mapActions(SETTINGS, ['fetchSettings']),
    async save() {
      try {
        await this.$refs.General.save();
        await this.$refs.Camera.save();
        await this.$refs.Audio.save();
        await this.$refs.Hotkeys.save();
        await this.$refs.Notifications.save();
        // await this.$refs.Permissions.save();
        await this.fetchSettings();
        this.showToastNotification({ body: 'success.successfullySaved', config: { type: TOAST_TYPE.SUCCESS } });
      } catch (err) {
        this.showToastNotification({ body: this.$t('error.couldNotSet', { name: this.$t('settings') }), config: { type: TOAST_TYPE.ERROR } });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.settings-wrapper {
  .settings-contents {
    position: relative;
    height: calc(100vh - 190px);
    &.has-alert {
      height: calc(100vh - 250px);
    }
    .content {
      transform: translateX(50%);
      transition: all ease 0.5s;
      opacity: 0;
      height: 0;
      &.active {
        opacity: 1;
        transform: translateX(0);
        display: inline-block;
        height: auto;
      }
    }
  }
  ::v-deep .save-button {
    .v-btn__content {
      text-transform: uppercase;
    }
  }
}
</style>

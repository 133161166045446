var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "animated-elements-wrapper" },
    [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [
          _c("div", { staticClass: "info-text" }, [
            _vm._v(_vm._s(_vm.$t("design.animatedElements"))),
          ]),
          _c(
            "v-tooltip",
            {
              attrs: { top: "", "max-width": "250" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "AppIcon",
                        _vm._g(
                          _vm._b(
                            {
                              staticClass: "ml-2",
                              attrs: {
                                icon: "info",
                                size: "14",
                                color: "var(--v-secondary-lighten1)",
                              },
                            },
                            "AppIcon",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("v-divider"),
              _c("div", [
                _vm._v(_vm._s(_vm.$t("design.animatedElementsInfo"))),
              ]),
            ],
            1
          ),
        ],
        1
      ),
      _vm._l(_vm.ANIMATED_ELEMENTS, function (element, key) {
        return _c("div", { key: key }, [
          _c("div", { staticClass: "mt-2 d-flex" }, [
            _c("div", [
              _c(
                "div",
                {
                  staticClass: "animated-element d-flex align-center pa-1",
                  class: {
                    active: element.type === _vm.selectedAnimatedElement,
                    applied: _vm.appliedDesigns.includes(element.type),
                    "hidden-element": _vm.animatedElements[element.type].hidden,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "animated-element--title d-flex justify-center align-center cursor-pointer",
                      class: {
                        bordered:
                          element.type ===
                          _vm.ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE,
                      },
                      on: {
                        click: function ($event) {
                          _vm.selectedAnimatedElement = element.type
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(
                            element.translatedTitle
                              ? this.$t(`design.${element.title}`)
                              : element.title
                          ) +
                          " "
                      ),
                    ]
                  ),
                  _c(
                    "div",
                    { staticClass: "animated-element--subtitle ml-5" },
                    [
                      _vm._v(
                        _vm._s(
                          _vm.$t(
                            `design.${
                              _vm.animatedElements[element.type].hidden
                                ? "hiddenOnStage"
                                : element.type
                            }`
                          )
                        )
                      ),
                    ]
                  ),
                ]
              ),
              _vm.showFields(element, "text")
                ? _c(
                    "div",
                    [
                      _c("v-text-field", {
                        staticClass: "animated-design-text mt-3",
                        attrs: {
                          "background-color": "var(--v-chat-input-color)",
                          height: "67",
                          "hide-details": "",
                          solo: "",
                          flat: "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "label",
                              fn: function () {
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "d-flex justify-start align-center",
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "text-field-slot" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("design.textHere"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          true
                        ),
                        model: {
                          value: _vm.animatedElements[element.type].text,
                          callback: function ($$v) {
                            _vm.$set(
                              _vm.animatedElements[element.type],
                              "text",
                              $$v
                            )
                          },
                          expression: "animatedElements[element.type].text",
                        },
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm.showFields(element, "textSpeed")
                ? _c(
                    "div",
                    { staticClass: "d-flex text-speed-wrapper mt-2" },
                    [
                      _c("div", [
                        _vm._v(" " + _vm._s(_vm.$t("design.textSpeed")) + " "),
                      ]),
                      _vm._l(3, function (item, key) {
                        return _c(
                          "div",
                          {
                            key: key,
                            staticClass: "ml-1 cursor-pointer text-speed",
                            class: {
                              active:
                                item ===
                                _vm.animatedElements[element.type].textSpeed,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.setField(
                                  element.type,
                                  "textSpeed",
                                  item
                                )
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(item) + "x ")]
                        )
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm.showFields(element, "cornerNotch")
                ? _c("div", { staticClass: "mt-4" }, [
                    _c(
                      "div",
                      { staticClass: "d-flex align-center" },
                      [
                        _c("v-switch", {
                          staticClass: "corner-notch-switch mt-0",
                          attrs: {
                            color: "var(--v-secondary-lighten1)",
                            label: _vm.$t("design.cornerNotch"),
                            "hide-details": "",
                          },
                          on: { change: _vm.changeNotch },
                          model: {
                            value:
                              _vm.animatedElements[element.type].cornerNotch,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.animatedElements[element.type],
                                "cornerNotch",
                                $$v
                              )
                            },
                            expression:
                              "animatedElements[element.type].cornerNotch",
                          },
                        }),
                        _c(
                          "v-tooltip",
                          {
                            attrs: { top: "", "max-width": "250" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "activator",
                                  fn: function ({ on, attrs }) {
                                    return [
                                      _c(
                                        "AppIcon",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                icon: "info",
                                                size: "14",
                                                color:
                                                  "var(--v-secondary-lighten1)",
                                              },
                                            },
                                            "AppIcon",
                                            attrs,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                          },
                          [
                            _c("v-divider"),
                            _c("div", [
                              _vm._v(_vm._s(_vm.$t("design.cornerNotchInfo"))),
                            ]),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _vm.animatedElements[element.type].cornerNotch
                      ? _c(
                          "div",
                          [
                            _c("corner-notch-selector", {
                              ref: "cornerNotchSelector",
                              refInFor: true,
                              on: { setNotchError: _vm.setNotchError },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                  ])
                : _vm._e(),
            ]),
            _vm.selectedAnimatedElement === element.type ||
            _vm.appliedDesigns.includes(element.type)
              ? _c("div", { staticClass: "ml-2" }, [
                  _c(
                    "div",
                    {
                      staticClass:
                        "remove-icon-wrapper d-flex justify-center align-center cursor-pointer",
                      on: {
                        click: function ($event) {
                          return _vm.removeSelected(element)
                        },
                      },
                    },
                    [
                      _c("AppIcon", {
                        attrs: {
                          icon: "trash-2",
                          width: "14",
                          height: "14",
                          color: "var(--v-white-base)",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticClass:
                        "remove-icon-wrapper d-flex justify-center align-center cursor-pointer mt-2",
                      class: {
                        "hidden-icon":
                          _vm.animatedElements[element.type].hidden,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.setField(
                            element.type,
                            "hidden",
                            !_vm.animatedElements[element.type].hidden
                          )
                        },
                      },
                    },
                    [
                      _c("AppIcon", {
                        attrs: {
                          icon: _vm.animatedElements[element.type].hidden
                            ? "eye-on"
                            : "eye-off",
                          width: "14",
                          height: "14",
                          color: _vm.animatedElements[element.type].hidden
                            ? "var(--v-border-orange)"
                            : "var(--v-white-base)",
                        },
                      }),
                    ],
                    1
                  ),
                ])
              : _vm._e(),
          ]),
          _vm.selectedAnimatedElement === element.type
            ? _c(
                "div",
                { staticClass: "mt-3 d-flex justify-end" },
                [
                  _c(
                    "AppButton",
                    {
                      attrs: {
                        disabled: _vm.disableApplyButton,
                        color: "var(--v-secondary-lighten1)",
                        small: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.applyDesign(element.type)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("apply")))]
                  ),
                ],
                1
              )
            : _vm._e(),
        ])
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
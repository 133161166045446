<template>
  <div class="layout-menu d-flex">
    <v-tooltip bottom v-for="item in menuItems" :key="item.title">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <AppButton
            :color="getLayoutName === item.name ? 'theme-primary' : 'theme-dark-btn-color'"
            class="mr-2"
            :outlined="getLayoutName === item.name"
            :disabled="!$can('changeLayout', 'Studio')"
            @click="onChange(item)"
          >
            <AppIcon :icon="item.icon" :size="item.iconSize" />
          </AppButton>
        </div>
      </template>
      <span>{{ $t(`layout.${item.name}`) }}</span>
    </v-tooltip>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { LAYOUT_TYPES } from '@/constants/layout';

export default {
  name: 'LayoutMenu',
  data() {
    return {
      menuItems: LAYOUT_TYPES,
    };
  },
  computed: {
    ...mapGetters('Studio', ['getLayoutName']),
  },
  mounted() {
    this.initLayout();
  },
  methods: {
    ...mapActions('Studio', ['changeLayout', 'setInitialRoomConfig']),
    initLayout() {
      if (this.getLayoutName) {
        return;
      }

      if (!this.$can('changeLayout', 'Studio')) {
        setTimeout(() => {
          this.initLayout();
        }, 1000);
      } else {
        this.setInitialRoomConfig();
      }
    },
    onChange(layout) {
      this.changeLayout({ layout });
    },
  },
};
</script>

<style lang="scss">
.layout-menu {
  button {
    border-width: 2px;
    height: 42px !important;
    padding: 0 !important;
    &.v-btn--disabled {
      border-color: var(--v-secondary-base) !important;
    }
  }
}
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "message-wrapper d-flex align-start my-2 px-2 py-2",
      class: {
        "stream-pending": _vm.isPending,
        "message-wrapper--hover":
          _vm.$can("showComment", "Studio") && !_vm.message.unclickable,
        "stream-message-wrapper ": _vm.message.type === "stream",
        "on-queue": _vm.isOnQueue,
        current: _vm.isCurrentMessage,
      },
      on: { click: _vm.showLiveComment },
    },
    [
      _c("DestinationAvatar", {
        staticClass: "message-avatar",
        attrs: {
          avatar: _vm.messageAvatar,
          "destination-types": _vm._f("getUniques")(
            _vm.message.selectedSessionPayload || _vm.message.streamType
          ),
          "hide-icon": _vm.isRoomMessage,
        },
      }),
      _c("div", { staticClass: "d-inline-flex flex-column ml-3" }, [
        _c(
          "div",
          {
            staticClass: "mb-1",
            class: { "my-message": !_vm.message.incoming },
          },
          [
            _c("span", { staticClass: "sender" }, [
              _vm._v(_vm._s(_vm.getSender)),
            ]),
            _c("span", { staticClass: "time ml-2" }, [
              _vm._v(_vm._s(_vm.message.time)),
            ]),
          ]
        ),
        _c("div", { staticClass: "message" }, [
          _vm.message.statusType === "error"
            ? _c(
                "div",
                {
                  staticClass: "d-flex align-center mr-2 send-again-wrapper",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.$emit("sendAgain", _vm.message)
                    },
                  },
                },
                [
                  _c("AppIcon", {
                    attrs: {
                      icon: "alert-circle-1",
                      size: "16",
                      color: "error",
                    },
                  }),
                  _c("span", { staticClass: "send-again-text" }, [
                    _vm._v(_vm._s(_vm.$t("sendAgain"))),
                  ]),
                ],
                1
              )
            : _vm.isPending
            ? _c(
                "div",
                { staticClass: "mr-2" },
                [
                  _c("v-progress-circular", {
                    attrs: { size: "14", width: "1", indeterminate: "" },
                  }),
                ],
                1
              )
            : _vm._e(),
          _c("div", {
            domProps: { innerHTML: _vm._f("urlify")(_vm.message.body) },
          }),
        ]),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
import xssService from '@/services/xss.service';
import { consoleLog } from 'xpermeet-lib';

export default class streamMessageQueue {
  constructor(callback) {
    this.inProgress = false;
    this.messages = [];
    this.callback = callback;
  }

  getAll() {
    return this.messages;
  }

  enqueue(message) {
    this.messages.push(message);
    if (!this.isEmpty() && !this.inProgress) {
      this.sendMessage();
    }
  }

  async sendMessage() {
    consoleLog('Send Message Started');
    const message = this.dequeue();
    if (!message) {
      this.inProgress = false;
      consoleLog('Message Not Found');
      return;
    }
    let reSendMessage = Boolean(message?.statusType) || false;
    const id = message.uuid;
    try {
      this.inProgress = true;
      consoleLog('Message Pending');
      this.callback({ id, status: 'pending', userSessionProviderId: message.targetProviders[0].userSessionProviderId, reSendMessage, message });
      await xssService.sendStreamMessage(message);
      this.callback({
        id,
        status: 'success',
        message: message.messageText,
        userSessionProviderId: message.targetProviders[0].userSessionProviderId,
        reSendMessage,
      });
      consoleLog('Message Sent');
      if (!this.isEmpty()) {
        this.sendMessage();
      } else {
        this.inProgress = false;
        consoleLog('All Messages Sent');
      }
    } catch (error) {
      consoleLog('Messages Couldnt Send');
      this.callback({ id, status: 'error', userSessionProviderId: message.targetProviders[0].userSessionProviderId, reSendMessage });
      if (!this.isEmpty()) {
        this.sendMessage();
      } else {
        this.inProgress = false;
      }
    }
  }
  dequeue() {
    if (this.isEmpty()) {
      return 'underflow';
    }
    return this.messages.shift();
  }

  findMessageByUuid(uuid) {
    return this.messages.find((message) => message.uuid === uuid);
  }

  isEmpty() {
    // return true if the queue is empty.
    return this.messages.length === 0;
  }
}

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "backstage-video-card" },
    [
      !_vm.isScreen
        ? _c(
            "div",
            {
              staticClass:
                "backstage-video-card--menu d-flex justify-space-between px-1 py-2",
            },
            [
              _vm.$can("addRemoveStream", "Studio")
                ? _c(
                    "AppButton",
                    {
                      staticClass: "backstage-video-card--menu--layout-button",
                      attrs: { outlined: "", color: _vm.selectSoloBtnColor },
                      on: {
                        click: function ($event) {
                          return _vm.selectSoloUser({
                            participantId: _vm.user.id,
                          })
                        },
                      },
                    },
                    [_c("AppIcon", { attrs: { icon: "user-full-color" } })],
                    1
                  )
                : _c("div"),
              _c(
                "div",
                {
                  staticClass:
                    "backstage-video-card--menu--btn-group d-flex align-center justify-center",
                },
                [
                  [
                    _c(
                      "div",
                      {
                        staticClass: "d-flex px-2",
                        class: {
                          "cursor-pointer":
                            _vm.$can("muteUser", "Studio") && !_vm.myVideoCard,
                        },
                        on: { click: _vm.toggleSetRemoteAudioMute },
                      },
                      [
                        _c("AppIcon", {
                          attrs: {
                            color: _vm.micIconColor,
                            icon: _vm.micIcon,
                            size: "12",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm.moreActionList.length
                      ? _c("div", {
                          staticClass:
                            "backstage-video-card--menu--btn-group--spacer",
                        })
                      : _vm._e(),
                  ],
                  _vm.moreActionList.length
                    ? _c(
                        "v-menu",
                        {
                          attrs: { "offset-y": "" },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "activator",
                                fn: function ({ on, attrs }) {
                                  return [
                                    _c(
                                      "div",
                                      _vm._g(
                                        _vm._b(
                                          { staticClass: "d-flex px-2" },
                                          "div",
                                          attrs,
                                          false
                                        ),
                                        on
                                      ),
                                      [
                                        _c("AppIcon", {
                                          attrs: {
                                            color: "black",
                                            icon: "more-vertical",
                                            size: "14",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            26347279
                          ),
                        },
                        [
                          _c(
                            "v-list-item-group",
                            _vm._l(
                              _vm.moreActionList,
                              function (action, index) {
                                return _c(
                                  "v-list-item",
                                  {
                                    key: index,
                                    attrs: { dense: "" },
                                    on: { click: action.onClick },
                                  },
                                  [
                                    _c("v-list-item-title", [
                                      _vm._v(" " + _vm._s(action.label) + " "),
                                    ]),
                                  ],
                                  1
                                )
                              }
                            ),
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          )
        : _vm._e(),
      _c("VideoCard", {
        attrs: {
          id: `${_vm.user.type}-video-${_vm.user.id}`,
          "class-name": `${_vm.user.type}`,
          closed:
            !_vm.user.hasVideoTrack ||
            _vm.user.interrupted ||
            _vm.user.videoMuted,
          local: _vm.myVideoCard,
          user: _vm.user,
        },
      }),
      _vm.$can("addRemoveStream", "Studio")
        ? _c(
            "div",
            {
              staticClass:
                "backstage-video-card--toggle-btn justify-center px-4 w-full",
            },
            [
              _c(
                "AppButton",
                {
                  class: { staged: _vm.onStage },
                  attrs: { block: "", small: "" },
                  on: { click: _vm.toggleStage },
                },
                [_vm._v(_vm._s(_vm.$t(_vm.toggleText)))]
              ),
            ],
            1
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "backstage-video-card--name d-flex align-center" },
        [
          _vm.user.interrupted
            ? _c("AppIcon", {
                attrs: { icon: "alert-circle-1", color: "red", size: "14" },
              })
            : _c("AppIcon", {
                attrs: {
                  icon: _vm.getUserIcon,
                  size: "14",
                  color: _vm.userIconColor,
                },
              }),
          _c("span", { staticClass: "ml-1 ellipsis" }, [
            _vm._v(_vm._s(_vm.user.displayName)),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
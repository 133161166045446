var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "studio-messages-wrapper" },
    [
      _c(
        "div",
        { staticClass: "d-flex studio-messages-wrapper--tabs" },
        [
          _c(
            "v-col",
            [
              _c(
                "v-badge",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getUnreadStreamMessageText,
                      expression: "getUnreadStreamMessageText",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    "offset-x": "40",
                    left: "",
                    color: "secondary",
                    overlap: "",
                    content: _vm.getUnreadStreamMessageCount,
                    value: _vm.getUnreadStreamMessageCount,
                  },
                },
                [
                  _c(
                    "app-button",
                    {
                      attrs: {
                        block: "",
                        large: "",
                        color: _vm.streamMsgBtnColor,
                        ripple: false,
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.tab = 0
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("rightMenu.messageTypes.stream")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-col",
            [
              _c(
                "v-badge",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: _vm.getUnreadMessageText,
                      expression: "getUnreadMessageText",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    "offset-x": "40",
                    left: "",
                    color: "secondary",
                    overlap: "",
                    content: _vm.getUnreadMessageCount,
                    value: _vm.getUnreadMessageCount,
                  },
                },
                [
                  _c(
                    "app-button",
                    {
                      attrs: {
                        block: "",
                        large: "",
                        color: _vm.studioMsgBtnColor,
                        ripple: false,
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          _vm.tab = 1
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.$t("rightMenu.messageTypes.inRoom")) +
                          " "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-tabs-items",
        {
          staticClass: "message-containers",
          model: {
            value: _vm.tab,
            callback: function ($$v) {
              _vm.tab = $$v
            },
            expression: "tab",
          },
        },
        [
          _c(
            "v-tab-item",
            [_c("StudioChat", { attrs: { type: "stream" } })],
            1
          ),
          _c(
            "v-tab-item",
            [_c("StudioChat", { attrs: { type: "inRoom" } })],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
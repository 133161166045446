<template>
  <div class="banners">
    <template v-if="!selectedFolder">
      <div class="banners--header d-flex justify-space-between align-center">
        <span>{{ $t('folders') }}</span>
        <AppIcon icon="folder-plus-outline" class="cursor-pointer" @click="showAddFolderModal()" />
      </div>
      <div class="banners--folders">
        <div
          class="banners--folders--folder d-flex justify-space-between align-center cursor-pointer"
          v-for="item in banners"
          :key="item.id"
          @click="selectedFolder = item"
        >
          <div class="d-flex align-center">
            <AppIcon icon="folder" class="mx-5" />
            <div>
              <div>{{ item.name }}</div>
              <div>{{ item.banners.length }} {{ $t(item.banners.length > 1 ? 'banners' : 'banner') }}</div>
            </div>
          </div>
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="more-actions" v-bind="attrs" v-on="on">
                <AppIcon class="ml-1" size="16" icon="more-vertical" />
              </v-btn>
            </template>
            <v-list class="app-action-list py-0" v-for="(action, index) in folderActions" :key="index">
              <v-list-item @click="action.onClick(item)">
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <span class="list-item-icon mr-2">
                      <AppIcon :icon="action.icon" width="20" height="20" />
                    </span>
                    <span class="list-item-text" :style="{ color: action.color ? action.color : '#ffffff' }">
                      {{ $t(action.name) }}
                    </span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="banners--header d-flex justify-space-between align-center">
        <div class="d-flex align-center">
          <AppIcon icon="chevron-left" class="cursor-pointer mr-2" @click="selectedFolder = null" />
          <span>{{ selectedFolder.name }}</span>
        </div>
        <div class="d-flex align-center">
          <AppIcon icon="plus" class="cursor-pointer" @click="showAddBannerModal()" />
          <v-menu offset-y>
            <template v-slot:activator="{ on, attrs }">
              <v-btn icon class="more-actions" v-bind="attrs" v-on="on">
                <AppIcon class="ml-1" size="16" icon="more-vertical" />
              </v-btn>
            </template>
            <v-list class="app-action-list py-0" v-for="(action, index) in folderActions" :key="index">
              <v-list-item @click="action.onClick(selectedFolder)">
                <v-list-item-content>
                  <div class="d-flex align-center">
                    <span class="list-item-icon mr-2">
                      <AppIcon :icon="action.icon" width="20" height="20" />
                    </span>
                    <span class="list-item-text" :style="{ color: action.color ? action.color : '#ffffff' }">
                      {{ $t(action.name) }}
                    </span>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>
      <div class="banners--list">
        <div v-for="banner in selectedFolder.banners" :key="banner.id" class="banners--list--banner-item">
          <div class="banners--list--banner-item--border" :class="{ 'on-queue': isOnQueue(banner.id), current: currentBannerId === banner.id }"></div>
          <span>{{ banner.text }}</span>
          <div
            v-if="isOnQueue(banner.id) || (currentBannerId === banner.id && getBannerSettings.parmanent)"
            class="banners--list--banner-item--menu d-flex justify-center align-center banners--list--banner-item--menu--current"
          >
            <AppButton @click="toggleBanner(banner)" text large plain :ripple="false">
              <AppIcon icon="minus-circle" class="mr-2 cursor-pointer" width="20" height="20" />
              {{ getBannerSettings.parmanent ? $t('hide') : $t('removeFromQueue') }}
            </AppButton>
          </div>
          <div
            v-else
            class="banners--list--banner-item--menu d-flex justify-center align-center"
            :class="{ 'cursor-pointer': currentBannerId !== banner.id }"
            @click="toggleBanner(banner)"
          >
            <div text large plain class="d-flex align-center">
              <AppIcon v-if="currentBannerId !== banner.id" icon="plus" class="mr-2 cursor-pointer" width="20" height="20" />
              {{ currentBannerId === banner.id ? $t('current') : $t('show') }}
            </div>
            <div class="d-flex banners--list--banner-item--menu--items" @click.stop>
              <AppIcon icon="pen" class="mr-2 cursor-pointer" width="20" height="20" @click="showAddBannerModal(banner)" />
              <AppIcon icon="trash" class="cursor-pointer" width="20" height="20" @click="deleteBanner(banner)" />
            </div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { consoleError, consoleLog } from 'xpermeet-lib';
import { mapActions, mapState, mapGetters } from 'vuex';
import { v4 as uuidv4 } from 'uuid';

import AddEditBannerFolderModal from '@/components/Modals/AddEditBannerFolderModal';
import AddEditBannerModal from '@/components/Modals/AddEditBannerModal';
import { SETTINGS, STUDIO, NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';
import bannerQueue from '@/helpers/banner';

export default {
  name: 'StudioBanners',
  data() {
    return {
      selectedFolder: null,
      folderActions: [
        { name: 'rename', onClick: this.showAddFolderModal, icon: 'pen' },
        { name: 'duplicate', onClick: this.duplicateFolder, icon: 'copy' },
        { name: 'delete', onClick: this.deleteFolder, icon: 'trash' },
      ],
    };
  },
  computed: {
    ...mapState(SETTINGS, ['banners']),
    ...mapState(STUDIO, ['currentBanners', 'currentBannerId']),
    ...mapGetters(SETTINGS, ['getBannerSettings']),

    isOnQueue() {
      return (id) => {
        return !!this.currentBanners.find((b) => b.id === id);
      };
    },
  },
  methods: {
    ...mapActions(STUDIO, ['sendBannerToBroadcast', 'removeBannerFromBroadcast']),
    ...mapActions(SETTINGS, ['fetchSettings', 'setSettings']),
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    onBannerChange() {
      this.currentBanners = bannerQueue.getQuteItems();
      this.currentBannerId = bannerQueue.currentItemId;
    },
    showAddBannerModal(banner) {
      const modal = this.$showModal(
        AddEditBannerModal,
        { folderId: this.selectedFolder.id, folders: this.banners, banner },
        {
          completed: () => {
            this.fetchSettings();
            this.selectedFolder = this.banners.find((b) => b.id === this.selectedFolder.id);
            modal.close();
          },
          close: () => {
            modal.close();
          },
        },
      );
    },
    deleteBanner(banner) {
      consoleLog('Delete ', banner);

      const confirmModal = this.$showConfirmModal(
        {
          title: this.$t('deleteFolder'),
          text: this.$t('areYouSureToDelete', { itemName: this.$t('banner') }),
        },
        {
          confirm: async () => {
            this.loading = true;
            const banners = this.banners.map((folder) => {
              if (folder.id === this.selectedFolder.id) {
                folder.banners = folder.banners.filter((b) => b.id !== banner.id);
              }

              return folder;
            });

            const isSuccess = await this.setSettings({
              shortCode: 'banners',
              state: JSON.stringify(banners),
            });

            if (isSuccess) {
              await this.fetchSettings();
              this.selectedFolder = this.banners.find((b) => b.id === this.selectedFolder.id);
              this.showToastNotification({ body: this.$t('success.successfullyDeleted'), config: { type: TOAST_TYPE.SUCCESS } });
            } else {
              this.showToastNotification({ body: this.$t('errors.couldNotDelete', { name: this.$t('folder') }), config: { type: TOAST_TYPE.ERROR } });
            }

            this.loading = true;
            confirmModal.close();
          },
          cancel: () => {
            confirmModal.close();
          },
        },
      );
    },
    deleteFolder(folder) {
      consoleLog('Delete ', folder);

      const confirmModal = this.$showConfirmModal(
        {
          title: this.$t('deleteFolder'),
          text: this.$t('areYouSureToDelete', { itemName: folder.name }),
        },
        {
          confirm: async () => {
            this.loading = true;
            const banners = this.banners.filter((b) => b.id !== folder.id);

            const isSuccess = await this.setSettings({
              shortCode: 'banners',
              state: JSON.stringify(banners),
            });

            if (isSuccess) {
              await this.fetchSettings();
              this.selectedFolder = null;
              this.showToastNotification({ body: this.$t('success.successfullyDeleted'), config: { type: TOAST_TYPE.SUCCESS } });
            } else {
              this.showToastNotification({ body: this.$t('errors.couldNotDelete', { name: this.$t('folder') }), config: { type: TOAST_TYPE.ERROR } });
            }

            this.loading = true;
            confirmModal.close();
          },
          cancel: () => {
            confirmModal.close();
          },
        },
      );
    },
    async duplicateFolder(folder) {
      try {
        this.loading = true;
        consoleLog('Duplicate ', folder);
        const isSuccess = await this.setSettings({
          shortCode: 'banners',
          state: JSON.stringify([
            {
              ...folder,
              id: uuidv4(),
            },
            ...this.banners,
          ]),
        });

        if (isSuccess) {
          await this.fetchSettings();
          this.showToastNotification({ body: this.$t('success.successfullyAdded'), config: { type: TOAST_TYPE.SUCCESS } });
          this.loading = true;
        } else {
          this.showToastNotification({ body: this.$t('errors.couldNotSet', { name: this.$t('folder') }), config: { type: TOAST_TYPE.ERROR } });
        }
      } catch (err) {
        consoleError('Duplicate folder error: ', err);
      }
    },
    showAddFolderModal(folder) {
      const modal = this.$showModal(
        AddEditBannerFolderModal,
        { folder, folders: this.banners },
        {
          completed: () => {
            this.fetchSettings();
            modal.close();
          },
          close: () => {
            modal.close();
          },
        },
      );
    },
    toggleBanner(payload) {
      const banner = {
        ...payload,
        duration: this.getBannerSettings.parmanent ? null : payload.duration || 4,
      };

      if (bannerQueue.itemIsExists(banner.id)) {
        this.removeBannerFromBroadcast(banner);
      } else {
        this.sendBannerToBroadcast(banner);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.banners {
  &--header {
    height: 50px;
    padding: 0 10px;
    border-bottom: 1px solid var(--v-light-gray-2-base);
    font-weight: 300;
    font-size: 20px;
  }

  &--folders {
    height: calc(100vh - 120px);
    overflow-y: auto;

    &--folder {
      height: 70px;
      border-bottom: 1px solid var(--v-light-gray-2-base);
    }
  }

  &--list {
    height: calc(100vh - 130px);
    overflow-y: auto;

    &--banner-item {
      // background-color: #33364a;
      border: 1px solid var(--v-light-gray-2-base);
      padding: 19px 34px;
      border-radius: 8px;
      margin: 15px 15px 15px;
      position: relative;
      &--border {
        transition: all 0.4s;
        border-radius: 8px 0 0 8px;
        width: 0;
        opacity: 0;
        height: 100%;
        position: absolute;
        left: 0px;
        top: 0;
        &.on-queue {
          opacity: 1;
          width: 10px;
          background-color: var(--v-light-gray-base);
        }

        &.current {
          opacity: 1;
          width: 10px;
          background-color: var(--v-theme-primary-base);
        }
      }

      &--menu {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
        inset: 0;
        border-radius: 8px;
        z-index: 9999;

        &--items {
          position: absolute;
          right: 14px;
        }
      }

      &:hover {
        .banners--list--banner-item--menu {
          opacity: 0.9;
          background-color: var(--v-light-gray-base);
          color: black;

          .app-btn {
            color: black;
          }

          &--current {
            background-color: var(--v-secondary-base);
          }
        }
      }
    }
  }
}
</style>

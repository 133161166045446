var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "background-selector-wrapper pl-1" }, [
    _c("div", { staticClass: "d-flex flex-column" }, [
      _c(
        "div",
        { staticClass: "d-flex align-center" },
        [_c("ColorPicker", { attrs: { type: "background" } })],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex flex-column" },
        [
          _vm._l(_vm.design.backgroundList, function (background, key) {
            return _c("Background", {
              key: key,
              staticClass: "mt-3",
              attrs: {
                background: background,
                selected: background.id === _vm.activeBackgroundId,
              },
              on: {
                changeBackgroundSelected: _vm.changeBackgroundSelected,
                deleteLogoFromList: _vm.deleteBackgroundFromList,
              },
            })
          }),
          _c(
            "div",
            {
              staticClass:
                "add-logo-selector mt-3 d-flex align-center justify-center cursor-pointer",
              on: { click: _vm.toggleBackgroundUploadModal },
            },
            [
              _c("AppIcon", {
                attrs: {
                  size: "36",
                  icon: "plus",
                  color: "var(--v-dark-gray-2-base)",
                },
              }),
            ],
            1
          ),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "device-selector speaker-device-selector" },
    _vm._l(_vm.getSpeakers, function (item) {
      return _c(
        "div",
        {
          key: item.deviceId,
          staticClass:
            "speaker-device-selector--item pr-2 py-2 d-flex align-center",
          class: { selected: _vm.selectedSpeakerId === item.deviceId },
          attrs: { role: "button" },
          on: {
            click: function ($event) {
              _vm.selectedSpeakerId = item.deviceId
            },
          },
        },
        [
          _c("span", { staticClass: "speaker-text ellipsis ml-2" }, [
            _vm._v(_vm._s(item.label)),
          ]),
          _c("v-spacer"),
          _c("audio", {
            attrs: {
              src: "/sounds/speakerTest.mp3",
              id: `audio-test-${item.deviceId}`,
            },
          }),
          _c(
            "v-btn",
            {
              attrs: { small: "", text: "" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.speakerTest(item.deviceId)
                },
              },
            },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.playingDeviceId === item.deviceId
                      ? _vm.$t("stop")
                      : _vm.$t("test")
                  ) +
                  " "
              ),
            ]
          ),
        ],
        1
      )
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "text-center" },
    [
      _c(
        "div",
        {
          staticClass:
            "design-box d-flex justify-center align-center cursor-pointer",
          class: { active: _vm.active },
        },
        [_vm._t("default")],
        2
      ),
      _vm.selected
        ? _c("AppIcon", { attrs: { icon: "chevron-up", color: "success" } })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
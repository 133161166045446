var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "layout-menu d-flex" },
    _vm._l(_vm.menuItems, function (item) {
      return _c(
        "v-tooltip",
        {
          key: item.title,
          attrs: { bottom: "" },
          scopedSlots: _vm._u(
            [
              {
                key: "activator",
                fn: function ({ on, attrs }) {
                  return [
                    _c(
                      "div",
                      _vm._g(_vm._b({}, "div", attrs, false), on),
                      [
                        _c(
                          "AppButton",
                          {
                            staticClass: "mr-2",
                            attrs: {
                              color:
                                _vm.getLayoutName === item.name
                                  ? "theme-primary"
                                  : "theme-dark-btn-color",
                              outlined: _vm.getLayoutName === item.name,
                              disabled: !_vm.$can("changeLayout", "Studio"),
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onChange(item)
                              },
                            },
                          },
                          [
                            _c("AppIcon", {
                              attrs: { icon: item.icon, size: item.iconSize },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]
                },
              },
            ],
            null,
            true
          ),
        },
        [_c("span", [_vm._v(_vm._s(_vm.$t(`layout.${item.name}`)))])]
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-app>
    <div class="d-flex flex-column justify-center align-center loading-screen" v-if="loading">
      <AppLogo width="7%" class="pulse" />
    </div>
    <template v-else>
      <KeydownHandler v-on:keyup="onHotkeyPressed" />
      <v-app-bar flat color="bg-gray" class="mb-4 studio--header" app clipped-right>
        <AppLogo width="7%" />
        <v-tooltip bottom max-width="500">
          <template v-slot:activator="{ on, attrs }">
            <span class="studio--header--title ml-2 ellipsis" v-bind="attrs" v-on="on">{{ getCurrentBroadcastTitle }}</span>
          </template>
          <span>{{ getCurrentBroadcastTitle }}</span>
        </v-tooltip>
        <v-spacer></v-spacer>
        <ExpiredTokenWarning v-if="hasExpiredToken" class="mr-2" />
        <div v-if="showAddDestinationButton">
          <AddDestination v-if="!destinations.length" bright trigger-element-type="button" />
          <app-button v-else color="night-rider" class="py-2" @click="showAddEditCurrentSessionModal">
            {{ $t('broadcasts.addDestinationToCurrentSession') }}
          </app-button>
        </div>
        <div v-if="isStreamStarted || isStreamEnded" class="mr-3 broadcast-status d-flex align-center">
          <div class="broadcast-status--icon mr-3" :class="{ started: isStreamStarted, 'ml-3': showAddDestinationButton }"></div>
          {{ statusText }}
        </div>
        <span class="studio--header--mode mr-6" :class="{ 'ml-4': showAddDestinationButton }">
          {{ modeText }}
        </span>
        <div class="d-flex">
          <DestinationMenu v-for="(destination, key) in providers" :key="key" :destination="destination" />
        </div>
        <v-menu
          v-model="streamMenu"
          :close-on-content-click="false"
          offset-y
          transition="scale-transition"
          :disabled="disableStreamMenu"
          v-if="$can('record', 'Studio')"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              v-if="showGoLiveButton"
              large
              :data-test-id="`studio-${btnText}`"
              class="mr-2 start-broadcast-btn"
              v-on="on"
              v-bind="attrs"
              :color="goLiveBtnColor"
              :loading="broadcastInProgress || recordIsPending"
              :disabled="goLiveIsDisabled"
              :class="{ 'stream-menu-btn': !disableStreamMenu }"
              @click="handleStart"
            >
              {{ $t(btnText) }}
            </v-btn>
          </template>
          <v-card class="start-stream-card">
            <v-list class="pt-2">
              <v-list-item>
                <v-list-item-content>
                  <v-list-item-title class="d-flex align-center justify-center">
                    <span class="start-stream-card--text">{{ $t('readyToGoLive') }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>

            <v-divider></v-divider>

            <v-list class="pb-4">
              <v-list-item>
                <v-list-item-action>
                  <v-switch v-model="recordStream" color="secondary"></v-switch>
                </v-list-item-action>
                <v-list-item-title>
                  <span class="start-stream-card--text">{{ $t('recordStream') }}</span>
                </v-list-item-title>
              </v-list-item>
            </v-list>

            <v-card-actions class="d-flex justify-space-around px-4">
              <AppButton :data-test-id="cancelBtn" text @click="streamMenu = false">{{ $t('cancel') }}</AppButton>
              <AppButton :data-test-id="`studio-${btnText}`" :color="goLiveBtnColor" @click="handleGoLiveClick">{{ $t(btnText) }}</AppButton>
            </v-card-actions>
          </v-card>
        </v-menu>
        <div v-if="!isUserLogin">
          <AppButton color="theme-btn-color" @click="login">{{ $t('login') }}</AppButton>
        </div>
      </v-app-bar>
      <StudioMenu :is-owner="isOwner" />
      <v-main class="px-0 py-0">
        <div class="studio" id="studio" ref="studio">
          <LayoutMenu class="pr-4 pl-4 mb-1" />
          <div class="studio--broadcast-wrapper" id="broadcast-wrapper">
            <Broadcast />
          </div>
          <AvatarCropperModal v-show="showAvatarCropper" ref="AvatarCropperModal" @handleAvatarModal="handleAvatarModal" />

          <div class="studio--bottom-menu my-2">
            <div class="studio--bottom-menu-backstage pb-5 mx-4">
              <div class="studio--bottom-menu-backstage--title">{{ $t('studio.backstage') }} ({{ userCount }})</div>
              <div class="d-flex">
                <BackstageVideoCard
                  v-for="user in getUsers"
                  :key="user.id"
                  class="studio--bottom-menu-backstage--video mr-4"
                  :user="user"
                  @handleAvatarModal="handleAvatarModal"
                  @clearAvatar="clearAvatar"
                />
              </div>
            </div>
            <StudioBottomMenu />
          </div>
          <UserAudioContainer />
        </div>
        <IAmTheHostModal v-if="waitingForTheHost" />
        <LogoUploadModal />
        <BackgroundUploadModal />
        <OverlayUploadModal />
      </v-main>
    </template>
    <div v-show="false">
      <MediaDevicesInitiator ref="mediaDeviceInitiator" @initiated="mediaDeviceInitiated" />
    </div>
  </v-app>
</template>

<script>
import { consoleError, consoleLog } from 'xpermeet-lib';
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex';

import MediaDevicesInitiator from '@/components/MediaDevicesInitiator';
import StudioMenu from '@/components/StudioMenu/Menu';
import StudioBottomMenu from '@/components/StudioBottomMenu';
import LayoutMenu from '@/components/LayoutMenu';
import BackstageVideoCard from '@/components/BackstageVideoCard';
import UserAudioContainer from '@/components/UserAudioContainer';
import IAmTheHostModal from '@/components/Modals/IAmTheHostModal';
import LogoUploadModal from '@/components/Modals/LogoUploadModal';
import BackgroundUploadModal from '@/components/Modals/BackgroundUploadModal';
import OverlayUploadModal from '@/components/Modals/OverlayUploadModal';
import Broadcast from '@/components/Broadcast/Broadcast';
import KeydownHandler from '@/components/KeydownHandler';
import DestinationMenu from '@/components/DestinationMenu';
import AddDestination from '@/components/AddDestination';
import { clearStudioName } from '@/helpers/studio';
import storage from '@/lib/storage';
import { isAuthenticated } from '@/helpers/keycloak';
import { STUDIO, SETTINGS, NOTIFICATION, DESTINATIONS, USER } from '@/constants/modules';
import { HOTKEYS } from '@/constants/settings';
import { TOAST_TYPE } from '@/constants/toast';
import { WEBINAR_CONNECTION_STATE, BROADCAST_FILTERS } from '@/constants/enums';
import { RECORDING_STATUS } from '@/constants/record';
import AddEditBroadcastModal from '@/components/Modals/AddEditBroadcastModal';
import AvatarCropperModal from '@/components/Modals/AvatarCropperModal';
import ExpiredTokenWarning from '@/components/ExpiredTokenWarning';
import { SET_DESIGN } from '@/constants/mutation-types';

const { CAMERA_ON_OFF, SPEAKER_ON_OFF, MICROPHONE_ON_OFF } = HOTKEYS;

export default {
  name: 'Studio',
  components: {
    MediaDevicesInitiator,
    StudioMenu,
    StudioBottomMenu,
    BackstageVideoCard,
    UserAudioContainer,
    IAmTheHostModal,
    LayoutMenu,
    Broadcast,
    KeydownHandler,
    DestinationMenu,
    AddDestination,
    AvatarCropperModal,
    ExpiredTokenWarning,
    LogoUploadModal,
    BackgroundUploadModal,
    OverlayUploadModal,
  },
  beforeRouteEnter(to, from, next) {
    const skipPrejoin =
      from.name === 'Prejoin' || storage.getItem('skipPreJoinRoom') === to.params.sessionId || to.params.sessionId.query?.skipPreJoin === 'true';

    if (skipPrejoin) {
      next();
    } else {
      next({ name: 'Prejoin', params: { sessionId: clearStudioName(to.params.sessionId) }, query: { ...to.query } });
    }
  },
  data() {
    return {
      CAMERA_ON_OFF,
      SPEAKER_ON_OFF,
      MICROPHONE_ON_OFF,
      errorCode: null,
      broadcastInProgress: false,
      recordEndStatus: null,
      streamMenu: false,
      recordStream: false,
      isOwner: false,
      streamHasError: false,
      showAvatarCropper: false,
    };
  },
  computed: {
    ...mapGetters(STUDIO, ['getUsers', 'getLocalUser', 'isWebinarStarted', 'isWebinarEnded', 'isWebinarRequestPending', 'getCurrentBroadcastTitle']),
    ...mapGetters(SETTINGS, ['findSettingByKeys']),
    ...mapState(STUDIO, [
      'waitingForTheHost',
      'roomConfig',
      'speakerMuted',
      'users',
      'conferenceJoined',
      'webinarSessionId',
      'currentSession',
      'connectionState',
      'recordingStatus',
    ]),
    ...mapState(SETTINGS, ['hotkeys', 'logoList', 'backgroundList', 'overlayList']),
    ...mapState(DESTINATIONS, ['destinations']),
    loading() {
      return !this.conferenceJoined && !this.waitingForTheHost;
    },
    providers() {
      return this.currentSession?.items || [];
    },
    showGoLiveButton() {
      return this.currentSession && this.currentSession.status !== 'finished';
    },
    showAddDestinationButton() {
      return (
        this.getLocalUser.isModerator && this.currentSession && this.currentSession.status === BROADCAST_FILTERS.UPCOMING && !this.currentSession.items.length
      );
    },
    goLiveBtnColor() {
      return this.isWebinarStarted || this.recordingStatus === RECORDING_STATUS.REMOTE_RECORD ? 'red' : 'theme-btn-color';
    },
    recordText() {
      return this.recordingStatus === RECORDING_STATUS.REMOTE_RECORD ? 'studio.stopRecord' : 'studio.record';
    },

    goLiveBtnText() {
      return this.providers.length ? this.startStreamText : this.recordText;
    },
    startStreamText() {
      return this.isWebinarStarted ? 'studio.stop' : 'studio.start';
    },
    isStreamStarted() {
      return this.recordingStatus === RECORDING_STATUS.REMOTE_RECORD || this.isWebinarStarted;
    },
    recordStatusText() {
      return this.recordingStatus === RECORDING_STATUS.REMOTE_RECORD ? this.$t('streamStatus.record') : this.$t('streamStatus.recordEnded');
    },
    streamStatusText() {
      return this.isWebinarStarted ? this.$t('streamStatus.live') : this.$t('streamStatus.ended');
    },
    statusText() {
      return this.providers.length ? this.streamStatusText : this.recordStatusText;
    },
    isStreamEnded() {
      return (this.recordEndStatus === RECORDING_STATUS.STOP || this.connectionState === WEBINAR_CONNECTION_STATE.ENDED) && !this.streamHasError;
    },
    btnText() {
      return this.isStreamEnded ? 'studio.returnToDashboard' : this.goLiveBtnText;
    },
    sessionId() {
      return clearStudioName(this.$route.params.sessionId);
    },
    userCount() {
      return this.getUsers.length;
    },
    user() {
      return this.getLocalUser || {};
    },
    isUserLogin() {
      return isAuthenticated();
    },
    recordAction() {
      return this.recordingStatus === RECORDING_STATUS.REMOTE_RECORD ? 'stopRecording' : 'startRecording';
    },
    recordIsPending() {
      return this.recordingStatus === RECORDING_STATUS.PENDING;
    },
    hasExpiredToken() {
      return this.providers.some((p) => p.refreshTokenValid === 'false');
    },
    goLiveIsDisabled() {
      return this.recordIsPending || this.hasExpiredToken;
    },
    disableStreamMenu() {
      return Boolean(!this.providers.length) || Boolean(this.webinarSessionId) || this.isStreamEnded;
    },
    modeText() {
      return this.$t('studio.mode', { mode: this.providers.length ? '' : this.$t('studio.modes.recordOnly') });
    },
    cancelBtn() {
      return 'studio-cancel-broadcast';
    },
  },
  mounted() {
    window.addEventListener('beforeunload', () => {
      this.removeStagedUser({ userId: this.getLocalUser.id });
    });
  },
  beforeDestroy() {
    if (this.getLocalUser.screenSharing) {
      this.stopScreenShare();
    }
    setTimeout(() => {
      if (this.getLocalUser.isModerator) {
        this.destroyRoomAndLeave();
      } else {
        this.leaveCall();
      }
    }, 1000);
  },
  methods: {
    ...mapActions(STUDIO, [
      'initXperMeet',
      'setVideoMute',
      'setAudioMute',
      'setSpeakerMute',
      'setInitialRoomConfig',
      'startBroadcast',
      'stopBroadcast',
      'leaveCall',
      'destroyRoomAndLeave',
      'updateRoomConfig',
      'fetchSessionById',
      'fetchPublicSessionById',
      'startRecording',
      'stopRecording',
      'updateCurrentSession',
      'removeStagedUser',
      'stopScreenShare',
    ]),
    ...mapActions(USER, ['login']),
    ...mapMutations(STUDIO, [SET_DESIGN]),
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    async fetchSession() {
      if (isAuthenticated()) {
        try {
          await this.fetchSessionById(this.sessionId);
          this.isOwner = true;
        } catch (err) {
          await this.fetchPublicSessionById(this.sessionId);
        }
      } else {
        await this.fetchPublicSessionById(this.sessionId);
      }
    },
    mediaDeviceInitiated({ errorCode }) {
      this.$refs.mediaDeviceInitiator.destroyVideoStreams();
      if (errorCode) {
        this.errorCode = errorCode;
        consoleError('mediaDeviceInitiated::Error', errorCode);
      } else {
        this.init();
      }
    },
    getConfig() {
      return {
        startWithAudioMuted: storage.getItem('startWithAudioMuted'),
        startWithVideoMuted: storage.getItem('startWithVideoMuted'),
        roomName: this.sessionId,
        startMutedPolicyConfig: {
          allowAdminStartVideo: false,
        },
      };
    },
    async init() {
      try {
        const config = this.getConfig();
        await this.fetchSession();

        consoleLog('Broadcast initialized with config: ', config);

        this.initXperMeet({
          ...config,
          withoutAuth: !this.isOwner,
        });
      } catch (err) {
        consoleError('Broadcast initialized Error: ', err);
        window.location.href = `${window.location.origin}/error/97`;
      }
    },
    onHotkeyPressed(e) {
      if (e.target.nodeName === 'BODY' || e.keyCode === 9) {
        const { keyCode, code } = e;
        const hotkey = this.findSettingByKeys({ code, keyCode });
        if (hotkey) {
          this.triggerHotkeyByType(hotkey.type);
        }
      }
    },
    triggerHotkeyByType(type) {
      if (type === CAMERA_ON_OFF) {
        this.setVideoMute(!this.user.videoMuted);
      }
      if (type === SPEAKER_ON_OFF) {
        this.setSpeakerMute(!this.speakerMuted);
      }
      if (type === MICROPHONE_ON_OFF) {
        this.setAudioMute(!this.user.audioMuted);
      }
    },
    handleStart() {
      if (!this.providers.length || this.webinarSessionId || this.isStreamEnded) {
        this.handleGoLiveClick();
      }
    },
    handleGoLiveClick() {
      if (this.isStreamEnded) {
        this.$router.push({ name: 'Broadcasts' });
      } else {
        if (!this.providers.length) {
          this[this.recordAction]();
        } else {
          this.streamMenu = false;
          if (this.webinarSessionId) {
            this.stopStream();
          } else {
            this.goLive();
          }
        }
      }
    },
    async goLive() {
      this.broadcastInProgress = true;
      this.streamHasError = false;

      try {
        await this.startBroadcast({ sessionId: this.sessionId, saveWebinarStream: this.recordStream });
      } catch (err) {
        consoleError('Start Broadcast Error: ', err.textContent || err);
        this.broadcastInProgress = false;
        this.streamHasError = true;

        if (err.textContent === 'all Jibris are busy') {
          this.showToastNotification({ body: 'error.allServersAreBusy', config: { type: TOAST_TYPE.WARNING } });
        } else {
          this.showToastNotification({ body: 'error.broadcsatStartFailed', config: { type: TOAST_TYPE.ERROR } });
        }
      }
    },
    async stopStream() {
      try {
        await this.stopBroadcast({ sessionId: this.sessionId });
        this.streamStarted = false;
      } catch (err) {
        consoleError('Stop Broadcast Error', err);
        this.showToastNotification({ body: 'error.broadcsatStopFailed', config: { type: TOAST_TYPE.ERROR } });
      }
    },
    showAddEditCurrentSessionModal() {
      const modal = this.$showModal(
        AddEditBroadcastModal,
        { broadcast: this.currentSession },
        {
          completed: () => {
            modal.close();
            this.updateCurrentSession();
          },
        },
      );
    },
    setupResizeListener() {
      const studio = document.getElementById('studio');
      const broadcast = document.getElementById('broadcast-wrapper');

      const onWindowResize = this.onWindowResize(studio, broadcast);
      window.onresize = onWindowResize;
      onWindowResize();
    },
    onWindowResize(studio, broadcast) {
      const STUDIO_MENU_HEIGHT = 300;
      const STUDIO_WIDTH_TRASHOLD = 250;

      return () => {
        const studioHeight = studio.clientHeight;
        const studioWidth = studio.clientWidth;
        let broadcastHeight;
        let broadcastWidth;

        if (studioHeight > studioWidth - STUDIO_WIDTH_TRASHOLD) {
          broadcastWidth = studioWidth * 0.85;
          broadcastHeight = broadcastWidth * 0.56;
        } else {
          broadcastHeight = (studioHeight - STUDIO_MENU_HEIGHT) * 0.85;
          broadcastWidth = broadcastHeight * 1.78;
        }

        broadcast.style.height = `${broadcastHeight}px`;
        broadcast.style.width = `${broadcastWidth}px`;
      };
    },
    handleAvatarModal(value) {
      this.showAvatarCropper = value;
    },
    clearAvatar() {
      this.$refs.AvatarCropperModal.clearAvatar();
    },
  },
  watch: {
    logoList: {
      deep: true,
      immediate: true,
      handler() {
        if (!this.loading) {
          this[SET_DESIGN]({ type: 'logoList', value: this.logoList });
          //this.updateRoomConfig({ logoList: this.logoList });
        }
      },
    },
    isWebinarEnded() {
      if (this.isWebinarEnded) {
        this.broadcastInProgress = false;
        this.showToastNotification('studio.broadcastStopped');
      }
    },
    isWebinarStarted() {
      if (this.isWebinarStarted) {
        this.broadcastInProgress = false;
      }
    },
    recordingStatus(oldVal, newVal) {
      if (oldVal === RECORDING_STATUS.PENDING && newVal === RECORDING_STATUS.REMOTE_RECORD) {
        this.recordEndStatus = RECORDING_STATUS.PENDING;
      }
      if (oldVal === RECORDING_STATUS.STOP && newVal === RECORDING_STATUS.PENDING && this.recordEndStatus === RECORDING_STATUS.PENDING) {
        this.recordEndStatus = RECORDING_STATUS.STOP;
      }
    },
    loading() {
      if (!this.loading) {
        setTimeout(() => {
          this.setupResizeListener();
          this[SET_DESIGN]({ type: 'logoList', value: this.logoList });
          this[SET_DESIGN]({ type: 'backgroundList', value: this.backgroundList });
          this[SET_DESIGN]({ type: 'overlayList', value: this.overlayList });

          //this.updateRoomConfig({ logoList: this.logoList });
        }, 1000);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.start-stream-card {
  &--text {
    font-size: 14px;
    color: var(--v-white-base);
  }
}

.start-broadcast-btn {
  border-radius: 8px;
  ::v-deep.v-btn__content {
    text-transform: none;
    font-size: 18px;
    font-weight: 400;
  }
  &.stream-menu-btn {
    &:focus {
      &::before {
        opacity: 0.01;
      }
    }
  }
}

.studio--header {
  font-weight: 300;
  border-bottom: 0.5px solid rgba(131, 125, 125, 0.5) !important;

  &--title {
    font-size: 20px;
    max-width: 500px;
  }

  &--mode {
    font-size: 18px;
  }
}

.loading-screen {
  height: 100%;
  background-color: var(--v-bg-gray-base);

  .pulse {
    animation: pulse 1s infinite ease-in-out alternate;
  }
  @keyframes pulse {
    from {
      transform: scale(0.8);
    }
    to {
      transform: scale(1.2);
    }
  }
}

.studio {
  width: 100%;
  height: 100%;
  padding-top: 75px;
  background-color: var(--v-bg-gray-base);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &--header {
    background-color: var(--v-bg-gray-base);
    font-weight: 300;
    .v-toolbar__content {
      border-bottom: 0.5px solid rgba(131, 125, 125, 0.5) !important;
    }

    &--title {
      font-size: 20px;
    }

    &--mode {
      font-size: 18px;
    }
  }

  &--broadcast-wrapper {
    margin: 0 auto;
  }

  &--bottom-menu-backstage {
    overflow-x: auto;
  }

  &--bottom-menu {
    width: 100%;
    bottom: 0;

    &--backstage {
      &--title {
        font-weight: 600;
        font-size: 20px;
        text-transform: uppercase;
      }
    }
  }
}
.broadcast-status {
  font-size: 18px;
  &--icon {
    box-sizing: border-box;
    position: relative;
    display: block;
    transform: scale(var(--ggs, 1));
    width: 16px;
    height: 16px;
    border: 3px solid transparent;
    box-shadow: 0 0 0 2px, inset 0 0 0 10px;
    border-radius: 100px;
    color: var(--v-gray-base);
    &.started {
      -webkit-animation: COLOR-FLASH 1s infinite; /* Safari 4+ */
      -moz-animation: COLOR-FLASH 1s infinite; /* Fx 5+ */
      -o-animation: COLOR-FLASH 1s infinite; /* Opera 12+ */
      animation: COLOR-FLASH 1s infinite; /* IE 10+, Fx 29+ */
    }
  }
}

@-webkit-keyframes COLOR-FLASH {
  0%,
  49% {
    color: var(--v-error-base);
  }
  50%,
  100% {
    color: #ffffff;
  }
}
</style>


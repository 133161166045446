var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "camera-device-settings py-1",
      class: { "has-alert": _vm.infoAlert },
    },
    [
      _c("DeviceSelector", {
        ref: "deviceSelector",
        attrs: {
          infoAlert: _vm.infoAlert,
          "hide-microphone-settings": "",
          "hide-speaker-settings": "",
        },
        on: { cameraChange: _vm.handleCameraChange },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <v-text-field
    v-model="message"
    :disabled="disableChat"
    :background-color="chatBackground"
    height="67"
    hide-details
    solo
    flat
    class="send-message mx-1"
    v-on:keyup.enter="send"
    @focus="$emit('onFocus')"
  >
    <template v-slot:label>
      <div class="d-flex justify-center align-start">
        <div>{{ $t('rightMenu.sendAMessage') }}</div>
        <div v-if="disableChat" class="send-message-input-warning ml-4">{{ errorText }}</div>
      </div>
    </template>
  </v-text-field>
</template>
<script>
import { mapGetters } from 'vuex';
import { STUDIO } from '@/constants/modules';

export default {
  name: 'ChatInput',
  props: {
    type: {
      type: String,
      required: true,
    },
    disableChat: {
      type: Boolean,
    },
    shortCodes: {
      type: Array,
    },
  },
  data() {
    return {
      message: '',
    };
  },
  computed: {
    ...mapGetters(STUDIO, ['isWebinarStarted']),
    chatBackground() {
      return this.disableChat ? 'var(--v-chat-disabled-input-color)' : 'var(--v-chat-input-color)';
    },
    errorText() {
      return this.isWebinarStarted ? this.$t('youMustSelectProvider') : this.$t('forSendMessageWarning');
    },
  },
  methods: {
    send() {
      if (!this.message) {
        return;
      } else {
        this.$emit('send', this.message);
        this.message = '';
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.send-message-input-warning {
  color: var(--v-error-base);
  font-size: 12px;
}
.send-message {
  ::v-deep .v-input__slot {
    min-height: auto !important;
    .v-text-field__slot {
      height: 100%;
      .v-label {
        color: white;
        font-size: 14px;
        margin: auto;
        top: 5px;
      }
    }
  }
}
</style>


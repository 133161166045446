<template>
  <div class="audio-device-settings py-1">
    <DeviceSelector
      :infoAlert="infoAlert"
      hide-camera-settings
      ref="deviceSelector"
      :class="{ 'has-alert': infoAlert }"
      @microphoneChange="handleMicrophoneChange"
      @speakerChange="handleSpeakerChange"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import DeviceSelector from '@/components/DeviceSelectors/DeviceSelector';
import { DEVICES } from '@/constants/modules';

export default {
  name: 'AudioSettings',
  components: { DeviceSelector },
  props: {
    infoAlert: Boolean,
  },
  data() {
    return {
      selectedMicrophoneId: null,
      selectedSpeakerId: null,
      selectedCameraId: null,
    };
  },
  computed: {
    ...mapGetters(DEVICES, [
      'getMicrophones',
      'getSpeakers',
      'hasMicrophone',
      'microphoneAllowed',
      'getSelectedCameraId',
      'getSelectedMicrophoneId',
      'getSelectedSpeakerId',
    ]),
    defaultMicrophoneDeviceName() {
      return this.getMicrophones.find((item) => item.deviceId === this.selectedMicrophoneId)?.label;
    },
    defaultSpeakerDeviceName() {
      return this.getSpeakers.find((item) => item.deviceId === this.selectedSpeakerId)?.label;
    },
  },
  methods: {
    ...mapActions(DEVICES, ['changeDevice', 'changeMicrophoneById', 'changeSpeakerById']),
    handleSpeakerChange(deviceId) {
      this.selectedSpeakerId = deviceId;
    },
    handleMicrophoneChange(deviceId) {
      this.selectedMicrophoneId = deviceId;
    },
    save() {
      if (this.selectedMicrophoneId && this.selectedMicrophoneId !== this.getSelectedMicrophoneId) {
        this.changeMicrophoneById(this.selectedMicrophoneId);
      }
      if (this.selectedSpeakerId && this.selectedSpeakerId !== this.getSelectedSpeakerId) {
        this.changeSpeakerById(this.selectedSpeakerId);
      }
    },
  },
  watch: {
    getSelectedMicrophoneId: {
      handler: function () {
        this.selectedMicrophoneId = this.getSelectedMicrophoneId || null;
      },
      immediate: true,
    },
    getSelectedSpeakerId: {
      handler: function () {
        this.selectedSpeakerId = this.getSelectedSpeakerId || null;
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.audio-device-settings {
  max-height: calc(100vh - 190px);
  &.has-alert {
    max-height: calc(100vh - 250px);
  }
  overflow: auto;
}
</style>

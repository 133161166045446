var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-menu",
    {
      attrs: {
        "close-on-content-click": "",
        "offset-y": "",
        transition: "scale-transition",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass:
                      "pl-1 py-1 mr-3 d-flex align-center destination-menu-selector-wrapper cursor-pointer",
                  },
                  on
                ),
                [
                  _c("DestinationAvatar", {
                    staticClass: "message-avatar",
                    attrs: {
                      size: "28",
                      avatar: _vm.destination.avatarUrl,
                      "destination-types": _vm.channel,
                      "icon-position": "right",
                    },
                  }),
                  _c("AppIcon", {
                    staticClass: "mr-2 destination-menu-icon",
                    attrs: {
                      icon:
                        attrs["aria-expanded"] === "true"
                          ? "chevron-up"
                          : "chevron-down",
                      color: "var(--v-text-gray-base)",
                      width: "24",
                    },
                  }),
                ],
                1
              ),
            ]
          },
        },
      ]),
    },
    [
      _c(
        "v-list",
        [
          _c("v-list-item-title", [
            _c(
              "div",
              { staticClass: "d-flex pa-4" },
              [
                _c("DestinationAvatar", {
                  staticClass: "message-avatar mr-3",
                  attrs: {
                    size: "50",
                    avatar: _vm.destination.avatarUrl,
                    "destination-types": _vm.channel,
                    "icon-position": "right",
                    "icon-height": "25",
                  },
                }),
                _c(
                  "div",
                  {
                    staticClass:
                      "d-flex flex-column justify-center item-head-wrapper",
                  },
                  [
                    _c(
                      "v-tooltip",
                      {
                        attrs: { bottom: "", "max-width": "300" },
                        scopedSlots: _vm._u([
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "span",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "item-head-title ellipsis",
                                      },
                                      "span",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_vm._v(_vm._s(_vm.destination.title))]
                                ),
                              ]
                            },
                          },
                        ]),
                      },
                      [_c("span", [_vm._v(_vm._s(_vm.destination.title))])]
                    ),
                    _c("span", { staticClass: "item-head-user" }, [
                      _vm._v(
                        _vm._s(_vm.destination.userProviderDetail.displayName)
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticClass: "d-flex align-center ready-to-go mx-4 pa-2",
                class: { started: _vm.webinarSessionId },
              },
              [
                _c("AppIcon", {
                  attrs: {
                    size: "20",
                    icon: "check-1",
                    color: _vm.webinarSessionId ? "success" : "",
                  },
                }),
                _c("span", { staticClass: "ready-text ml-3" }, [
                  _vm._v(
                    _vm._s(
                      _vm.$t(_vm.youAreLiveText, { destination: _vm.channel })
                    )
                  ),
                ]),
              ],
              1
            ),
          ]),
          _vm.isModerator
            ? _c(
                "v-list-item",
                {
                  staticClass: "my-2",
                  attrs: { dense: "", ripple: false },
                  on: { click: _vm.showAddEditModal },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center px-2 py-2" },
                    [
                      _c("AppIcon", {
                        attrs: {
                          size: "20",
                          icon: "pen",
                          color: "var(--v-text-gray-base)",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "destination-list-text ml-3" },
                        [_vm._v(_vm._s(_vm.$t("edit")))]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _vm.showRemoveListItem
            ? _c(
                "v-list-item",
                {
                  staticClass: "my-2",
                  attrs: { dense: "" },
                  on: { click: _vm.showDeleteBroadcastModal },
                },
                [
                  _c(
                    "div",
                    { staticClass: "d-flex align-center px-2 py-2" },
                    [
                      _c("AppIcon", {
                        attrs: {
                          size: "20",
                          icon: "trash",
                          color: "var(--v-text-gray-base)",
                        },
                      }),
                      _c(
                        "span",
                        { staticClass: "destination-list-text ml-3" },
                        [_vm._v(_vm._s(_vm.$t("remove")))]
                      ),
                    ],
                    1
                  ),
                ]
              )
            : _vm._e(),
          _c("v-divider", { class: { "mt-2": !_vm.isModerator } }),
          _c("v-list-item", { staticClass: "mt-2", attrs: { dense: "" } }, [
            _c(
              "a",
              {
                staticClass: "d-flex align-center px-2 py-2 view-on",
                attrs: { href: _vm.destination.viewUrl, target: "_blank" },
              },
              [
                _c("ProviderIcon", {
                  staticClass: "stream-image",
                  staticStyle: { height: "28px" },
                  attrs: { provider: _vm.channel },
                }),
                _c("span", { staticClass: "destination-list-text ml-3" }, [
                  _vm._v(_vm._s(_vm.$t("viewOn", { channel: _vm.channel }))),
                ]),
              ],
              1
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
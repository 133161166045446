<template>
  <AppModal :title="$t('changeDisplayName')" max-width="450">
    <AppInput v-model="userName" :label="$t('displayName')" :max-length="30"></AppInput>
    <AppButton block @click="create" :loading="loading">{{ $t('saveChanges') }}</AppButton>
  </AppModal>
</template>

<script>
import { mapActions } from 'vuex';
import { STUDIO } from '@/constants/modules';
import storage from '@/lib/storage';

export default {
  name: 'ChangeDisplayNameModal',
  data() {
    return {
      userName: storage.getItem('displayName') || '',
      loading: false,
    };
  },
  methods: {
    ...mapActions(STUDIO, ['setDisplayName']),
    async create() {
      await this.setDisplayName(this.userName);
      this.$emit('completed');
    },

    close(type) {
      this.dialog = false;
      this.$modal?.close(type);
    },
  },
};
</script>

<template>
  <div
    class="message-wrapper d-flex align-start my-2 px-2 py-2"
    :class="{
      'stream-pending': isPending,
      'message-wrapper--hover': $can('showComment', 'Studio') && !message.unclickable,
      'stream-message-wrapper ': message.type === 'stream',
      'on-queue': isOnQueue,
      current: isCurrentMessage,
    }"
    @click="showLiveComment"
  >
    <DestinationAvatar
      class="message-avatar"
      :avatar="messageAvatar"
      :destination-types="message.selectedSessionPayload || message.streamType | getUniques"
      :hide-icon="isRoomMessage"
    />
    <div class="d-inline-flex flex-column ml-3">
      <div class="mb-1" :class="{ 'my-message': !message.incoming }">
        <span class="sender">{{ getSender }}</span>
        <span class="time ml-2">{{ message.time }}</span>
      </div>
      <div class="message">
        <div v-if="message.statusType === 'error'" class="d-flex align-center mr-2 send-again-wrapper" @click.stop="$emit('sendAgain', message)">
          <AppIcon icon="alert-circle-1" size="16" color="error" />
          <span class="send-again-text">{{ $t('sendAgain') }}</span>
        </div>
        <div v-else-if="isPending" class="mr-2"><v-progress-circular size="14" width="1" indeterminate></v-progress-circular></div>
        <div :inner-html.prop="message.body | urlify"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { urlify } from '@/helpers/regex';
import DestinationAvatar from '@/components/DestinationAvatar';
import { STUDIO } from '@/constants/modules';
import { SETTINGS } from '../../constants/modules';
import bannerQueue from '@/helpers/banner';

export default {
  name: 'Message',
  components: {
    DestinationAvatar,
  },
  filters: {
    urlify,
  },
  props: {
    message: {
      type: Object,
      required: true,
    },
    isPending: {
      Boolean,
    },
  },
  computed: {
    ...mapGetters(STUDIO, ['getUserById']),
    ...mapGetters(SETTINGS, ['getLiveCommentSettings']),
    ...mapState(STUDIO, ['currentBanners', 'currentBannerId']),
    getSender() {
      const { sender, incoming } = this.message;

      if (incoming) {
        return sender || 'Old Message';
      }

      return this.$t('you');
    },
    senderTextToNumber() {
      return this.message.incoming ? [...this.getSender].reduce((prev, curr) => prev + curr.charCodeAt(0), 0) : '';
    },
    isOnQueue() {
      return !!this.currentBanners.find((b) => +b.id === this.message.id);
    },
    isCurrentMessage() {
      return +this.currentBannerId === this.message.id;
    },
    isRoomMessage() {
      return this.message.type === 'inRoom';
    },
    canShowComment() {
      return this.$can('showComment', 'Studio') && !this.isRoomMessage && !this.message.unclickable && !this.isCurrentMessage && !this.isOnQueue;
    },
    messageAvatar() {
      if (this.message.type === 'inRoom') {
        return this.getUserById(this.message.userId) ? this.getUserById(this.message.userId).avatar : '';
      } else {
        return this.message?.senderAvatar;
      }
    },
  },
  methods: {
    ...mapActions(STUDIO, ['sendBannerToBroadcast', 'removeBannerFromBroadcast']),
    showLiveComment() {
      const comment = {
        ...this.message,
        text: this.message.body,
        duration: this.getLiveCommentSettings.parmanent ? null : this.getLiveCommentSettings.duration || 4,
      };

      if (bannerQueue.itemIsExists(comment.id)) {
        this.removeBannerFromBroadcast(comment);
      } else {
        this.sendBannerToBroadcast(comment);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.message-wrapper {
  position: relative;
  background-color: var(--v-theme-gray-base);
  border-radius: 4px;

  &.stream-message-wrapper {
    line-height: 0.4;
    &.on-queue {
      .time {
        color: var(--v-white-base);
      }
    }

    &.current {
      background-color: var(--v-theme-primary-base);
      .time {
        color: var(--v-white-base);
      }
    }
    &.message-wrapper--hover {
      cursor: pointer;
    }

    &:hover {
      &.message-wrapper--hover {
        background-color: #4c4d58;
      }
    }
  }

  .sender-selected-sessions {
    min-width: 42px;
  }
  .time {
    color: var(--v-message-time-color);
    font-size: 13px;
    line-height: 16px;
  }

  .sender {
    font-size: 13px;
    font-weight: 700;
    line-height: 16px;
    max-width: 7em;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--v-white-base);
  }
  .message {
    font-size: 13px;
    line-height: 15px;
    color: var(--v-white-base);
    overflow: hidden;
    overflow-wrap: anywhere;
    font-weight: 300;
    &.my-message {
      width: 100%;
    }
    ::v-deep.urlified-link {
      color: #ffffff !important;
    }
  }
  &.stream-pending {
    opacity: 0.6;
  }
  .send-again-wrapper {
    cursor: pointer;
    .send-again-text {
      color: var(--v-error-base);
      font-size: 12px;
    }
  }
}
</style>


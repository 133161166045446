<template>
  <div class="background-wrapper d-flex justify-center align-center cursor-pointer">
    <div class="background-menu" :class="{ selected: selected }">
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <AppIconButton
            :width="30"
            icon-size="16px"
            :icon="selected ? 'eye-off' : 'eye-on'"
            v-bind="attrs"
            v-on="on"
            :class="{ 'mr-1': background.addedByModerator }"
            @click="$emit('changeBackgroundSelected', background.id)"
          />
        </template>
        <span>{{ $t(selected ? 'hide' : 'show') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <AppIconButton
            v-if="background.addedByModerator"
            class="ml-1"
            :width="30"
            icon-size="16px"
            :icon="'minus-circle'"
            v-bind="attrs"
            v-on="on"
            @click="$emit('deleteLogoFromList', background.id)"
          />
        </template>
        <span>{{ $t('delete') }}</span>
      </v-tooltip>
    </div>
    <img class="background-image" :src="background.url" :class="{ selected: selected }" />
  </div>
</template>
<script>
export default {
  name: 'Background',
  props: {
    background: {
      type: Object,
    },
    selected: {
      type: Boolean,
    },
  },
};
</script>

<style lang="scss" scoped>
.background-wrapper {
  width: 108px;
  height: 54px;
  border-radius: 3px;
  position: relative;

  &:hover {
    .background-menu {
      opacity: 1;
    }
  }
  .background-image {
    width: 108px;
    &.selected {
      border: 3px solid var(--v-border-orange);
    }
  }
  .background-menu {
    transition: all 500ms;
    background-color: var(--v-black-40);
    position: absolute;
    width: 100%;
    height: 55px;
    top: -1px;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    &.selected {
      height: 57px;
      top: -2px;
    }
  }
}
</style>


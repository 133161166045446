<template>
  <div class="device-selector-wrapper my-2">
    <v-menu v-if="!hideCameraSettings" v-model="showCameraMenu" class="popup-menu" offset-y content-class="rounded" :disabled="!hasCamera">
      <template v-slot:activator="{ on, attrs }">
        <div class="device-item-title my-2 py-3 align-center" v-bind="attrs" v-on="on" :class="{ 'cursor-default': !hasCamera }">
          <AppIcon :color="!hasCamera ? 'logo-color' : 'secondary'" :icon="!hasCamera ? 'video-off-1' : 'video'" class="mr-2 flex-shrink-0"></AppIcon>
          <span class="default-device-name ellipsis mr-2">{{ cameraText }}</span>
          <v-spacer></v-spacer>
          <AppIcon :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'" :class="{ 'opacity-50': !hasCamera }"></AppIcon>
        </div>
      </template>
      <CameraDeviceSelector
        v-if="showCameraMenu"
        ref="cameraDeviceSelector"
        v-model="selectedCameraId"
        data-test-id="XSS_C_S_1"
        :showPreview="showVideoPreview"
        :class="{ 'has-alert': infoAlert }"
        @change="$emit('cameraChange', selectedCameraId)"
      />
    </v-menu>

    <v-menu v-if="!hideMicrophoneSettings" v-model="showMicrophoneMenu" class="popup-menu" offset-y content-class="rounded" :disabled="!hasMicrophone">
      <template v-slot:activator="{ on, attrs }">
        <div class="device-item-title my-2 py-3 align-center" v-bind="attrs" v-on="on" :class="{ 'cursor-default': !hasMicrophone }">
          <AppIcon :color="!hasMicrophone ? 'logo-color' : 'secondary'" :icon="!hasMicrophone ? 'mic-off' : 'mic'" class="mr-2"></AppIcon>
          <span class="default-device-name ellipsis mr-2">{{ microphoneText }}</span>
          <v-spacer></v-spacer>
          <MicrophoneMeter v-show="!hasMicrophone" class="mx-6" :level="audioLevels[selectedMicrophoneId]" />
          <AppIcon :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'" :class="{ 'opacity-50': !hasMicrophone }"></AppIcon>
        </div>
      </template>
      <MicrophoneDeviceSelector
        v-if="showMicrophoneMenu"
        v-model="selectedMicrophoneId"
        data-test-id="XSS_M_S_1"
        :class="{ 'has-alert': infoAlert }"
        @onAudioLevelChange="onAudioLevelChanged"
        @change="$emit('microphoneChange', selectedMicrophoneId)"
      />
    </v-menu>

    <v-menu
      v-if="!hideSpeakerSettings && showSpeakerAppIcon"
      v-model="showSpeakerMenu"
      class="popup-menu"
      offset-y
      content-class="rounded"
      :disabled="!hasSpeaker"
    >
      <template v-slot:activator="{ on, attrs }">
        <div class="device-item-title my-2 py-3 align-center" v-bind="attrs" v-on="on">
          <AppIcon :color="!hasSpeaker ? 'logo-color' : 'secondary'" :icon="!hasSpeaker ? 'volume-off' : 'volume-1'" class="mr-2"></AppIcon>
          <span class="default-device-name ellipsis mr-2">{{ speakerText }}</span>
          <v-spacer></v-spacer>
          <AppIcon :icon="attrs['aria-expanded'] === 'true' ? 'chevron-up' : 'chevron-down'" :class="{ 'opacity-50': !hasSpeaker }"></AppIcon>
        </div>
      </template>
      <SpeakerDeviceSelector
        v-if="showSpeakerMenu"
        v-model="selectedSpeakerId"
        data-test-id="XSS_S_S_1"
        :class="{ 'has-alert': infoAlert }"
        @change="$emit('speakerChange', selectedSpeakerId)"
      />
    </v-menu>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CameraDeviceSelector from '@/components/DeviceSelectors/CameraDeviceSelector';
import MicrophoneDeviceSelector from '@/components/DeviceSelectors/MicrophoneDeviceSelector';
import MicrophoneMeter from '@/components/DeviceSelectors/SettingsMicrophoneMeter';
import SpeakerDeviceSelector from '@/components/DeviceSelectors/SpeakerDeviceSelector';
import { DEVICES } from '@/constants/modules';
import { isSafari } from '@/helpers/detect-browser';
import { isMacos } from '@/helpers/os';

export default {
  name: 'DeviceSelector',
  components: { SpeakerDeviceSelector, MicrophoneMeter, MicrophoneDeviceSelector, CameraDeviceSelector },
  props: {
    hideCameraSettings: Boolean,
    hideMicrophoneSettings: Boolean,
    hideSpeakerSettings: Boolean,
    infoAlert: Boolean,
  },
  data() {
    return {
      selectedCameraId: null,
      selectedMicrophoneId: null,
      selectedSpeakerId: null,
      audioLevels: {},
      showCameraMenu: false,
      showMicrophoneMenu: false,
      showSpeakerMenu: false,
    };
  },
  computed: {
    ...mapGetters(DEVICES, [
      'getCameras',
      'hasCamera',
      'getMicrophones',
      'hasMicrophone',
      'getSpeakers',
      'hasSpeaker',
      'getSelectedCameraId',
      'getSelectedMicrophoneId',
      'getSelectedSpeakerId',
    ]),
    defaultCameraDeviceName() {
      return this.getCameras.find((item) => item.deviceId === this.selectedCameraId)?.label;
    },
    defaultMicrophoneDeviceName() {
      return this.getMicrophones.find((item) => item.deviceId === this.selectedMicrophoneId)?.label;
    },
    defaultSpeakerDeviceName() {
      return this.getSpeakers.find((item) => item.deviceId === this.selectedSpeakerId)?.label;
    },
    cameraText() {
      if (!this.hasCamera) {
        return this.$t('noCameraDetected');
      }
      return this.defaultCameraDeviceName;
    },
    microphoneText() {
      if (!this.hasMicrophone) {
        return this.$t('noMicrophoneDetected');
      }
      return this.defaultMicrophoneDeviceName;
    },
    speakerText() {
      if (!this.hasSpeaker) {
        return this.$t('noSpeakerDetected');
      }
      return this.defaultSpeakerDeviceName;
    },
    showSpeakerAppIcon() {
      return !isSafari();
    },
    showVideoPreview() {
      return !this.$isMobile && !isMacos();
    },
  },
  beforeDestroy() {
    // Destroy video stream
    this.$refs.cameraDeviceSelector?.clearTracks();
  },
  methods: {
    onAudioLevelChanged(v) {
      this.audioLevels = v;
    },
  },
  watch: {
    getSelectedMicrophoneId: {
      handler: function () {
        this.selectedMicrophoneId = this.getSelectedMicrophoneId || null;
      },
      immediate: true,
    },
    getSelectedSpeakerId: {
      handler: function () {
        this.selectedSpeakerId = this.getSelectedSpeakerId || null;
      },
      immediate: true,
    },
    getSelectedCameraId: {
      handler: function () {
        this.selectedCameraId = this.getSelectedCameraId || null;
      },
      immediate: true,
    },
  },
};
</script>

<style lang="scss">
.device-selector-wrapper {
  .device-item-title {
    border: 1px solid var(--v-white-base);
    display: flex;
    border-radius: 8px;

    .icon {
      flex: 0;
    }
  }
  .popup-menu {
    .v-menu__content {
      box-shadow: none !important;
    }
  }

  @media (max-width: $breakpoint-mobile) {
    .popup-menu {
      .v-menu__content {
        .device-selector {
          overflow: auto;
          height: 200px;
          border-radius: 15px 15px 0 0 !important;
          border: none !important;
          background-color: var(--v-primary-base) !important;
        }
      }
    }
  }
}
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-menu",
        {
          attrs: {
            top: "",
            "offset-y": "",
            "close-on-content-click": false,
            disabled: _vm.providerItems.length < 2,
          },
          on: { input: _vm.onMenuHandled },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "span",
                    _vm._g(
                      _vm._b(
                        {
                          class: {
                            "cursor-default": _vm.providerItems.length < 2,
                          },
                        },
                        "span",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [
                      _vm._v(" " + _vm._s(_vm.$t("to")) + " : "),
                      _c("span", { staticClass: "selector-span" }, [
                        _vm._v(_vm._s(_vm.selectedProviders)),
                      ]),
                    ]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.showMenu,
            callback: function ($$v) {
              _vm.showMenu = $$v
            },
            expression: "showMenu",
          },
        },
        [
          _c(
            "v-list",
            {
              staticClass: "py-0",
              attrs: { color: "var(--v-chat-input-color)" },
            },
            _vm._l(_vm.filteredProviderItems, function (item, index) {
              return _c(
                "div",
                {
                  key: index,
                  staticClass: "cursor-pointer",
                  on: {
                    click: function ($event) {
                      return _vm.selectItem(item)
                    },
                  },
                },
                [
                  !_vm.selectedCode
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "d-flex justify-start align-center py-2 chat-stream-selector-list w-full",
                          class: { active: item.selected },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "chat-stream-selector-list--icon" },
                            [
                              _c("AppIcon", {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: item.selected,
                                    expression: "item.selected",
                                  },
                                ],
                                staticClass: "ml-2",
                                attrs: { size: "14", icon: "check" },
                              }),
                            ],
                            1
                          ),
                          _c("span", { staticClass: "ml-1 mr-4" }, [
                            _vm._v(_vm._s(item.orderedTitle || item.title)),
                          ]),
                        ]
                      )
                    : _c(
                        "div",
                        {
                          staticClass:
                            "d-flex align-center py-2 w-full chat-stream-selector-list-with-short-code",
                          class: { active: item.selected },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "ml-2 stream-small-font" },
                            [_vm._v(_vm._s(index + 1))]
                          ),
                          _c("ProviderIcon", {
                            staticClass: "ml-2 stream-image",
                            attrs: { provider: _vm.selectedCode },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "ml-2 mr-4 stream-title stream-small-font",
                            },
                            [_vm._v(_vm._s(item.title))]
                          ),
                        ],
                        1
                      ),
                ]
              )
            }),
            0
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
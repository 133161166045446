var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("div", { staticClass: "pt-5" }, [
        _c("div", { staticClass: "text-h5 mb-4" }, [
          _vm._v(_vm._s(_vm.$t("liveComments"))),
        ]),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("AppInput", {
              attrs: {
                dense: "",
                label: `${_vm.$t("duration")} (${_vm.$t("seconds")})`,
                disabled: _vm.settings.liveComments.parmanent,
              },
              model: {
                value: _vm.settings.liveComments.duration,
                callback: function ($$v) {
                  _vm.$set(_vm.settings.liveComments, "duration", $$v)
                },
                expression: "settings.liveComments.duration",
              },
            }),
            _c("div", { staticClass: "mx-5" }),
            _c("v-switch", {
              staticClass: "mt-0",
              attrs: {
                color: "var(--v-secondary-lighten1)",
                label: _vm.$t("permanent"),
                "hide-details": "",
              },
              model: {
                value: _vm.settings.liveComments.parmanent,
                callback: function ($$v) {
                  _vm.$set(_vm.settings.liveComments, "parmanent", $$v)
                },
                expression: "settings.liveComments.parmanent",
              },
            }),
          ],
          1
        ),
      ]),
      _c("v-divider"),
      _c("div", { staticClass: "pt-5" }, [
        _c("div", { staticClass: "text-h5 mb-4" }, [
          _vm._v(_vm._s(_vm.$t("banners"))),
        ]),
        _c(
          "div",
          { staticClass: "d-flex align-center" },
          [
            _c("AppInput", {
              attrs: {
                dense: "",
                label: `${_vm.$t("duration")} (${_vm.$t("seconds")})`,
                disabled: _vm.settings.banners.parmanent,
              },
              model: {
                value: _vm.settings.banners.duration,
                callback: function ($$v) {
                  _vm.$set(_vm.settings.banners, "duration", $$v)
                },
                expression: "settings.banners.duration",
              },
            }),
            _c("div", { staticClass: "mx-5" }),
            _c("v-switch", {
              staticClass: "mt-0",
              attrs: {
                color: "var(--v-secondary-lighten1)",
                label: _vm.$t("permanent"),
                "hide-details": "",
              },
              model: {
                value: _vm.settings.banners.parmanent,
                callback: function ($$v) {
                  _vm.$set(_vm.settings.banners, "parmanent", $$v)
                },
                expression: "settings.banners.parmanent",
              },
            }),
          ],
          1
        ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "studio-menu-wrapper" },
    [
      _c(
        "div",
        { staticClass: "studio-menu-selectors" },
        _vm._l(_vm.menuItems, function (item, key) {
          return _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !item.hideSelector,
                  expression: "!item.hideSelector",
                },
              ],
              key: key,
              staticClass:
                "px-0 mt-1 studio-menu-selector-items cursor-pointer",
              class: { "mt-4": key === 0, active: _vm.tab === item.value },
              on: {
                click: function ($event) {
                  _vm.tab = item.value
                },
              },
            },
            [
              _c(
                "v-badge",
                {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value:
                        item.value === _vm.RIGHT_MENU_ITEMS.MESSAGES
                          ? _vm.tooltipText
                          : false,
                      expression:
                        "item.value === RIGHT_MENU_ITEMS.MESSAGES ? tooltipText : false",
                    },
                  ],
                  staticClass: "w-full",
                  attrs: {
                    left: "",
                    color: "secondary",
                    overlap: "",
                    content:
                      item.value === _vm.RIGHT_MENU_ITEMS.MESSAGES
                        ? _vm.totalUnreadedMessageCount
                        : false,
                    value:
                      item.value === _vm.RIGHT_MENU_ITEMS.MESSAGES
                        ? _vm.totalUnreadedMessageCount
                        : false,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "d-flex align-center justify-space-around w-full h-full flex-column pt-2 pb-1 mr-1",
                    },
                    [
                      _c("AppIcon", { attrs: { icon: item.icon, size: "20" } }),
                      _c(
                        "span",
                        { staticClass: "studio-menu-text px-2 mt-2" },
                        [_vm._v(_vm._s(item.text))]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          )
        }),
        0
      ),
      _c(
        "v-navigation-drawer",
        {
          staticClass: "studio-menu-items",
          attrs: {
            permanent: "",
            app: "",
            right: "",
            bottom: "",
            width: "440",
            clipped: "",
          },
        },
        [
          _c(
            "v-tabs",
            {
              attrs: { vertical: "" },
              model: {
                value: _vm.tab,
                callback: function ($$v) {
                  _vm.tab = $$v
                },
                expression: "tab",
              },
            },
            _vm._l(_vm.menuItems, function (item, key) {
              return _c(
                "v-tab-item",
                { key: key, attrs: { value: item.value } },
                [
                  _c(
                    "v-card",
                    { attrs: { color: "var(--v-bg-gray-base)", flat: "" } },
                    [
                      _c(
                        "v-card-text",
                        { staticClass: "pb-0 px-0 pt-0" },
                        [
                          _c(item.component, {
                            tag: "component",
                            attrs: { "selected-tab": _vm.tab },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
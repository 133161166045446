<template>
  <AppModal :title="$t(this.title)" max-width="450" persistent :show-close-button="!loading">
    <AppTextField v-model="name" :label="$t('folderName')"></AppTextField>

    <AppButton block @click="handleClick" :loading="loading">{{ $t('saveChanges') }}</AppButton>
  </AppModal>
</template>

<script>
import { mapActions } from 'vuex';
import { v4 as uuidv4 } from 'uuid';
import { consoleError } from 'xpermeet-lib';

import { SETTINGS, NOTIFICATION } from '@/constants/modules';
import { TOAST_TYPE } from '@/constants/toast';

export default {
  name: 'AddEditBannerFolderModal',
  props: {
    folders: {
      type: Array,
      required: true,
    },
    folder: {
      type: Object,
    },
  },
  data() {
    return {
      name: this.folder?.name || '',
      loading: false,
    };
  },
  computed: {
    title() {
      return this.folder ? 'editFolder' : 'createNewFolder';
    },
  },
  methods: {
    ...mapActions(SETTINGS, ['setSettings']),
    ...mapActions(NOTIFICATION, ['showToastNotification']),
    async create() {
      try {
        this.loading = true;

        const folder = {
          id: uuidv4(),
          banners: [],
          name: this.name,
        };

        const isSuccess = await this.setSettings({
          shortCode: 'banners',
          state: JSON.stringify([folder, ...this.folders]),
        });

        if (isSuccess) {
          this.$emit('completed');
          this.showToastNotification({ body: this.$t('success.successfullyAdded'), config: { type: TOAST_TYPE.SUCCESS } });
        } else {
          this.showToastNotification({ body: this.$t('errors.couldNotSet', { name: this.$t('folder') }), config: { type: TOAST_TYPE.ERROR } });
        }

        this.loading = false;
      } catch (err) {
        consoleError('Create banner folder error: ', err);
      }
    },
    async edit() {
      try {
        this.loading = true;
        const folders = [...this.folders];

        const index = folders.findIndex((f) => f.id === this.folder.id);
        folders[index].name = this.name;

        const isSuccess = await this.setSettings({
          shortCode: 'banners',
          state: JSON.stringify(folders),
        });

        if (isSuccess) {
          this.close('asd');
          this.$emit('completed');
          this.showToastNotification({ body: this.$t('success.successfullyEdited'), config: { type: TOAST_TYPE.SUCCESS } });
        } else {
          this.showToastNotification({ body: this.$t('errors.couldNotEdit', { name: this.$t('folder') }), config: { type: TOAST_TYPE.ERROR } });
        }

        this.loading = false;
      } catch (err) {
        consoleError('Edit banner folder error: ', err);
      }
    },
    handleClick() {
      if (this.folder) {
        this.edit();
      } else {
        this.create();
      }
    },
    close(type) {
      this.dialog = false;
      this.$modal?.close(type);
    },
  },
};
</script>

<template>
  <div>
    <div class="pt-5">
      <div class="text-h5 mb-4">{{ $t('liveComments') }}</div>
      <div class="d-flex align-center">
        <AppInput
          dense
          :label="`${$t('duration')} (${$t('seconds')})`"
          v-model="settings.liveComments.duration"
          :disabled="settings.liveComments.parmanent"
        ></AppInput>
        <div class="mx-5"></div>
        <v-switch class="mt-0" color="var(--v-secondary-lighten1)" :label="$t('permanent')" hide-details v-model="settings.liveComments.parmanent" />
      </div>
    </div>
    <v-divider></v-divider>
    <div class="pt-5">
      <div class="text-h5 mb-4">{{ $t('banners') }}</div>
      <div class="d-flex align-center">
        <AppInput dense :label="`${$t('duration')} (${$t('seconds')})`" v-model="settings.banners.duration" :disabled="settings.banners.parmanent"></AppInput>
        <div class="mx-5"></div>
        <v-switch class="mt-0" color="var(--v-secondary-lighten1)" :label="$t('permanent')" hide-details v-model="settings.banners.parmanent" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { consoleError, consoleLog } from 'xpermeet-lib';
import { SETTINGS } from '@/constants/modules';

const GENERAL_SETTINGS = 'generalSettings';

export default {
  name: 'GeneralSettings',
  data() {
    return {
      settings: {
        liveComments: {
          parmanent: true,
          duration: 4,
        },
        banners: {
          parmanent: true,
          duration: 4,
        },
      },
    };
  },
  computed: {
    ...mapState(SETTINGS, ['generalSettings']),
  },
  methods: {
    ...mapActions(SETTINGS, ['setSettings']),
    async save() {
      const isSuccess = await this.setSettings({
        shortCode: GENERAL_SETTINGS,
        state: JSON.stringify(this.settings),
      });

      if (isSuccess) {
        consoleLog('Successfulyl changed');
      } else {
        consoleError("Couln't change general settings");
      }
    },
  },
  watch: {
    generalSettings: {
      immediate: true,
      deep: true,
      handler() {
        if (this.generalSettings) {
          this.settings = structuredClone(this.generalSettings);
        }
      },
    },
  },
};
</script>


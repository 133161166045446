var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "background-wrapper d-flex justify-center align-center cursor-pointer",
    },
    [
      _c(
        "div",
        { staticClass: "background-menu", class: { selected: _vm.selected } },
        [
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _c(
                        "AppIconButton",
                        _vm._g(
                          _vm._b(
                            {
                              class: {
                                "mr-1": _vm.background.addedByModerator,
                              },
                              attrs: {
                                width: 30,
                                "icon-size": "16px",
                                icon: _vm.selected ? "eye-off" : "eye-on",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.$emit(
                                    "changeBackgroundSelected",
                                    _vm.background.id
                                  )
                                },
                              },
                            },
                            "AppIconButton",
                            attrs,
                            false
                          ),
                          on
                        )
                      ),
                    ]
                  },
                },
              ]),
            },
            [
              _c("span", [
                _vm._v(_vm._s(_vm.$t(_vm.selected ? "hide" : "show"))),
              ]),
            ]
          ),
          _c(
            "v-tooltip",
            {
              attrs: { bottom: "" },
              scopedSlots: _vm._u([
                {
                  key: "activator",
                  fn: function ({ on, attrs }) {
                    return [
                      _vm.background.addedByModerator
                        ? _c(
                            "AppIconButton",
                            _vm._g(
                              _vm._b(
                                {
                                  staticClass: "ml-1",
                                  attrs: {
                                    width: 30,
                                    "icon-size": "16px",
                                    icon: "minus-circle",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.$emit(
                                        "deleteLogoFromList",
                                        _vm.background.id
                                      )
                                    },
                                  },
                                },
                                "AppIconButton",
                                attrs,
                                false
                              ),
                              on
                            )
                          )
                        : _vm._e(),
                    ]
                  },
                },
              ]),
            },
            [_c("span", [_vm._v(_vm._s(_vm.$t("delete")))])]
          ),
        ],
        1
      ),
      _c("img", {
        staticClass: "background-image",
        class: { selected: _vm.selected },
        attrs: { src: _vm.background.url },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
<template>
  <div>
    <v-switch
      color="var(--v-secondary-lighten1)"
      :label="$t(`rightMenu.settings.notificationSoundsOnOff`)"
      hide-details
      v-model="soundSettings[SOUNDS_ON_OFF]"
    />
    <v-switch
      color="var(--v-secondary-lighten1)"
      v-model="soundSettings[USER_JOINED]"
      :label="$t('rightMenu.settings.userJoinedSound')"
      :disabled="!soundSettings[SOUNDS_ON_OFF]"
      :hide-details="true"
    ></v-switch>
  </div>
</template>
<script>
import { mapState, mapMutations, mapActions } from 'vuex';
import { NOTIFICATION, SETTINGS } from '@/constants/modules';
import { NOTIFICATION_SETTINGS } from '@/constants/settings';
import { NOTIFICATION_SOUND_TYPES } from '@/constants/notification';
import { SET_NOTIFICATION_SOUND_SETTING } from '@/constants/mutation-types';

const { SOUNDS_ON_OFF } = NOTIFICATION_SETTINGS;
const { USER_JOINED } = NOTIFICATION_SOUND_TYPES;

export default {
  name: 'NotificationsSettings',
  data() {
    return {
      SOUNDS_ON_OFF,
      USER_JOINED,
      soundSettings: {
        [SOUNDS_ON_OFF]: false,
        [USER_JOINED]: false,
      },
    };
  },
  computed: {
    ...mapState(NOTIFICATION, ['notificationSoundSettings']),
    settingsPayload() {
      return {
        shortCode: 'notifications',
        state: JSON.stringify(this.soundSettings),
      };
    },
  },
  created() {
    this.soundSettings = { ...this.notificationSoundSettings };
  },
  methods: {
    ...mapActions(SETTINGS, ['setSettings']),
    ...mapMutations(NOTIFICATION, [SET_NOTIFICATION_SOUND_SETTING]),
    save() {
      this[SET_NOTIFICATION_SOUND_SETTING](this.soundSettings);
      this.setSettings(this.settingsPayload);
    },
  },
  watch: {
    soundSettings: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.soundSettings[SOUNDS_ON_OFF]) {
          Object.keys(this.soundSettings).forEach((setting) => {
            this.soundSettings[setting] = false;
          });
        }
      },
    },
  },
};
</script>

<template>
  <div class="backstage-video-card">
    <div class="backstage-video-card--menu d-flex justify-space-between px-1 py-2" v-if="!isScreen">
      <AppButton
        v-if="$can('addRemoveStream', 'Studio')"
        outlined
        class="backstage-video-card--menu--layout-button"
        :color="selectSoloBtnColor"
        @click="selectSoloUser({ participantId: user.id })"
      >
        <AppIcon icon="user-full-color" />
      </AppButton>
      <div v-else></div>
      <div class="backstage-video-card--menu--btn-group d-flex align-center justify-center">
        <template>
          <div class="d-flex px-2" @click="toggleSetRemoteAudioMute" :class="{ 'cursor-pointer': $can('muteUser', 'Studio') && !myVideoCard }">
            <AppIcon :color="micIconColor" :icon="micIcon" size="12" />
          </div>
          <div class="backstage-video-card--menu--btn-group--spacer" v-if="moreActionList.length" />
        </template>
        <v-menu offset-y v-if="moreActionList.length">
          <template v-slot:activator="{ on, attrs }">
            <div class="d-flex px-2" v-on="on" v-bind="attrs">
              <AppIcon color="black" icon="more-vertical" size="14" />
            </div>
          </template>
          <v-list-item-group>
            <v-list-item v-for="(action, index) in moreActionList" :key="index" dense @click="action.onClick">
              <v-list-item-title>
                {{ action.label }}
              </v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-menu>
      </div>
    </div>
    <VideoCard
      :id="`${user.type}-video-${user.id}`"
      :class-name="`${user.type}`"
      :closed="!user.hasVideoTrack || user.interrupted || user.videoMuted"
      :local="myVideoCard"
      :user="user"
    />
    <div class="backstage-video-card--toggle-btn justify-center px-4 w-full" v-if="$can('addRemoveStream', 'Studio')">
      <AppButton @click="toggleStage" :class="{ staged: onStage }" block small>{{ $t(toggleText) }}</AppButton>
    </div>
    <div class="backstage-video-card--name d-flex align-center">
      <AppIcon v-if="user.interrupted" icon="alert-circle-1" color="red" size="14" />
      <AppIcon v-else :icon="getUserIcon" size="14" :color="userIconColor" />
      <span class="ml-1 ellipsis">{{ user.displayName }}</span>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { STUDIO, SETTINGS } from '@/constants/modules';
import ChangeDisplayNameModal from '@/components/Modals/ChangeDisplayNameModal';
import { attachTrackToElements, detachUserVideoTracks } from '@/helpers/studio';

export default {
  name: 'BackstageVideoCard',
  props: {
    user: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapState(STUDIO, ['roomConfig', 'selectedSoloLayout']),
    ...mapGetters(STUDIO, ['getLocalUser']),
    isScreen() {
      return this.user.isScreen === 'true';
    },
    userIconColor() {
      return this.user.isModerator ? 'yellow' : 'white';
    },
    onStage() {
      return this.roomConfig.stagedUsers.find((user) => user.id === this.user.id);
    },
    toggleText() {
      return this.onStage ? 'remove' : 'studio.addToStream';
    },
    toggleColor() {
      return this.onStage ? 'light-gray-3' : 'primary';
    },
    micIcon() {
      return this.user.audioMuted ? 'mic-off' : 'mic-solid';
    },
    micIconColor() {
      return this.user.audioMuted ? 'red' : 'black';
    },
    audioMutePayload() {
      return {
        participantId: this.user.id,
        muteState: !this.user.audioMuted,
      };
    },
    myVideoCard() {
      return this.user?.id === this.getLocalUser?.id;
    },
    muteVideoText() {
      return this.user.videoMuted ? this.$t('unmuteVideo') : this.$t('muteVideo');
    },
    selectSoloBtnColor() {
      if (this.selectedSoloLayout?.soloUserId === this.user.id) {
        return 'theme-primary';
      }
      return 'white';
    },
    moreActionList() {
      const items = [
        {
          hide: this.myVideoCard || !this.$can('muteUser', 'Studio'),
          label: this.muteVideoText,
          onClick: () => {
            this.setRemoteVideoMute({
              participantId: this.user.id,
              muteState: !this.user.videoMuted,
            });
          },
        },
        {
          label: this.$t('changeDisplayName'),
          hide: !this.myVideoCard,
          onClick: () => {
            this.showChangeDisplayNameModal();
          },
        },
        {
          label: this.$t('kickFromStudio'),
          hide: this.myVideoCard || !this.$can('kickFromStudio', 'Studio'),
          onClick: () => {
            const confirmModal = this.$showConfirmModal(
              {
                title: this.$t('areYouSure'),
                text: this.$t('areYouSureWantToKick', { displayName: this.user.displayName }),
              },
              {
                confirm: () => {
                  this.kickParticipant(this.user.id);
                  confirmModal.close();
                },
                cancel: () => {
                  confirmModal.close();
                },
              },
            );
          },
        },
        {
          label: this.$t('editAvatar'),
          hide: !this.myVideoCard || !this.$can('useAvatar', 'Studio'),
          isChangeAvatar: true,
          onClick: () => {
            this.$emit('handleAvatarModal', true);
            this.showAvatarCropper = true;
          },
        },
        {
          label: this.$t('clearAvatar'),
          hide: !this.myVideoCard || !this.$can('useAvatar', 'Studio'),
          onClick: () => {
            this.$emit('clearAvatar');
          },
        },
      ];
      return items.filter((item) => !item.hide);
    },
    getUserIcon() {
      return this.isScreen ? 'monitor-1' : 'user-full-color';
    },
  },
  mounted() {
    this.$nextTick().then(() => {
      attachTrackToElements([this.user], false, true);
    });
  },
  beforeDestroy() {
    detachUserVideoTracks(this.user.id, this.user.id === this.getLocalUser.id);
  },
  methods: {
    ...mapActions(SETTINGS, ['fetchSettings']),
    ...mapActions(STUDIO, ['addStagedUser', 'removeStagedUser', 'setRemoteAudioMute', 'setRemoteVideoMute', 'selectSoloUser', 'kickParticipant']),
    toggleStage() {
      const { id, isScreen } = this.user;
      if (this.onStage) {
        this.removeStagedUser({ userId: id });
      } else {
        this.addStagedUser({ userId: id, isScreen });
      }
    },
    toggleSetRemoteAudioMute() {
      if (this.$can('muteUser', 'Studio') && !this.myVideoCard) {
        this.setRemoteAudioMute(this.audioMutePayload);
      }
    },
    showChangeDisplayNameModal() {
      const modal = this.$showModal(
        ChangeDisplayNameModal,
        {},
        {
          completed: () => {
            this.fetchSettings();
            modal.close();
          },
          close: () => {
            modal.close();
          },
        },
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.backstage-video-card {
  min-width: 150px;
  height: 84px;
  position: relative;
  background-color: black;

  &--menu {
    z-index: 1;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;

    &--layout-button {
      min-width: 42px !important;
      width: 42px !important;
      height: 30px !important;
    }

    &--btn-group {
      background: white;
      border: 0.5px solid black;
      border-radius: 5px;
      height: 20px;

      &--spacer {
        background-color: black;
        height: 100%;
        width: 1px;
      }
    }
  }

  &--toggle-btn {
    display: none;
    position: absolute;
    top: 45px;
    background: var(--v-primary);

    .staged {
      background-color: rgba(107, 107, 107, 0.74);
    }
  }

  &:hover {
    .backstage-video-card--toggle-btn {
      display: flex;
    }
  }

  &--name {
    padding: 0 5px;
    font-size: 12px;
    background-color: var(--v-theme-backstage-name-bg-color-base);

    span {
      margin-bottom: -2px;
      max-width: 120px;
    }
  }
}
</style>


var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "audio-device-settings py-1" },
    [
      _c("DeviceSelector", {
        ref: "deviceSelector",
        class: { "has-alert": _vm.infoAlert },
        attrs: { infoAlert: _vm.infoAlert, "hide-camera-settings": "" },
        on: {
          microphoneChange: _vm.handleMicrophoneChange,
          speakerChange: _vm.handleSpeakerChange,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
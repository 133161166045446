<template>
  <div class="mt-4 corner-notch-selector">
    <div class="corner-notch-selector--title">
      {{ $t('design.chooseSingleOrMultipleToAdd') }}
    </div>
    <div class="d-flex flex-wrap align-center corner-notch-selector--button-wrapper mt-2">
      <AppButton
        v-for="(cornerNotch, key) in CORNER_NOTCH_TYPES"
        :disabled="hasSingleElement(cornerNotch.type)"
        small
        class="corner-notch-selector--button"
        color="var(--v-chat-input-color)"
        :key="key"
        @click="addNotch(cornerNotch)"
      >
        <span class="">{{ $t(`design.cornerNotchTypes.${cornerNotch.type}`) }}</span>
      </AppButton>
    </div>
    <div class="mt-4">
      <div v-for="(value, key) in selectedNotchs" class="d-flex" :key="key">
        <v-text-field
          v-if="selectedNotchs[key].type !== 'time'"
          v-model="selectedNotchs[key].value"
          class="corner-notch-selector--inputs"
          solo
          outlined
          dense
          hide-details
          height="30"
          :error="fieldHasError(selectedNotchs[key])"
          :hint="$t('design.maxCharacter', { value: selectedNotchs[key].maxChar })"
        >
          <template v-slot:label>
            <div class="d-flex align-center">
              <span class="label-info">({{ $t(`design.cornerNotchTypes.${selectedNotchs[key].type}`) }})</span>
              <span class="label-max-char">*{{ $t('design.maxCharacter', { value: selectedNotchs[key].maxChar }) }}</span>
            </div>
          </template>
          <template v-slot:append>
            <AppIcon :icon="selectedNotchs[key].icon" color="var(--v-white-base)" width="14" />
          </template>
        </v-text-field>
        <div v-else class="time-notch d-flex justify-space-between align-center mb-2">
          <CurrentTime />
          <AppIcon :icon="selectedNotchs[key].icon" color="var(--v-white-base)" width="14" />
        </div>
        <div class="d-flex justify-center ml-4 mt-1 cursor-pointer" @click="removeNotch(key)">
          <AppIcon icon="close" width="14" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import CurrentTime from '@/components/Common/CurrentTime.vue';
import { CORNER_NOTCH_TYPES, ANIMATED_ELEMENT_TYPES } from '@/constants/animated-elements';
import { STUDIO } from '@/constants/modules';

export default {
  name: 'CornerNotchSelector',
  components: { CurrentTime },
  data() {
    return {
      CORNER_NOTCH_TYPES,
      selectedNotchs: {},
    };
  },
  computed: {
    ...mapGetters(STUDIO, ['getDesign']),
    hasSingleElement() {
      return (type) => this.selectedNotchsArray.some((notch) => notch.type === type && !notch.isMultiple);
    },
    selectedNotchsArray() {
      return Object.keys(this.selectedNotchs).map((notchId) => {
        return {
          ...this.selectedNotchs[notchId],
        };
      });
    },
    newNotchId() {
      return this.selectedNotchsArray.length ? Math.max(...this.selectedNotchsArray.map((notch) => notch.id)) + 1 : 0;
    },
    fieldHasError() {
      return (element) => {
        return element.value === '' || element.value.length > element.maxChar;
      };
    },
    anyNotchError() {
      return this.selectedNotchsArray.some((notch) => (notch.type !== 'time' && notch.value === '') || notch.value.length > notch.maxChar);
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.setDefaults();
    });
  },
  methods: {
    removeNotchs() {
      this.selectedNotchs = {};
    },
    removeNotch(id) {
      this.$delete(this.selectedNotchs, id);
    },
    addNotch(element) {
      const id = this.newNotchId;
      this.$set(this.selectedNotchs, id, {
        value: '',
        id,
        ...element,
      });
    },

    notchsPayload() {
      return [...this.selectedNotchsArray];
    },
    setDefaults() {
      if (
        this.getDesign.animatedElements[ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE]?.cornerNotch &&
        this.getDesign.animatedElements[ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE]?.cornerNotchProperties?.length
      ) {
        for (let i in this.getDesign.animatedElements[ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE]?.cornerNotchProperties) {
          this.$set(this.selectedNotchs, i, { ...this.getDesign.animatedElements[ANIMATED_ELEMENT_TYPES.ANIMATED_SUBTITLE]?.cornerNotchProperties[i] });
        }
      }
    },
  },
  watch: {
    anyNotchError() {
      this.$emit('setNotchError', this.anyNotchError);
    },
  },
};
</script>

<style lang="scss" scoped>
.corner-notch-selector {
  color: var(--v-white-base);
  width: 310px;
  &--title,
  &--button {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    letter-spacing: 0px;
    text-align: left;
  }
  &--button {
    width: 72px;
  }
  &--button-wrapper {
    gap: 5px;
  }
  &--inputs {
    ::v-deep .v-input__control {
      .v-input__slot {
        min-height: 30px !important;
        .v-label {
          width: 100%;
          .label-info {
            font-size: 12px;
            font-weight: 400;
            line-height: 14px;
            letter-spacing: 0px;
            text-align: left;
          }
          .label-max-char {
            font-size: 10px;
            font-weight: 400;
            line-height: 9px;
            letter-spacing: 0px;
            text-align: left;
          }
        }
      }
    }
  }
  .time-notch {
    width: 100%;
    border: 1px solid var(--v-chat-input-color);
    padding: 6px;
    height: 30px;
    border-radius: 4px;
  }
}
</style>

